import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../store/actions";
import AddCustomMetrics from "./metricStep/AddCustomMetrics";
import StepOne from "./metricStep/StepOne";
// import StepThree from "./metricStep/StepThree";
import StepThreeNew from "./metricStep/StepThreeNew";
import StepTwo from "./metricStep/StepTwo";
import { METRICDATATYPE } from "../../constant";
import { useNavigate } from "react-router-dom";

const SalesMetricMain = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedMetrics, setSelectedMetrics] = useState([]);
  const [isAddCustomMetrics, setIsAddCustomMetrics] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const [totalWeightage, setTotalWeightage] = useState(0);
  const [loading, setLoading] = useState({
    // metricsList: false,
    selectedMetrics: false,
    submitMetrics: false,
  });
  const [metricLoading, setMetricLoading] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const {
    metricsList: metrics,
    selectedMetricsList,
    bucketListByMetrics,
  } = useSelector((state) => state.metrics);

  const handleOnSelectMetric = (data) => {
    const updatedMatrics = selectedMetrics;
    if (updatedMatrics.some((sm) => sm.name === data.name)) {
      const filteredPeople = updatedMatrics.filter(
        (item) => item.name !== data.name
      );
      setSelectedMetrics([...filteredPeople]);
    } else {
      data.weightage = 0;
      setSelectedMetrics([...selectedMetrics, data]);
    }
  };

  const handleCustomMetrics = () => {
    setIsAddCustomMetrics(!isAddCustomMetrics);
  };

  const handleAddWeightage = (index) => {
    if (totalWeightage < 100) {
      const oldSelectedMetrics = selectedMetrics;
      let total = 0;
      oldSelectedMetrics[index].weightage =
        oldSelectedMetrics[index].weightage + 5;

      oldSelectedMetrics.map((data) => {
        total = data.weightage + total;
      });
      setTotalWeightage(total);
      setSelectedMetrics(oldSelectedMetrics);
    }
  };

  const handleMinusWeightage = (index) => {
    if (totalWeightage > 0) {
      const oldSelectedMetrics = selectedMetrics;
      let total = 0;
      if (oldSelectedMetrics[index].weightage > 0) {
        oldSelectedMetrics[index].weightage =
          oldSelectedMetrics[index].weightage - 5;
      }
      oldSelectedMetrics.map((data) => {
        total = total - data.weightage;
      });
      setTotalWeightage(Math.abs(total));
      setSelectedMetrics(oldSelectedMetrics);
    }
  };

  const handleOnSubmitStepTwo = async () => {
    try {
      // setLoading({ ...loading, submitMetrics: true });
      const payload = {
        metricsType: "sales",
        metricDTOs: [],
      };

      selectedMetrics.map((data) => {
        payload.metricDTOs.push({
          name: data.name ?? "",
          metricObjectId: data.metricObjectId ?? data.name.replace(/ +/g, ""),
          weightage: data.weightage ?? 0,
          // type: data.type ?? "",
          tenant: data.tenant ?? user?.tenant,
        });
      });

      payload?.metricDTOs?.map((data, key) => {
        data.type = METRICDATATYPE[data.name.toLowerCase()];
      });
      //deleted array start
      const deletedArray = selectedMetricsList.filter(
        (data) =>
          !selectedMetrics?.some(
            (selectedMetrics2) => selectedMetrics2.name === data.name
          )
      );
      if (deletedArray.length > 0) {
        deletedArray.map(async (data) => {
          const res = await dispatch(
            actions.deleteMetricsByMetricsId("SALES", data.metricObjectId)
          );
        });
        payload.deletedIds = deletedArray.map((data) => {
          return data.metricObjectId;
        });
      }
      //deleted array end

      const res = await dispatch(actions.createMetrics(payload));
      if (res?.status === 201) {
        dispatch(actions.getBucketByMetricsId(navigate));
        setActiveStep(3);
        setLoading({ ...loading, submitMetrics: false });
      }
    } catch (error) {
      console.log(error);
      setLoading({ ...loading, submitMetrics: false });
    }
  };

  const handleGetMetricsList = async () => {
    try {
      setMetricLoading(true);
      // setLoading({ ...loading, metricsList: true });
      const res = await dispatch(actions.getAllMetrics(user?.id, navigate));
      if (res) {
        setMetricLoading(false);
        // setLoading({ ...loading, metricsList: false });
      }
    } catch (error) {
      console.log(error);
      setMetricLoading(false);
      // setLoading({ ...loading, metricsList: false });
    }
  };

  const handleGetSelectedList = async () => {
    try {
      setLoading({ ...loading, selectedMetrics: true });
      const res = await dispatch(actions.getSelectedMetrics(user?.tenant));
      if (res) {
        setLoading({ ...loading, selectedMetrics: false });
      }
    } catch (error) {
      console.log(error);
      setLoading({ ...loading, selectedMetrics: false });
    }
  };
  useEffect(() => {
    if (selectedMetrics.length > 0) {
      let total = 0;
      selectedMetrics.map((data) => {
        total = data.weightage + total;
      });
      setTotalWeightage(total);
    }
  }, [selectedMetrics]);

  useEffect(() => {
    if (selectedMetricsList?.length > 0) {
      setSelectedMetrics(selectedMetricsList);
    }
  }, [selectedMetricsList]);

  useEffect(() => {
    handleGetMetricsList();
    handleGetSelectedList();
  }, [dispatch]);

  return (
    <div className=" border-t-2 !border-primary bg-white my-4 mx-3 py-3 px-7">
      {isAddCustomMetrics && (
        <AddCustomMetrics {...{ setIsAddCustomMetrics }} />
      )}
      {activeStep === 1 && !isAddCustomMetrics && (
        <>
          <StepOne
            {...{
              metrics,
              handleOnSelectMetric,
              selectedMetrics,
              handleCustomMetrics,
              setActiveStep,
              totalWeightage,
              loading,
              metricLoading,
            }}
          />
        </>
      )}
      {activeStep === 2 && !isAddCustomMetrics && (
        <StepTwo
          {...{
            selectedMetrics,
            setActiveStep,
            handleOnSubmitStepTwo,
            handleAddWeightage,
            totalWeightage,
            loading,
            handleMinusWeightage,
          }}
        />
      )}
      {activeStep === 3 && !isAddCustomMetrics && (
        <StepThreeNew {...{ bucketListByMetrics, setActiveStep }} />
      )}
    </div>
  );
};

export default SalesMetricMain;
