import React, { useState } from "react";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Spin, Tabs } from "antd";
import FeatureRequestSystemActivityTab from "./FeatureRequestSystemActivityTab";
import FeatureRequestComment from "./FeatureRequestComment";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { Formik } from "formik";
import { Form, Select } from "formik-antd";
import { DATA_TYPES } from "../common/constant";
import moment from "moment";
import GreenBtn from "../common/greenBtn";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../store/actions";
import { LoadingOutlined } from "@ant-design/icons";

const FeatureRequestDetails = (props) => {
  const { handleExpanded, selectedFeatures, isChangedData } = props;
  const [isEditDetails, setIsEditDetails] = useState(false);
  const [isLoadingComment, setIsLoadingComment] = useState(false);
  const dispatch = useDispatch();
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const items = [
    {
      key: "1",
      label: `Comments`,
      children: <FeatureRequestComment {...{ selectedFeatures }} />,
    },
    {
      key: "2",
      label: `System Activity`,
      children: <FeatureRequestSystemActivityTab />,
    },
  ];
  const handleEditFR = () => {
    setIsEditDetails(!isEditDetails);
    if (isEditDetails) {
      handleSubmit();
    }
  };
  const handleSubmit = async (values) => {
    console.log(values, "values");
  };

  return (
    <div className="w-full border-l-2 border-[#DFDFDF] h-full pl-3">
      {/* header */}
      <div className="flex justify-between items-center pb-2 border-b-[1px] border-[#DFDFDF]">
        <h2 className=" text-base mb-0 ">
          {selectedFeatures?.jiraDetails?.issueName}
          {isChangedData && <Spin indicator={antIcon} className="!ml-2" />}
        </h2>
        <div className="flex">
          <button
            className="!bg-primary py-1 w-14 text-center rounded-[2px]  text-xs capitalize text-white"
            onClick={handleEditFR}
          >
            {isEditDetails && (
              <>
                <SaveOutlinedIcon className="!text-sm mr-1 mb-0.5" />
                Save
              </>
            )}
            {!isEditDetails && (
              <>
                <ModeEditOutlineOutlinedIcon className="!text-xs mr-1 mb-0.5" />
                Edit
              </>
            )}
          </button>
          <button className="ml-3" onClick={handleExpanded}>
            <CloseOutlinedIcon />
          </button>
        </div>
      </div>
      {/* middle */}
      <div className="flex pt-3 justify-between">
        <div className="w-4/12">
          <h4 className="mb-3  text-[#9A9F95] text-xs">
            FR ID :{" "}
            <span className="pl-1 text-[#1F1F1F]">
              {selectedFeatures?.customFeatureId}
            </span>
          </h4>

          <h4 className="mb-3  text-[#9A9F95] text-xs">
            JIRA ID :{" "}
            <span className="pl-1 !text-primary">
              {selectedFeatures?.destinationId}
            </span>
          </h4>
          <h4 className="mb-3  text-[#9A9F95] text-xs">
            Created on :{" "}
            <span className="pl-1 text-[#1F1F1F]">
              {moment(selectedFeatures?.createdDate).format("DD MMM YYYY")}
            </span>
          </h4>
          {/* TODO: need to add Opportunity Name
          get Opportunity by ID and take name from there. 
          https://devapi.theitstack.com/api/v1.0/opportunity/opp/0065i000003Ual8AAC
          */}
          {/* <h4 className="mb-3  text-[#9A9F95] text-xs">Opportunity Name : </h4>
          <span className="pl-1 text-[#1F1F1F]">
            Lorem ipsum dolor sit amet
          </span> */}
        </div>
        <div className="w-4/12">
          <Formik
            initialValues={{ priority: "", issueType: "", project: "" }}
            onSubmit={handleSubmit}
          >
            {({}) => (
              <Form>
                <div className="flex items-center text-xs pb-3">
                  <span className="text-[#9A9F95]">Issue Type :</span>
                  {!isEditDetails && (
                    <span className="pl-2 text-[#1F1F1F]">
                      {selectedFeatures?.jiraDetails?.issueTypeId ?? "-"}
                    </span>
                  )}
                  {isEditDetails && (
                    <div className="ml-3">
                      <Select
                        options={DATA_TYPES}
                        name="issueType"
                        id="issueType"
                        placeholder="Select Issue Type"
                        className="ot-select ot-select--fr border-[#797979] !w-40 "
                      />
                    </div>
                  )}
                </div>
                <div className="flex items-center text-xs pb-3">
                  <span className=" text-[#9A9F95]">Priority :</span>
                  {!isEditDetails && (
                    <div className="ml-2">
                      {selectedFeatures?.jiraDetails?.priority?.toLowerCase() ===
                      "high" ? (
                        <GreenBtn
                          {...{
                            text: selectedFeatures?.jiraDetails?.priority,
                            extrClass: "w-14 !cursor-auto",
                          }}
                        />
                      ) : selectedFeatures?.jiraDetails?.priority?.toLowerCase() ===
                        "medium" ? (
                        <GreenBtn
                          {...{
                            text: selectedFeatures?.jiraDetails?.priority,
                            extrClass: "!bg-[#EB901C] w-14  !cursor-auto",
                          }}
                        />
                      ) : (
                        <GreenBtn
                          {...{
                            text: selectedFeatures?.jiraDetails?.priority,
                            extrClass: "!bg-[#2BB8FF] w-14  !cursor-auto",
                          }}
                        />
                      )}
                    </div>
                  )}
                  {isEditDetails && (
                    <div className="ml-3">
                      <Select
                        options={DATA_TYPES}
                        name="priority"
                        id="priority"
                        placeholder="Select priority"
                        className="ot-select ot-select--fr border-[#797979] !w-40"
                      />
                    </div>
                  )}
                </div>
                <div className="flex items-center text-xs">
                  <span className="text-[#9A9F95]">Project :</span>
                  {!isEditDetails && (
                    <span className="pl-2 text-[#1F1F1F] text-xs">
                      {selectedFeatures?.jiraDetails?.projectId}
                    </span>
                  )}
                  {isEditDetails && (
                    <div className="ml-3">
                      <Select
                        options={DATA_TYPES}
                        name="project"
                        id="project"
                        placeholder="Select priority"
                        className="ot-select ot-select--fr border-[#797979] !w-40"
                      />
                    </div>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <div className="w-4/12 flex justify-end">
          <div className="w-11/12 bg-[#EDEBEB] p-3 flex">
            <div className="w-7/12 flex flex-col">
              <span className=" text-xs text-[#1F1F1F] pb-3">OT Score :</span>
              <span className=" text-xs text-[#1F1F1F] pb-3">
                Sales Score :
              </span>
              <span className=" text-xs text-[#1F1F1F] pb-3">
                Product Score :
              </span>
              <span className=" text-xs text-[#1F1F1F] pb-3">
                Actual Score :
              </span>
              <span className=" text-xs text-[#1F1F1F] pb-3">FR Score :</span>
            </div>
            <div className="w-5/12 flex flex-col">
              <span className=" text-xs text-[#1F1F1F] pb-3">
                {selectedFeatures?.score}
              </span>
              <span className=" text-xs text-[#1F1F1F] pb-3">
                {selectedFeatures?.salesScore}
              </span>
              <span className=" text-xs text-[#1F1F1F] pb-3">
                {selectedFeatures?.productScore}
              </span>
              <span className=" text-xs text-[#1F1F1F] pb-3">
                {selectedFeatures?.actualScore}
              </span>
              {selectedFeatures?.jiraStatus?.toLowerCase() === "done" ? (
                <GreenBtn
                  {...{
                    text: selectedFeatures?.jiraStatus,
                    extrClass: "w-14 !cursor-auto",
                  }}
                />
              ) : selectedFeatures?.jiraStatus?.toLowerCase() === "to do" ? (
                <GreenBtn
                  {...{
                    text: selectedFeatures?.jiraStatus,
                    extrClass: "!bg-[#EB901C] w-14  !cursor-auto",
                  }}
                />
              ) : (
                <GreenBtn
                  {...{
                    text: selectedFeatures?.jiraStatus,
                    extrClass: "!bg-[#2BB8FF] w-14  !cursor-auto",
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="pt-2">
        <h4 className="mb-2  text-[#9A9F95] text-xs">Description</h4>
        <span className="pl-1 text-[#1F1F1F]">
          {selectedFeatures?.jiraDetails?.description}
          {/* <span className="!text-primary"> .... read more</span> */}
        </span>
      </div>
      {/* activity */}
      <h2 className=" text-base mb-0  border-t-[1px] border-[#DFDFDF] mt-3 pt-3">
        Activity
      </h2>
      <Tabs defaultActiveKey="1" items={items} />
    </div>
  );
};

export default FeatureRequestDetails;
