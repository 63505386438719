import React, { useState } from "react";
import { DATA_TYPES } from "../../common/constant";
import InputFields from "../../common/InputFields";
import SelectFields from "../../common/Select";

const AddCustomMetrics = (props) => {
  const {
    setIsAddCustomMetrics,
    setSelectedMetrics,
    setMetrics,
    metrics,
    selectedMetrics,
    customId,
    setCustomId,
  } = props;
  const [values, setValues] = useState({
    metricName: "",
    dataType: null,
  });

  const handleSubmit = () => {
    const payload = {
      name: values.metricName,
      type: values.dataType,
      weightage: 0,
      label: values.metricName,
      metricObjectId: `customId${customId}`,
      tenant: "devt4",
    };
    if (values) {
      setSelectedMetrics([...selectedMetrics, payload]);
      setMetrics([payload, ...metrics]);
      setIsAddCustomMetrics(false);
      setCustomId(customId + 1);
    }
  };
  return (
    <>
      <h2 className="text-base mb-0">Add Custom Metric</h2>
      <div className="flex pt-8">
        {/* Metric Name */}
        <div className="w-1/12">
          <label className="text-[13px] text-[#717475]">Metric Name</label>
        </div>
        <div className="w-3/12">
          <InputFields
            {...{
              value: values.metricName,
              handleChange: (e) => {
                setValues({ ...values, metricName: e.target.value });
              },
              placeholader: "Metric Name",
              extraClass: "!w-[296px]",
            }}
          />
        </div>
        {/* Data Type */}
        <div className="w-1/12">
          <label className="text-[13px] text-[#717475]">Data Type</label>
        </div>
        <div className="w-2/12">
          <SelectFields
            {...{
              handleChange: (e) => {
                setValues({ ...values, dataType: e });
              },
              placeholader: "Select data type",
              options: DATA_TYPES,
              value: values.dataType,
            }}
          />
        </div>
      </div>
      <div className="flex justify-center items-center">
        <div className="flex justify-center mt-10">
          <button
            className="mr-3 bg-[#999999] text-[13px] text-white rounded-[3px] !px-3.5 !py-1"
            onClick={() => setIsAddCustomMetrics(false)}
          >
            Back
          </button>
          <button
            className="ml-3 !bg-primary text-[13px] text-white rounded-[3px] !px-3.5 !py-1 disabled:opacity-50"
            disabled={values.metricName === "" || values.dataType === null}
            onClick={handleSubmit}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default AddCustomMetrics;
