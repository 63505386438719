import axios from "axios";
import config from "../../../config";
import types from "../../types";

/*==============================================================
                GET Connector
===============================================================*/

export const getExtensions = () => {
  let response = {};
  return async (dispatch) => {
    try {
      response = await axios.get(`${config.WEB_URL}/api/v1.0/jira/connector/`);
      if (response.data.status === "FAILURE") {
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: true,
            alertType: "error",
            alertMessage: response.data.errorMessage,
          },
        });
        dispatch({
          type: types.SET_ALL_EXTENTIONS,
          payload: [],
        });
        // setIsLoading(false);
      } else if (response.data.status === "SUCCESS") {
        dispatch({
          type: types.SET_ALL_EXTENTIONS,
          payload: response.data.jiraConnectorDO,
        });
        // setIsLoading(false);
      }
    } catch (error) {
      dispatch({
        type: types.SET_ALERT,
        payload: {
          alert: true,
          alertType: "error",
          alertMessage: "Error in get opportunity",
        },
      });
      //   setIsLoading(false);
      //   dispatch(actions.logoutUser(navigate));
    }
    return response;
  };
};

/*==============================================================
                GET IngeratedList
===============================================================*/

export const getIngeratedList = () => {
  let response = {};
  return async (dispatch) => {
    try {
      response = await axios.get(
        `${config.WEB_URL}/api/v1.0/integrationtools/list`
      );
      if (response.data.status === "FAILURE") {
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: true,
            alertType: "error",
            alertMessage: response.data.errorMessage,
          },
        });
        dispatch({
          type: types.SET_ALL_INTEGRATIONLIST,
          payload: [],
        });
        // setIsLoading(false);
      } else if (response.data.status === "SUCCESS") {
        dispatch({
          type: types.SET_ALL_INTEGRATIONLIST,
          payload: response.data,
        });
        // setIsLoading(false);
      }
    } catch (error) {
      dispatch({
        type: types.SET_ALERT,
        payload: {
          alert: true,
          alertType: "error",
          alertMessage: "Error in get opportunity",
        },
      });
      //   setIsLoading(false);
      //   dispatch(actions.logoutUser(navigate));
    }
    return response;
  };
};

/*==============================================================
                GET INTEGRATIONTOOLS BY TENENT
===============================================================*/

export const getintegrationtoolstENENT = () => {
  let response = {};
  return async (dispatch) => {
    try {
      response = await axios.get(
        `${config.WEB_URL}/api/v1.0/integrationtools/list/devt4`
      );
      if (response.data.status === "FAILURE") {
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: true,
            alertType: "error",
            alertMessage: response.data.errorMessage,
          },
        });
        dispatch({
          type: types.SET_ALL_INTEGRATIONLIST_TENENT,
          payload: [],
        });
        // setIsLoading(false);
      } else if (response.data.status === "SUCCESS") {
        dispatch({
          type: types.SET_ALL_INTEGRATIONLIST_TENENT,
          payload: response.data,
        });
        // setIsLoading(false);
      }
    } catch (error) {
      dispatch({
        type: types.SET_ALERT,
        payload: {
          alert: true,
          alertType: "error",
          alertMessage: "Error in get opportunity",
        },
      });
      //   setIsLoading(false);
      //   dispatch(actions.logoutUser(navigate));
    }
    return response;
  };
};

/*==============================================================
                GET EXTENTION DETAILS
===============================================================*/

export const getExtensionsDetails = () => {
  let response = {};
  return async (dispatch) => {
    try {
      response = await axios.get(`${config.WEB_URL}/api/v1.0/sfdc/auth/devt4`);
      if (response.data.status === "FAILURE") {
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: true,
            alertType: "error",
            alertMessage: response.data.errorMessage,
          },
        });
        dispatch({
          type: types.SET_ALL_EXTENTIONS_DETAILS,
          payload: [],
        });
        // setIsLoading(false);
      } else if (response.data.status === "SUCCESS") {
        dispatch({
          type: types.SET_ALL_EXTENTIONS_DETAILS,
          payload: response.data.authConfigDO,
        });
        // setIsLoading(false);
      }
    } catch (error) {
      dispatch({
        type: types.SET_ALERT,
        payload: {
          alert: true,
          alertType: "error",
          alertMessage: "Error in get opportunity",
        },
      });
      //   setIsLoading(false);
      //   dispatch(actions.logoutUser(navigate));
    }
    return response;
  };
};

/*====================================================
                    Create extentions
====================================================*/
export const createExtention = (payload) => {
  // const { payload } = { props };
  let response = {};
  return async (dispatch) => {
    try {
      response = await axios.post(
        `${config.WEB_URL}/api/v1.0/sfdc/auth/`,
        payload
      );

      if (response.data.status === "SUCCESS")
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: true,
            alertType: "success",
            alertMessage: "Extension Created Successfully.",
          },
        });
      else
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: true,
            alertType: "error",
            alertMessage: response.data.errorMessage,
          },
        });
    } catch (error) {
      dispatch({
        type: types.SET_ALERT,
        payload: {
          alert: true,
          alertType: "error",
          alertMessage: "Error in create extension action",
        },
      });
    }
    return response;
  };
};

/*====================================================
                    Create connection
====================================================*/
export const checkConnection = (payload) => {
  // const { payload } = { props };
  let response = {};
  return async (dispatch) => {
    try {
      response = await axios.post(
        `${config.WEB_URL}/api/v1.0/sfdc/auth/checkstatus`,
        payload
      );
      console.log(response, "pp");
      if (response.data.status === 200)
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: true,
            alertType: "success",
            alertMessage: "Connection Successfully.",
          },
        });
      else
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: true,
            alertType: "error",
            alertMessage: "Connection Failed.",
          },
        });
    } catch (error) {
      dispatch({
        type: types.SET_ALERT,
        payload: {
          alert: true,
          alertType: "error",
          alertMessage: "Error in check connection action",
        },
      });
    }
    return response;
  };
};

/*====================================================
                    Create connection
====================================================*/
export const checkJiraConnection = (payload) => {
  // const { payload } = { props };
  let response = {};
  return async (dispatch) => {
    try {
      response = await axios.post(
        `${config.WEB_URL}/api/v1.0/jira/checkstatus/`,
        payload
      );
      if (response.status === 200)
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: true,
            alertType: "success",
            alertMessage: "Connection Successfully.",
          },
        });
      else
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: true,
            alertType: "error",
            alertMessage: "Connection Failed.",
          },
        });
    } catch (error) {
      dispatch({
        type: types.SET_ALERT,
        payload: {
          alert: true,
          alertType: "error",
          alertMessage: "Error in jira action",
        },
      });
    }
    return response;
  };
};
/*====================================================
                    delete connection
====================================================*/
export const deleteConnection = (id) => {
  // const { payload } = { props };
  let response = {};
  return async (dispatch) => {
    try {
      response = await axios.delete(
        `${config.WEB_URL}/api/v1.0/sfdc/auth/${id}`
      );

      if (response.data.status === "SUCCESS")
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: true,
            alertType: "success",
            alertMessage: "Connection Successfully.",
          },
        });
      else
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: true,
            alertType: "error",
            alertMessage: response.data.errorMessage,
          },
        });
    } catch (error) {
      dispatch({
        type: types.SET_ALERT,
        payload: {
          alert: true,
          alertType: "error",
          alertMessage: "Error in delete collection action",
        },
      });
    }
    return response;
  };
};

/*======================================================
                 add jira
=======================================================*/
export const addJira = (payload) => {
  // const { payload } = { props };
  let response = {};
  return async (dispatch) => {
    try {
      response = await axios.post(
        `${config.WEB_URL}/api/v1.0/jira/connector/`,
        payload
      );

      if (response.data.status === "SUCCESS")
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: true,
            alertType: "success",
            alertMessage: "Connection Successfully.",
          },
        });
      else
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: true,
            alertType: "error",
            alertMessage: response.data.errorMessage,
          },
        });
    } catch (error) {
      dispatch({
        type: types.SET_ALERT,
        payload: {
          alert: true,
          alertType: "error",
          alertMessage: "Error in add jira action",
        },
      });
    }
    return response;
  };
};

/*====================================================
                    delete jira connection
====================================================*/
export const deleteJiraConnection = () => {
  // const { payload } = { props };
  let response = {};
  return async (dispatch) => {
    try {
      response = await axios.delete(
        `${config.WEB_URL}/api/v1.0/jira/connector/
        `
      );

      if (response.data.status === "SUCCESS")
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: true,
            alertType: "success",
            alertMessage: "Connection Successfully.",
          },
        });
      else
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: true,
            alertType: "error",
            alertMessage: response.data.errorMessage,
          },
        });
    } catch (error) {
      dispatch({
        type: types.SET_ALERT,
        payload: {
          alert: true,
          alertType: "error",
          alertMessage: "Error in delete jira action",
        },
      });
    }
    return response;
  };
};
