import React from "react";
import { useFieldArray } from "react-hook-form";
import NestedArray from "./NestedArray";

const FiledArray = (props) => {
  const {
    control,
    register,
    activeIndex,
    watch,
    handleStep,
    initialValues,
    handleBackStep,
    filledBucket,
    resSelectedIndex,
    loading,
  } = props;
  const { fields } = useFieldArray({
    control,
    name: "metrics",
  });

  return (
    <>
      <div className="flex items-start w-full">
        <div>
          {fields?.map((item, index) => {
            return (
              <>
                {/* mertics list */}
                <div
                  className={`mb-3 text-center break-words text-white bg-[#002244] w-[200px] text-[13px] rounded-sm py-1 px-2 
                   ${activeIndex === index ? "border-2 !border-primary" : ""}
                   ${index < activeIndex ? "!bg-primary cursor-pointer" : ""}
                    ${
                      filledBucket?.some((d) => d === index)
                        ? "!bg-primary"
                        : ""
                    }
                   ${
                     index === resSelectedIndex
                       ? "border-2 !border-[#BCCED5]"
                       : ""
                   }
              `}
                >
                  {item?.metricsName} ({item?.weightage})
                </div>
              </>
            );
          })}
        </div>
        {fields?.map((item, index) => {
          return (
            <>
              {activeIndex === index && (
                <div className="pl-4 ml-4 border-l-2 w-10/12 relative">
                  <div>
                    <div className="pb-2 w-full border-b-2 border-[#C5C5C5]">
                      <h2 className="mb-0 text-base font-normal">
                        {resSelectedIndex !== null
                          ? "Bucket for"
                          : "Create Bucket for"}
                        <span className="font-semibold">
                          {" "}
                          {item?.metricsName} ({item?.weightage}%)
                        </span>
                      </h2>
                    </div>
                    <NestedArray
                      nestIndex={index}
                      {...{
                        control,
                        register,
                        activeIndex,
                        watch,
                        handleStep,
                        initialValues,
                        handleBackStep,
                        resSelectedIndex,
                        loading,
                      }}
                    />
                  </div>
                </div>
              )}
            </>
          );
        })}
      </div>
    </>
  );
};

export default FiledArray;
