import { Select } from "antd";
import React from "react";

const SelectFields = (props) => {
  const { value, handleChange, placeholader, extraClass, options } = props;
  return (
    <Select
      className={`${extraClass ? extraClass : ""} ot-select border-[#797979]`}
      onChange={handleChange}
      options={options}
      value={value}
      placeholder={placeholader}
    />
  );
};

export default SelectFields;
