import { Checkbox, Spin, Table } from "antd";
import React, { useState } from "react";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch } from "react-redux";
import actions from "../../store/actions";
import { LoadingOutlined } from "@ant-design/icons";
const UserTable = (props) => {
  const {
    // handleAddModalForm,
    userList,
    handleOnTableChange,
    currentPage,
    isLoading,
    handleEdit,
    handleAdd,
    setIsUpdateTable,
  } = props;
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState({
    id: "",
    active: false,
  });
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const handleChangeCheckbox = async (id) => {
    try {
      setIsActive({
        id: id,
        active: true,
      });
      const res = await dispatch(actions.getActiveUser(id));
      if (res) {
        setIsActive({
          id: "",
          active: false,
        });
        setIsUpdateTable(true);
      }
    } catch (error) {
      setIsActive({
        id: "",
        active: false,
      });
      console.log(error);
    }
  };

  const columns = [
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
      sorter: (a) => a,
      render: (firstName) => <div className="py-3 px-2">{firstName}</div>,
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
      sorter: (a) => a,
      render: (name) => <div className="py-3 px-2"> {name}</div>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: (a) => a,
      render: (amount) => <div className="py-3 px-2">{amount}</div>,
    },
    {
      title: "Company",
      key: "organisation",
      dataIndex: "organisation",
      sorter: (a) => a,
      render: (organisation) => <div className="py-3 px-2">{organisation}</div>,
    },
    {
      title: "Role",
      key: "role",
      dataIndex: "role",
      sorter: (a) => a,
      render: (role) => (
        <div className="py-3 px-2">{role.split("_").join("  ")}</div>
      ),
    },
    {
      title: "Action",
      key: "active",
      dataIndex: "active",
      sorter: (a) => a,
      render: (active, data) => (
        <div className="py-3 px-2 flex items-center">
          <Checkbox
            defaultChecked={active === true ? true : false}
            onChange={() => handleChangeCheckbox(data?.id)}
            disabled={active}
          />

          {isActive.id === data.id && isActive.active && (
            <Spin className="!ml-2" indicator={antIcon} />
          )}
        </div>
      ),
    },
    {
      title: "Edit",
      key: "edit",
      dataIndex: "edit",
      sorter: (a) => a,
      render: (edit, data) => (
        <div
          className="py-3 px-2"
          onClick={() => {
            handleEdit(data);
          }}
        >
          <EditIcon className="!text-primary !text-lg ml-5 cursor-pointer" />
        </div>
      ),
    },
  ];

  return (
    <div className="relative  user-table pl-3">
      <div className="flex items-center absolute left-3 top-9">
        <MonetizationOnOutlinedIcon className=" !text-lg !leading-0 mr-1" />{" "}
        <h5 className="text-base mb-0">User ({userList?.length})</h5>
      </div>
      <button
        className="!bg-primary text-white text-xs p-2 rounded-sm top-9 absolute right-4 z-50"
        onClick={handleAdd}
      >
        + Add User
      </button>
      <Table
        columns={columns}
        // className={"opportunitiesExpandedTable"}
        dataSource={userList}
        loading={isLoading}
        pagination={{
          position: ["topRight"],
          pageSize: 10,
          current: currentPage,
          simple: true,
          total: userList?.length,
          onChange: (e) => handleOnTableChange(e),
        }}
        scroll={{ y: 640, x: 1000 }}
      />
    </div>
  );
};

export default UserTable;
