import React, { useEffect } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useState } from "react";
import DeleteExtension from "./DeleteExtension";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import actions from "../../store/actions";
import CancelIcon from "@mui/icons-material/Cancel";
import JiraComponent from "./JiraComponent";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const ManageExtension = (props) => {
  const { handleExtension, extentionDetails, extentionType, extentionsList } =
    props;
  const [isDeleteExtension, setIsDeleteExtension] = useState(false);
  const [checkConnection, setCheckConnection] = useState(true);
  const [checkLoader, setCheckConnectionLoader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const dispatch = useDispatch();
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  // const []
  const { register, handleSubmit, errors, setValue, watch } = useForm({
    defaultValues: {
      userName: "",
      password: "",
      clientId: "",
      clientSecret: "",
      token: "",
    },
  });

  const watchF = watch();

  const onSubmit = async (values) => {
    setSaveLoader(true);
    const payload = {
      clientId: values?.clientId,
      clientSecret: values?.clientSecret,
      password: values?.password,
      tenantId: extentionDetails?.tenantId,
      token: values?.token,
      username: values?.userName,
      id: extentionDetails?.id,
    };

    const res = await dispatch(actions.createExtention(payload));
    if (res.status === 200) {
      setSaveLoader(false);
      handleExtension();
    }
  };
  const handleDeleteExtension = async (e) => {
    e.stopPropagation();
    const res = null;
    if (extentionType === "sdfc") {
      const id = extentionDetails.id;

      res = await dispatch(actions.deleteConnection(id));
    }

    if (extentionType === "jira") {
      res = await dispatch(actions.deleteJiraConnection());
    }
    if (res) {
      setIsDeleteExtension(!isDeleteExtension);
      handleExtension();
    }
  };

  const handleCheckConnection = async () => {
    setCheckConnectionLoader(true);
    const payload = {
      clientId: watchF.clientId,
      clientSecret: watchF.clientSecret,
      password: watchF.password,
      tenantId: extentionDetails.tenantId,
      token: watchF.token,
      username: watchF.username,
      id: extentionDetails.id,
    };
    const res = await dispatch(actions.checkConnection(payload));
    // console.log(res, "responsive");
    if (res) {
      setCheckConnection(res.data);
      setCheckConnectionLoader(false);
    }
  };

  useEffect(() => {
    if (extentionDetails) {
      setValue("userName", extentionDetails.username);
      setValue("password", extentionDetails.password);
      setValue("clientId", extentionDetails.clientId);
      setValue("clientSecret", extentionDetails.clientSecret);
      setValue("token", extentionDetails.token);
    }
  }, [extentionDetails]);

  return (
    <>
      {extentionType === "sdfc" && (
        <form
          className="w-full"
          id="manageExtention"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="bg-white pt-4 px-14 pb-14 flex flex-col ">
            <div className="w-10/12">
              <div className="flex items-center justify-between">
                <h2 className=" text-base mb-0 flex items-center">
                  <img
                    src="/salesforces.jfif"
                    className="w-[62px] mr-5"
                    alt="salesforces"
                  />
                  Sales Force Authentication Settings
                </h2>
                <div
                  className="bg-[#002244] text-white text-sm py-2 px-4 rounded cursor-pointer"
                  onClick={handleCheckConnection}
                >
                  Test
                  {checkLoader && (
                    <Spin indicator={antIcon} className="!ml-3" />
                  )}
                  {!checkLoader && checkConnection && (
                    <CheckCircleIcon className="!text-primary !text-lg ml-2" />
                  )}
                  {!checkLoader && !checkConnection && (
                    <CancelIcon className="!text-[#EE584C] !text-lg ml-2" />
                  )}
                </div>
              </div>
              <div className="flex flex-col">
                <input
                  {...register("userName")}
                  name={`userName`}
                  id={`userName`}
                  placeholder="User Name"
                  className={`ot-input ot-input--manage mt-7 ${
                    errors?.userName ? "ot-input-error" : ""
                  }`}
                />
                <input
                  {...register("password")}
                  name={`password`}
                  id={`password`}
                  type="password"
                  placeholder="Password"
                  className={`ot-input ot-input--manage mt-7 ${
                    errors?.password ? "ot-input-error" : ""
                  }`}
                />
                <input
                  {...register("clientId")}
                  name={`clientId`}
                  id={`clientId`}
                  placeholder="Clent Id"
                  className={`ot-input ot-input--manage mt-7 ${
                    errors?.clientId ? "ot-input-error" : ""
                  }`}
                />
                <input
                  {...register("clientSecret")}
                  name={`clientSecret`}
                  id={`clientSecret`}
                  type="password"
                  placeholder="Client Secret"
                  className={`ot-input ot-input--manage mt-7 ${
                    errors?.clientSecret ? "ot-input-error" : ""
                  }`}
                />
                <input
                  {...register("token")}
                  name={`token`}
                  id={`token`}
                  type="password"
                  placeholder="Token"
                  className={`ot-input ot-input--manage mt-7 ${
                    errors?.token ? "ot-input-error" : ""
                  }`}
                />
              </div>
            </div>
            <div className="flex justify-center w-10/12 mt-5">
              {/* <button
                type="button"
                className=" text-white !bg-gray-500 text-[13px]  rounded-[3px] !px-5 w-[139px] !py-1"
                onClick={() => handleExtension()}
              >
                Cancel
              </button> */}
              <button
                type="button"
                className=" text-white !bg-[#EE584C] py-2 px-4 rounded text-sm disabled:opacity-50 mr-3"
                onClick={() => handleDeleteExtension()}
              >
                Delete
              </button>
              <button
                className=" !bg-primary text-white py-2 px-4 rounded text-sm disabled:opacity-50 submit-btn ml-3"
                type="submit"
                htmlFor="manageExtention"
              >
                {saveLoader && <Spin indicator={antIcon} className="!mr-3" />}
                Save
              </button>
            </div>
          </div>
        </form>
      )}
      {extentionType === "jira" && (
        <JiraComponent
          {...{ extentionsList, handleExtension, handleDeleteExtension }}
        />
      )}
      {isDeleteExtension && (
        <DeleteExtension {...{ isDeleteExtension, handleDeleteExtension }} />
      )}
    </>
  );
};

export default ManageExtension;
