import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
const TopFiveOpportunitiesTableComponent = () => {
  const rows = [
    {
      clientName: "TCS",
      amount: "$123k",
      details: "Change in form",
      appends: 2,
      probability: 50.12,
      priority: 2,
      closingOn: "12 Nov 2022",
      state: "Discovery",
    },
    {
      clientName: "WIPRO",
      amount: "$56k",
      details: "Add 50 orders",
      appends: 4,
      probability: 34.65,
      priority: 1,
      closingOn: "18 Nov 2022",
      state: "Value Proposition",
    },
    {
      clientName: "Amazon",
      amount: "$25k",
      details: "Change unit capacity",
      appends: 6,
      probability: 87.45,
      priority: 3,
      closingOn: "18 Nov 2022",
      state: "Closed Won",
    },
    {
      clientName: "ola",
      amount: "$456K",
      details: "Delete Items",
      appends: 2,
      probability: 54.12,
      priority: 3,
      closingOn: "18 Nov 2022",
      state: "Discovery",
    },
    {
      clientName: "Accenture",
      amount: "$545k",
      details: "Remove addons",
      appends: 1,
      probability: 45.78,
      priority: 1,
      closingOn: "18 Nov 2022",
      state: "Closed Won",
    },
  ];

  const starArr = [1, 2, 3];
  return (
    <TableContainer
      component={Paper}
      className="mt-2 border-t-4 !border-primary !rounded-none"
    >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Client Name</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Details</TableCell>
            <TableCell>Appends</TableCell>
            <TableCell>Probability</TableCell>
            <TableCell>Priority</TableCell>
            <TableCell>Closing on</TableCell>
            <TableCell>State</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.clientName}
              </TableCell>
              <TableCell>{row.amount}</TableCell>
              <TableCell className=" whitespace-nowrap text-ellipsis overflow-hidden w-40">
                {row.details}
              </TableCell>
              <TableCell>{row.appends}</TableCell>
              <TableCell>{row.probability}</TableCell>
              <TableCell width={"15%"}>
                {starArr.map((data, index) => (
                  <span key={index}>
                    {index < row.priority ? (
                      <StarIcon className="!text-primary !text-sm" />
                    ) : (
                      <StarBorderIcon className="ml-1 !text-primary !text-sm" />
                    )}
                  </span>
                ))}
              </TableCell>
              <TableCell>{row.closingOn}</TableCell>
              <TableCell>{row.state}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TopFiveOpportunitiesTableComponent;
