import types from "../../types";

const initialState = {
  metricsList: [],
  selectedMetricsList: [],
  bucketListByMetrics: [],
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_All_METRICS:
      return {
        ...state,
        metricsList: action.payload,
      };
    case types.SET_SELECTED_METRICS:
      return {
        ...state,
        selectedMetricsList: action.payload,
      };
    case types.SET_BUCKETS_LIST_BY_METRIC:
      return {
        ...state,
        bucketListByMetrics: action.payload,
      };

    default:
      return { ...state };
  }
}
