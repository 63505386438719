import types from "../../types";

const initialState = {
  accounts: [],
  accountDicts: {},
};

export default function bucketReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_ACCOUNTS:
      return {
        ...state,
        accounts: action.payload,
      };
    case types.SET_ACCOUNTS_DICT:
      return {
        ...state,
        accountDicts: action.payload,
      };
    default:
      return { ...state };
  }
}
