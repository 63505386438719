import { Button } from "antd";
import React from "react";

const GreenBtn = (props) => {
  const { loading = false, text, extrClass, type = "button", id = "" } = props;
  return (
    <Button
      type={type}
      htmlType={id}
      loading={loading}
      className={`!bg-primary rounded-sm px-2 py-1 text-white capitalize text-xs ${
        extrClass ? extrClass : ""
      }`}
    >
      {text}
    </Button>
  );
};

export default GreenBtn;
