import axios from "axios";
import config from "../../../config";
import types from "../../types";
import slugify from "react-slugify";

/*==============================================================
                GET Account Buckets
===============================================================*/

export const createAccountAction = (data) => {
  let response = {};
  return async (dispatch) => {
    try {
      response = await axios.post(`${config.WEB_URL}/api/v1.0/accounts`, data);
      if (response.status === 200) {
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: true,
            alertType: "success",
            alertMessage: "Account added",
          },
        });
      } else {
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: true,
            alertType: "error",
            alertMessage: "Error in Account List",
          },
        });
      }
    } catch (error) {
      dispatch({
        type: types.SET_ALERT,
        payload: {
          alert: true,
          alertType: "error",
          alertMessage: "Error in Account List",
        },
      });
    }
    return response;
  };
};

export const listAccounts = () => {
  let response = {};
  return async (dispatch) => {
    try {
      response = await axios.get(`${config.WEB_URL}/api/v1.0/accounts/tenant`);
      let dictData = {};
      if (response.status === 200) {
        // convert it to dict for better performance.
        response.data.map((i) => (dictData[slugify(i["accountName"])] = i));
        dispatch({
          type: types.SET_ACCOUNTS_DICT,
          payload: dictData,
        });
        dispatch({
          type: types.SET_ACCOUNTS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: true,
            alertType: "error",
            alertMessage: response.data.errorMessage || "Something Went Wrong",
          },
        });
        dispatch({
          type: types.SET_ACCOUNTS,
          payload: [],
        });
      }
    } catch (error) {
      dispatch({
        type: types.SET_ALERT,
        payload: {
          alert: true,
          alertType: "error",
          alertMessage: "Error in listAccountBucket action",
        },
      });
    }
    return response;
  };
};
