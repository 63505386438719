import { LoadingOutlined } from "@ant-design/icons";
import CloseIcon from "@mui/icons-material/Close";
import { Select, Spin } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import slugify from "react-slugify";
import actions from "../../store/actions";
import TextArea from "../common/TextArea";
import GreenBtn from "../common/greenBtn";
import OpportunitiesNotesModal from "./OpportunitiesNotesModal";

const OpportunitiesDetails = (props) => {
  const {
    handleExpanded,
    selectedOpportunity,
    // btnHit,
    setBtnHit,
    accountBucketName,
    setAccountBucketName,
  } = props;
  const dispatch = useDispatch();
  // const [description, setDescription] = useState("");
  const { opportunitiesNotes } = useSelector((state) => state.opportunities);
  const { accountBucketsDicts, accountBuckets } = useSelector(
    (state) => state.buckets
  );
  const [showInput, setShowInput] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [notesView, setNotesView] = useState(false);
  // const [accountBucket, setAccountBucket] = useState("");
  const [selectedNotes, setSelectedNotes] = useState(null);
  const [isAccountAdded, setIsAccountAdded] = useState(false);
  const [loder, setLoader] = useState(false);

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const { accountDicts } = useSelector((state) => state.accounts);
  const [loading, setLoading] = useState({
    notes: false,
  });
  const navigate = useNavigate();

  // TODO: need to add loader on opportunity notes.

  let options = accountBuckets.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  const handleChange = (e) => {
    setAccountBucketName(e);
    // setAccountBucketName(() => {
    // console.log(e);
    // let temp = {};
    // options.map((data) => {
    //   if (data.value === e) {
    //     temp.label = data.label;
    //     temp.value = data.value;
    //   }
    // });
    // return temp;
    // });
  };

  // const handleChangeTextArea = (e) => {
  //   setDescription(e.target.value);
  // };

  const buttonClickHandler = async () => {
    setBtnHit(true);
    setLoader(true);
    const res = await dispatch(
      actions.createAccountAction({
        accountName: selectedOpportunity?.parameters?.account?.Name,
        bucket: accountBucketName,
        oppId: selectedOpportunity?.id,
      })
    );
    if (res) {
      setLoader(false);
    }
  };

  const handleSelectedNotes = async () => {
    try {
      setLoading({ ...loading, notes: true });
      const res = await dispatch(
        actions.getOpportunitiesNotes(selectedOpportunity?.id)
      );
      if (res) {
        setLoading({ ...loading, notes: false });
      }
    } catch (error) {
      console.log(error);
      setLoading({ ...loading, notes: false });
    }
  };

  const truncate = (string, n) =>
    string?.length > n ? `${string.substr(0, n - 1)}` : string;

  const handleOnClickShowInput = (key) => {
    console.log(key);
    setActiveIndex(key);
    setShowInput(true);
  };

  const submitFRHandler = (data) => {
    console.log("submit fr handler called.");
    navigate(`/submit-feature-request`);
    localStorage.setItem("selectedNote", JSON.stringify(data));
    // return <Router to="/submit-feature-request" />;
  };

  const handleAddModalForm = () => {
    setNotesView(!notesView);
  };

  useEffect(() => {
    if (selectedOpportunity) handleSelectedNotes();
    if (accountDicts[slugify(selectedOpportunity?.parameters?.account?.Name)]) {
      setAccountBucketName({
        value:
          accountBucketsDicts[
            accountDicts[
              slugify(selectedOpportunity?.parameters?.account?.Name)
            ]["bucket"]
          ]["name"],
        name: accountBucketsDicts[
          accountDicts[slugify(selectedOpportunity?.parameters?.account?.Name)][
            "bucket"
          ]
        ]["name"],
      });
      setIsAccountAdded(true);
    } else {
      setIsAccountAdded(false);
      setAccountBucketName({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOpportunity, accountDicts, accountBucketsDicts]);

  return (
    <>
      <div className=" border-l-2 border-[#DFDFDF] pl-4 min-h-full ">
        <div className="flex justify-between items-center border-b-2 border-[#DFDFDF]">
          <h4 className="text-base mb-0">{selectedOpportunity?.name}</h4>
          <button
            className=" bg-transparent border-none p-0"
            onClick={handleExpanded}
          >
            <CloseIcon className=" !text-[#333333]" />
          </button>
        </div>
        <div className="flex justify-between border-b-2 border-[#DFDFDF] pt-6  pb-3">
          <div className="w-5/12">
            <h5 className="text-base mb-0">Details</h5>
            {/* Account Name */}
            <div className="flex pt-4">
              <h5 className="text-xs mb-0 w-5/12">Account Name</h5>{" "}
              <h5 className="text-xs mb-0 w-5/12  pl-1">
                {selectedOpportunity?.parameters?.account?.Name}
              </h5>{" "}
            </div>
            {/* Customer Type */}
            <div className="flex items-center pt-8">
              <h5 className="text-xs mb-0 w-5/12">Customer Type</h5>{" "}
              {!isAccountAdded && (
                <>
                  <Select
                    value={accountBucketName}
                    onChange={handleChange}
                    className=" w-36 border-[#797979] !rounded"
                    options={options}
                  />
                  <h5 className="text-[#FA0202] pl-2 mb-0 pt-0.5">*</h5>
                </>
              )}
              {isAccountAdded && (
                <>
                  {accountBucketName?.name ?? (
                    <>
                      {options?.map((data, index) => (
                        <h5>
                          {data?.value === accountBucketName && data?.label}
                        </h5>
                      ))}
                    </>
                  )}
                </>
              )}
            </div>
            {/* ID */}
            <div className="flex pt-4">
              <h5 className="text-xs mb-0 w-5/12">ID</h5>{" "}
              <h5 className="text-xs mb-0 w-5/12  pl-1">
                {selectedOpportunity?.id}
              </h5>{" "}
            </div>
            {/* Probability */}
            <div className="flex pt-4">
              <h5 className="text-xs mb-0 w-5/12">Probability</h5>{" "}
              <h5 className="text-xs mb-0 w-5/12  pl-1">
                {selectedOpportunity?.probability}
              </h5>{" "}
            </div>
            {/* Priority */}
            <div className="flex pt-4">
              <h5 className="text-xs mb-0 w-5/12">Priority</h5>{" "}
              <h5 className="text-xs mb-0 w-5/12  pl-1">
                {selectedOpportunity?.priority === "High" ? (
                  <GreenBtn
                    {...{
                      text: selectedOpportunity?.priority,
                      extrClass: "w-14 !cursor-auto",
                    }}
                  />
                ) : selectedOpportunity?.priority === "Medium" ? (
                  <GreenBtn
                    {...{
                      text: selectedOpportunity?.priority,
                      extrClass: "!bg-[#EB901C] w-14  !cursor-auto",
                    }}
                  />
                ) : (
                  <GreenBtn
                    {...{
                      text: selectedOpportunity?.priority,
                      extrClass: "!bg-[#2BB8FF] w-14  !cursor-auto",
                    }}
                  />
                )}
              </h5>{" "}
            </div>
            {/* Amount */}
            <div className="flex pt-4">
              <h5 className="text-xs mb-0 w-5/12">Amount</h5>{" "}
              <h5 className="text-xs mb-0 w-5/12  pl-1">
                $ {selectedOpportunity?.amount}
              </h5>{" "}
            </div>
            {/* ROI */}
            <div className="flex pt-4">
              <h5 className="text-xs mb-0 w-5/12">ROI</h5>{" "}
              <h5 className="text-xs mb-0 w-5/12  pl-1">
                $ {selectedOpportunity?.parameters?.expectedRevenue}
              </h5>{" "}
            </div>
            {/* OT Score */}
            <div className="flex pt-4">
              <h5 className="text-xs mb-0 w-5/12">OT Score</h5>{" "}
              <h5 className="text-xs mb-0 w-5/12  pl-1">
                {selectedOpportunity?.otscore}
              </h5>{" "}
            </div>
          </div>
          <div className="w-7/12 pl-8">
            <h5 className="text-base mb-0 ">Add Description</h5>{" "}
            <TextArea
              {...{
                readOnly: isAccountAdded,
                // handleChange: handleChangeTextArea,
                value: selectedOpportunity?.parameters?.description,
                extraClass: "mt-4 h-72",
                placeholader: "No Description Found",
              }}
            />
            <div className="text-right mt-3">
              <button
                disabled={isAccountAdded}
                onClick={() => {
                  buttonClickHandler();
                  setIsAccountAdded(true);
                }}
                className=" !bg-primary text-white py-2 px-4 rounded text-sm disabled:opacity-50 submit-btn"
              >
                {loder && <Spin indicator={antIcon} className="!mr-3" />}
                Save
              </button>
            </div>
          </div>
        </div>
        <div className="pt-6">
          <h5 className="text-base mb-0">
            Opportunity Notes ({opportunitiesNotes?.length})
          </h5>

          {loading.notes && (
            <div className="h-48 w-full flex items-center justify-center">
              <Spin />
            </div>
          )}
          {!loading?.notes && opportunitiesNotes?.length === 0 && (
            <div>
              <h2 className=" text-3xl text-center py-5">Notes not found</h2>
            </div>
          )}
          {!loading?.notes &&
            opportunitiesNotes?.map((data, key) => (
              <div
                key={key}
                className="flex justify-between items-center border-2 py-3 px-2 border-[#DFDFDF]"
              >
                <div className="w-7/12">
                  <h3 className="text-xs mb-0">{data?.owner}</h3>
                  <h2 className="text-xs mb-0 pt-1 !text-primary font-bold">
                    {data.title}
                  </h2>

                  {showInput && activeIndex === key ? (
                    <h5
                      // onClick={() => setShowInput(false)}
                      className="text-xs mb-0 pt-1"
                    >
                      {data.body}
                    </h5>
                  ) : (
                    <h5
                      className="text-xs mb-0 pt-1"
                      onClick={() => handleOnClickShowInput(key)}
                    >
                      {truncate(data?.body, 50)}&nbsp; &nbsp;
                      <span className="!text-primary cursor-pointer">
                        <strong>{data.body?.length > 50 && "more"}</strong>
                      </span>
                    </h5>
                  )}
                </div>
                <h5 className="w-3/12 text-xs mb-0">
                  {moment(data?.lastModifiedDate).fromNow()}
                </h5>
                {isAccountAdded && (
                  // <Link to={"/submit-feature-request"}>
                  <button
                    className="text-xs mb-0 !bg-primary text-white py-2 px-4 rounded"
                    onClick={() => submitFRHandler(data)}
                  >
                    Submit FR
                  </button>
                  // </Link>
                )}{" "}
                {/* code commented */}
                {/* <button
                {showInput && activeIndex === key ? (
                  <h5
                    // onClick={() => setShowInput(false)}
                    className="text-xs mb-0 pt-1"
                  >
                    {data.body}
                  </h5>
                ) : (
                  <h5
                    className="text-xs mb-0 pt-1"
                    onClick={() => handleOnClickShowInput(key)}
                  >
                    {truncate(data.body, 50)}&nbsp; &nbsp;
                    <span className="!text-primary cursor-pointer">
                      <strong>{data.body?.length > 50 && "more"}</strong>
                    </span>
                  </h5>
                )}
              </div>
              <h5 className="w-3/12 text-xs mb-0">
                {moment(data.lastModifiedDate).fromNow()}
              </h5>
              {isAccountAdded && (
                // <Link to={"/submit-feature-request"}>
                <Link>
                  <button
                    onClick={() => submitFRHandler(data)}
                    className="text-xs mb-0 !bg-primary text-white py-2 px-4 rounded"
                  >
                    Submit FR
                  </button>
                </Link>
              )}{" "}
              {/* <button
                className="text-xs mb-0 !bg-[#002244] text-white py-2 px-4 rounded"
                onClick={() => {
                  handleAddModalForm();
                  setSelectedNotes(data);
                }}
              >
                View
              </button> */}
              </div>
            ))}
        </div>
      </div>
      {notesView && (
        <OpportunitiesNotesModal
          {...{ handleAddModalForm, notesView, selectedNotes }}
        />
      )}
    </>
  );
};

export default OpportunitiesDetails;
