import { Modal, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../store/actions";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const AppendListModal = (props) => {
  const {
    handleShowAppendFr,
    isShowAppendFr,
    selectedFeatures,
    handleExpanded,
  } = props;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDeleted, setIsLoadingDeleted] = useState(false);
  const navigate = useNavigate();
  const { appendList } = useSelector((state) => state.features);
  const antIcon = (
    <LoadingOutlined
      style={{ fontSize: 14, color: "white", marginRight: 8 }}
      spin
    />
  );

  const handelDelete = async (id) => {
    setIsLoadingDeleted(true);
    if (id) {
      const res = await dispatch(actions.deleteAppend(id));
      if (res.status === 200) {
        handleShowAppendFr();
      }
      setIsLoadingDeleted(false);
    }
  };
  const columns = [
    {
      title: "FR ID",
      dataIndex: "featurecustomid",
      key: "featurecustomid",
      sorter: (a, b) => a.featurecustomid.length - b.featurecustomid.length,
      render: (featurecustomid, data) => (
        <div
          onClick={() => {
            handleExpanded();
            handleShowAppendFr();
            // handleSelectedData(data);
          }}
          className="py-3 px-2 !text-primary"
        >
          {featurecustomid ?? "-"}
          {/* {shortText(text, 4, true)} */}
        </div>
      ),
    },
    {
      title: "Appended Name",
      dataIndex: "jiraTitle",
      key: "jiraTitle",
      render: (jiraTitle, data) => (
        <div className="py-3 px-2"> {jiraTitle}</div>
      ),
    },
    {
      title: "Feature Priority",
      dataIndex: "featurePriority",
      key: "featurePriority",
      sorter: (a, b) => a?.featurePriority - b?.featurePriority,
      render: (featurePriority) => (
        <div className="py-3 px-2">{featurePriority}</div>
      ),
    },
    {
      title: "Appended By",
      key: "submitterName",
      dataIndex: "submitterName",
      sorter: (a, b) => a?.submitterName - b?.submitterName,
      render: (submitterName) => (
        <div className="py-3 px-2">{submitterName}</div>
      ),
    },
    {
      title: "Appended Score",
      key: "appendScore",
      dataIndex: "appendScore",
      sorter: (a, b) => a?.appendScore - b?.appendScore,
      render: (appendScore) => <div className="py-3 px-2">{appendScore}</div>,
    },
    {
      title: "Scales Score",
      key: "salesScore",
      dataIndex: "salesScore",
      sorter: (a, b) => a?.salesScore - b?.salesScore,
      render: (salesScore) => <div className="py-3 px-2">{salesScore}</div>,
    },
    {
      title: "Product Score",
      key: "productScore",
      dataIndex: "productScore",
      sorter: (a, b) => a?.productScore - b?.productScore,
      render: (productScore) => <div className="py-3 px-2">{productScore}</div>,
    },
    {
      title: "Action",
      key: "featureId",
      dataIndex: "featureId",
      render: (featureId) => (
        <div
          className="py-3 px-2 flex items-center deleteIcon"
          onClick={() => handelDelete(featureId)}
        >
          <DeleteIcon className="!text-primary" />
          {isLoadingDeleted && <Spin indicator={antIcon} />}
        </div>
      ),
    },
  ];

  console.log(appendList, "appendList");
  useEffect(() => {
    if (selectedFeatures?.destinationId) {
      dispatch(
        actions.getAppendedListById({
          ...{ navigate, id: selectedFeatures?.destinationId, setIsLoading },
        })
      );
    }
  }, [dispatch]);

  return (
    <Modal
      width={"1000px"}
      open={isShowAppendFr}
      onCancel={handleShowAppendFr}
      okText={"Submit"}
      okButtonProps={"submit"}
      onOk={() => handleShowAppendFr()}
      className="appendListModal"
    >
      <h2 className=" text-base mb-0 pb-2 border-b-[1px] border-[#BCCED5]">
        Append List
      </h2>
      <Table
        columns={columns}
        className={"opportunitiesExpandedTable"}
        dataSource={appendList || []}
        loading={isLoading}
        pagination={false}
        scroll={{ y: 640 }}
      />
    </Modal>
  );
};

export default AppendListModal;
