import axios from "axios";
import config from "../../../config";
import types from "../../types";

/*==============================================================
                GET Account Buckets
===============================================================*/

export const getAccountTypes = () => {
  let response = {};
  return async (dispatch) => {
    try {
      response = await axios.get(
        `${config.WEB_URL}/api/v1.0/metric/bucket/type/customer/sales/`
      );
      let dictData = {};
      if (response.status === 200 && response.data.status === "SUCCESS") {
        response.data.bucketsDTOS.map((i) => (dictData[i.id] = i));
        dispatch({
          type: types.SET_ACCOUNTS_BUCKETS_DICT,
          payload: dictData,
        });
        dispatch({
          type: types.SET_ACCOUNTS_BUCKETS,
          payload: response.data.bucketsDTOS,
        });
      } else {
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: true,
            alertType: "error",
            alertMessage: response.data.errorMessage || "Something Went Wrong",
          },
        });
        dispatch({
          type: types.SET_ACCOUNTS_BUCKETS,
          payload: [],
        });
      }
    } catch (error) {
      dispatch({
        type: types.SET_ALERT,
        payload: {
          alert: true,
          alertType: "error",
          alertMessage: "Error in  Account type action",
        },
      });
    }
    return response;
  };
};
