import { Modal } from "antd";
import moment from "moment";
import React from "react";

const OpportunitiesNotesModal = (props) => {
  const { handleAddModalForm, notesView, selectedNotes } = props;
  return (
    <Modal
      width={"50%"}
      open={notesView}
      onCancel={handleAddModalForm}
      // okText={"Submit"}
      // onOk={handleSubmit}
      footer={false}
    >
      <div className="px-1">
        <h4 className="text-lg mb-3">Opportunity Notes</h4>
        <div className="max-h-[400px] overflow-y-auto">
          <h5 className="text-sm ">
            {selectedNotes?.owner}{" "}
            <span className="ml-8 text-xs">
              {moment(selectedNotes?.lastModifiedDate).fromNow()}
            </span>{" "}
          </h5>
          <h2 className="text-xs mb-0 pt-1 !text-primary font-bold">
            {selectedNotes?.title}
          </h2>
          <h5 className="text-xs mb-0 pt-6 pr-5">
            {selectedNotes?.body ?? "-"}
          </h5>
        </div>
      </div>
    </Modal>
  );
};

export default OpportunitiesNotesModal;
