import React, { Fragment } from "react";
import "./App.scss";
import setAuthToken from "./utils/setAuthToken";
import store from "./store/store";
import types from "./store/types";
import jwt from "jwt-decode";
import Routes from "./routes";
// import AlertBox from "./components/common/Alert";
import "antd/dist/antd.css";

let otAuthToken = JSON.parse(localStorage.getItem("otAuthToken"));

if (otAuthToken) {
  const token = otAuthToken;
  const user = jwt(token);
  setAuthToken(token);
  store.dispatch({
    type: types.SET_USER,
    payload: user,
  });
}

function App() {
  return (
    <Fragment>
      {/* <AlertBox /> */}
      <Routes />
    </Fragment>
  );
}

export default App;
