import React, { Fragment, useState, useEffect } from "react";
import LoginForm from "./LoginForm";
import ForgetPassword from "./ForgetPassword";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
function Login() {
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.auth);
  const [forgetPasswordForm, setForgetPasswordForm] = useState(false);

  useEffect(() => {
    if (isAuthenticated === true && user) {
      if (user.roles[0] === "ADMIN") {
        navigate("/dashboard-admin");
      } else if (user.roles[0] === "SALES_ADMIN") {
        navigate("/dashboard-sales-person");
      } else if (user.roles[0] === "PRODUCT_ADMIN") {
        navigate("/dashboard-product-person");
      }
    }
  }, [isAuthenticated, navigate, user]);

  if (!isAuthenticated) {
    return (
      <Fragment>
        <div className="login-container d-flex align-content-center">
          <div className="section-one d-flex text-center flex-column">
            <div className="logo-section">
              <img src={require("../../assets/images/Logo.png")} alt=""></img>
            </div>
            <div className="quote-section">
              <h1>Welcome to OT</h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Pellentesque sed urna et sem ultrices vulputate sed at elit.
              </p>
            </div>
            <div className="footer-section">
              2022 © OT-Service All rights reserved MVP-1.1
            </div>
          </div>
          <div className="section-two">
            {!forgetPasswordForm ? (
              <LoginForm setForgetPasswordForm={setForgetPasswordForm} />
            ) : (
              <ForgetPassword />
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Login;
