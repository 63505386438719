import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../store/actions";
import Header from "../Header/Header";
import SideBar from "../sidebar/SideBar";
import ExtentionsList from "./ExtentionsList";
import ManageExtension from "./ManageExtension";
function ExtentionsMain() {
  const toggleState = useSelector((state) => state.dashboard.toggleSidebar);
  const [editExtensions, setEditExtensions] = useState(false);
  const [extentionType, setExtentionType] = useState("");
  const dispatch = useDispatch();

  const handleExtension = () => {
    setEditExtensions(!editExtensions);
  };

  const {
    extentionsList,
    // integrationtoolsList,
    // tenantIntegrationtoolsList,
    extentionDetails,
  } = useSelector((state) => state.extentions);

  useEffect(() => {
    dispatch(actions.getExtensions());
    dispatch(actions.getIngeratedList());
    dispatch(actions.getintegrationtoolstENENT());
  }, []);

  useEffect(() => {
    if (editExtensions) {
      if (extentionType === "sdfc") {
        dispatch(actions.getExtensionsDetails());
      }
      if (extentionType === "jira") {
        dispatch(actions.getExtensions());
      }
    }
  }, [editExtensions]);

  return (
    <Fragment>
      <div
        className={`d-flex dashboard-main-container extentions-main-container ${
          toggleState ? "expandedBlock" : "unExpandedBlock"
        }`}
      >
        <SideBar sideBardExtendHeight={"extentions-main-container"} />
        <div
          className={
            toggleState ? "content-section-extend" : "content-section-minimize"
          }
        >
          <Header title={"Extention"} />
          {!editExtensions && (
            <ExtentionsList {...{ handleExtension, setExtentionType }} />
          )}
          {editExtensions && (
            <ManageExtension
              {...{
                handleExtension,
                extentionDetails,
                extentionType,
                extentionsList,
              }}
            />
          )}
        </div>
      </div>
    </Fragment>
  );
}

export default ExtentionsMain;
