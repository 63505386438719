import React, { useEffect } from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

function StepTwo(props) {
  const {
    selectedMetrics,
    setActiveStep,
    handleAddWeightage,
    totalWeightage,
    handleMinusWeightage,
    handleOnSubmitStepTwo,
    isLoadingSubmit,
  } = props;

  useEffect(() => {}, [selectedMetrics]);
  const antIcon = (
    <LoadingOutlined
      style={{ fontSize: 14, color: "white", marginRight: 8 }}
      spin
    />
  );
  return (
    <div>
      {/* header */}
      <div className="flex justify-between items-center">
        <h5 className="text-sm mb-0 text-[#1F1F1F]">
          Assign Metrics Weightage %
        </h5>
        <div className="flex justify-start items-center">
          <h5 className="text-sm mb-0 text-[#1F1F1F] mr-5">
            Weightage applied: {totalWeightage} %
          </h5>
          <h5 className="text-sm mb-0 text-[#1F1F1F]">
            Weightage remaining: {100 - totalWeightage} %
          </h5>
        </div>
      </div>
      <div className="flex flex-wrap mt-7 w-full">
        {selectedMetrics?.length > 0 &&
          selectedMetrics.map((data, key) => (
            <div key={key} className="w-[300px]">
              <div className="flex justify-between mb-5 items-center break-words text-white bg-[#002244] w-[250px] text-[13px] rounded-sm py-1 px-2">
                <button
                  onClick={() => handleMinusWeightage(key)}
                  disabled={totalWeightage <= 0}
                  className={
                    "disabled:opacity-50 disabled:cursor-none cursor-pointer"
                  }
                >
                  <RemoveCircleOutlineIcon className="!w-5 " />
                </button>{" "}
                <span className="w-7/12 text-center  font-normal">
                  {data?.label ?? data?.name}
                  <span className="underline underline-offset-4 pl-1">
                    {data.weightage}%
                  </span>
                </span>
                <button
                  onClick={() => handleAddWeightage(key)}
                  disabled={totalWeightage >= 100}
                  className={
                    "disabled:opacity-50 disabled:cursor-none disabled:pointer-events-none cursor-pointer"
                  }
                >
                  <AddCircleOutlineIcon className="!w-5" />
                </button>
              </div>
            </div>
          ))}
      </div>
      <div className="flex justify-center mt-10">
        <button
          className="mr-3 bg-[#999999] text-[13px] text-white rounded-[3px] !px-3.5 !py-1"
          onClick={() => setActiveStep(1)}
        >
          Back
        </button>
        <button
          className="ml-3 !bg-primary text-[13px] text-white rounded-[3px] !px-3.5 !py-1 disabled:opacity-50"
          // disabled={totalWeightage !== 100}
          onClick={() => {
            handleOnSubmitStepTwo();
          }}
        >
          {isLoadingSubmit && <Spin indicator={antIcon} />}
          Next
        </button>
      </div>
    </div>
  );
}

export default StepTwo;
