import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SideBar from "../sidebar/SideBar";
import Header from "../Header/Header";
import FeatureRequestTable from "./FeatureRequestTable";
import FeatureRequestExpandedTableContent from "./FeatureRequestExpandedTableContent";
import FeatureRequestDetails from "./FeatureRequestDetails";
import actions from "../../store/actions";
import { useNavigate } from "react-router-dom";
import AppendListModal from "./AppendListModal";

function FeatureRequestMain() {
  const toggleState = useSelector((state) => state.dashboard.toggleSidebar);

  // TODO: need to print userFeature Variable in Feature Request table.
  const { userFeatures } = useSelector((state) => state.features);

  const [isExpanded, setIsExpanded] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [featureLoading, setFeatureLoading] = useState(false);
  const [selectedFeatures, setSelectedFeatures] = useState(null);
  const [isShowAppendFr, setIsShowAppenFr] = useState(false);
  const [isChangedData, setIsChangedData] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (!isShowAppendFr) {
      dispatch(
        actions.getFeatureByUser({
          ...{
            pageNumber: currentPage - 1,
            pageSize: 10,
            navigate,
            setFeatureLoading,
            setTotalCount,
          },
        })
      );
    }
  }, [dispatch, isShowAppendFr, currentPage]);

  const handleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const handleShowAppendFr = () => {
    setIsShowAppenFr(!isShowAppendFr);
  };

  const handleSelectedData = (d) => {
    setIsChangedData(true);
    setSelectedFeatures(d);
    setIsChangedData(false);
  };

  const handleOnTableChange = (e) => {
    setCurrentPage(e);
  };

  console.log(selectedFeatures, "selectedFeatures");
  console.log(userFeatures[0], "userFeatures");

  return (
    <Fragment>
      <div
        className={`d-flex dashboard-main-container feature-request-main-container ${
          toggleState ? "expandedBlock" : "unExpandedBlock"
        }`}
      >
        <SideBar sideBardExtendHeight={"feature-request-main-container"} />
        <div
          className={
            toggleState ? "content-section-extend" : "content-section-minimize"
          }
        >
          <Header title={"Feature Request"} />
          <div className="p-3 !bg-white">
            {/* <SubmitFeatureRequest /> */}
            {!isExpanded && (
              <FeatureRequestTable
                {...{
                  handleExpanded,
                  isExpanded,
                  userFeatures,
                  featureLoading,
                  handleSelectedData,
                  handleShowAppendFr,
                  totalCount,
                  handleOnTableChange,
                  currentPage,
                }}
              />
            )}
            {isExpanded && (
              <div className="flex items-stretch min-h-screen">
                <div
                  className={
                    isExpanded
                      ? "w-4/12 ease-in transition duration-150 pr-4"
                      : ""
                  }
                >
                  <FeatureRequestExpandedTableContent
                    {...{
                      userFeatures,
                      featureLoading,
                      handleSelectedData,
                      selectedFeatures,
                      handleShowAppendFr,
                      totalCount,
                      handleOnTableChange,
                      currentPage,
                    }}
                  />
                </div>
                <div className={isExpanded ? "w-8/12" : "hidden"}>
                  <FeatureRequestDetails
                    {...{
                      handleExpanded,
                      selectedFeatures,
                      isChangedData,
                      handleOnTableChange,
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        {isShowAppendFr && (
          <AppendListModal
            {...{
              isShowAppendFr,
              handleShowAppendFr,
              selectedFeatures,
              handleExpanded,
            }}
          />
        )}
      </div>
    </Fragment>
  );
}

export default FeatureRequestMain;
