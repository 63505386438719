import axios from "axios";

import actions from "..";
import config from "../../../config";
import types from "../../types";

/*==============================================================
                GET ALL Opportunity
===============================================================*/

export const getAllOpportunities = (props) => {
  const { currentPage, pageSize = 10, navigate, setIsLoading } = props;
  let response = {};
  return async (dispatch) => {
    try {
      setIsLoading(true);
      response = await axios.get(
        `${config.WEB_URL}/api/v1.0/opportunity/tenant/page/devt4?page=${currentPage}&size=${pageSize}`
      );
      if (response.data.status === "FAILURE") {
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: true,
            alertType: "error",
            alertMessage: response.data.errorMessage,
          },
        });
        dispatch({
          type: types.SET_ALL_OPPORTUNITIES,
          payload: [],
        });
        setIsLoading(false);
      } else if (response.data.status === "SUCCESS") {
        dispatch({
          type: types.SET_ALL_OPPORTUNITIES,
          payload: response.data.featureRequestRanks,
        });
        setIsLoading(false);
      }
    } catch (error) {
      dispatch({
        type: types.SET_ALERT,
        payload: {
          alert: true,
          alertType: "error",
          alertMessage: "Error in get opportunity",
        },
      });
      setIsLoading(false);
      dispatch(actions.logoutUser(navigate));
    }
    return response;
  };
};

/*==============================================================
                GET ALL Opportunity
===============================================================*/

export const getOpportunitiesScore = (id, navigate) => {
  let response = {};
  return async (dispatch) => {
    try {
      response = await axios.get(`${config.WEB_URL}/api/v1.0/score/${id}`);

      if (response.data.status === "FAILURE") {
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: true,
            alertType: "error",
            alertMessage: response.data.errorMessage,
          },
        });
        dispatch({
          type: types.SET_OPPORTUNITIES_SCORE,
          payload: [],
        });
      } else if (response.status === 200) {
        dispatch({
          type: types.SET_OPPORTUNITIES_SCORE,
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch({
        type: types.SET_ALERT,
        payload: {
          alert: true,
          alertType: "error",
          alertMessage: "Error in opportunity score",
        },
      });
      dispatch(actions.logoutUser(navigate));
    }
    return response;
  };
};

export const getOpportunitiesNotes = (id, navigate) => {
  let response = {};
  return async (dispatch) => {
    try {
      response = await axios.get(
        `${config.WEB_URL}/api/v1.0/sfdc/notes/opportunity/${id}`
      );

      if (response.data.status === "FAILURE") {
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: true,
            alertType: "error",
            alertMessage: response.data.errorMessage,
          },
        });
        dispatch({
          type: types.SET_OPPORTUNITIES_NOTES,
          payload: [],
        });
      } else if (response.status === 200) {
        dispatch({
          type: types.SET_OPPORTUNITIES_NOTES,
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch({
        type: types.SET_ALERT,
        payload: {
          alert: true,
          alertType: "error",
          alertMessage: "Error in opportunity notes",
        },
      });
      dispatch(actions.logoutUser(navigate));
    }
    return response;
  };
};
