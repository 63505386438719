import React, { Fragment, useEffect, useState } from "react";
import InventoryIcon from "@mui/icons-material/Inventory";
import { Select, Input } from "formik-antd";
import { featureRequestTableData } from "../common/constant";
import { Form, Formik } from "formik";
import { Spin, Table } from "antd";
import AppendFRModal from "./AppendFRModal";
// import { useSelector } from "react-redux";
import Header from "../Header/Header";
import SideBar from "../sidebar/SideBar";
import actions from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
const { TextArea } = Input;

const SubmitFeatureRequest = () => {
  const navigate = useNavigate();
  const toggleState = useSelector((state) => state.dashboard.toggleSidebar);
  let { jiraProjects, jiraIssueType } = useSelector((state) => state.jira);
  const [selectedFR, setSelectedFR] = useState(null);
  const [appendPayload, setAppendPayload] = useState(null);
  let { searchFeatures } = useSelector((state) => state.features);

  const selectedOpportunity = JSON.parse(
    localStorage.getItem("selectedOpportunity")
  );

  const selectedNote = JSON.parse(localStorage.getItem("selectedNote"));
  const { user } = useSelector((state) => state.auth);
  const [isAppendFR, setIsAppendFR] = useState(false);
  const [isLoading, setIsLoading] = useState({
    appendFR: false,
  });
  const [submitFRLoading, setSubmitFRLoading] = useState(false);
  const dispatch = useDispatch();
  const handleSubmit = async (values) => {
    setSubmitFRLoading(true);
    let data = {
      featureRequestRank: selectedOpportunity,
      title: selectedNote.title,
      body: selectedNote.body,
      salesforceNoteId: selectedNote.id,
      score: selectedOpportunity.otscore,
      oppUserId: user?.id,
      issueTypeId: values.issueType,
      projectId: values.project,
      featurePriority: values.featurePriority,
      source: "SFDC",
    };
    const res = await dispatch(actions.submitFeatureRequest(data));
    if (res) {
      navigate("/feature-request");
      setSubmitFRLoading(false);
    }
    // create feature using above data.
  };

  const handleSetData = (data) => {
    let updateData = {
      featureRequestRank: selectedOpportunity,
      title: selectedNote.title,
      body: selectedNote.body,
      salesforceNoteId: selectedNote.id,
      score: selectedOpportunity.otscore,
      oppUserId: user?.id,
      source: "SFDC",
      issueId: data.issueId,
      description: `Appended Feature Details:\n Title-  ${selectedNote.title}\n Desc- ${selectedNote.body}\n`,
    };
    setSelectedFR(data);
    setAppendPayload(updateData);
  };
  const handleModal = () => {
    setIsAppendFR(!isAppendFR);
  };

  jiraProjects = jiraProjects.map((item) => ({
    label: item.name,
    value: item.key,
  }));

  jiraIssueType = jiraIssueType.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  const featurePriority = [
    { label: "Must Have", value: "Must Have" },
    { label: "Should Have", value: "Should Have" },
    { label: "Could Have", value: "Could Have" },
    { label: "Won't Have", value: "Won't Have" },
  ];

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 20, marginRight: 12 }} spin />
  );

  const columns = [
    {
      title: "FR ID",
      dataIndex: "issueId",
      key: "issueId",
      render: (issueId) => <div className="py-3 px-2">{issueId}</div>,
    },
    {
      title: "Project",
      dataIndex: "projectId",
      key: "projectId",
      render: (projectId) => <div className="py-3 px-2">{projectId}</div>,
    },
    {
      title: "Title",
      dataIndex: "issueName",
      key: "issueName",
      render: (issueName) => <div className="py-3 px-2">{issueName}</div>,
    },
    {
      title: "OT Score",
      key: "score",
      dataIndex: "score",
      render: (score) => <div className="py-3 px-2">{score ?? "0"}</div>,
    },
    {
      title: "Type",
      key: "issueTypeId",
      dataIndex: "issueTypeId",
      render: (issueTypeId) => <div className="py-3 px-2">{issueTypeId}</div>,
    },
    {
      title: "",
      key: "action",
      dataIndex: "action",
      render: (priority, data) => (
        <div
          className="flex justify-center"
          onClick={() => {
            handleModal();
            handleSetData(data);
          }}
        >
          <div className="!bg-primary py-1 w-14 text-center rounded-[2px] text-xs capitalize text-white cursor-pointer">
            Append
          </div>
        </div>
      ),
    },
  ];

  const handleGetSerachFeature = async () => {
    try {
      setIsLoading({ ...isLoading, appendFR: true });
      const res = await dispatch(
        actions.searchFeature({
          ...{ title: "Demo Fr of poc1 to append", navigate },
        })
      );
      if (res) {
        setIsLoading({ ...isLoading, appendFR: false });
      }
    } catch (err) {
      console.log(err);
      setIsLoading({ ...isLoading, appendFR: false });
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(actions.getAllProject({ ...{ navigate } }));
    dispatch(actions.getAllIssueType({ ...{ navigate } }));
    handleGetSerachFeature();
  }, [dispatch]);

  return (
    <Fragment>
      <div
        className={`d-flex dashboard-main-container feature-request-main-container ${
          toggleState ? "expandedBlock" : "unExpandedBlock"
        }`}
      >
        <SideBar sideBardExtendHeight={"feature-request-main-container"} />
        <div
          className={
            toggleState ? "content-section-extend" : "content-section-minimize"
          }
        >
          <Header title={"Feature Request"} />
          <div className="p-3 !bg-white">
            <div>
              <h2 className="mb-0 text-base !text-[#1F1F1F] flex items-center border-b-2 border-[#BCCED5] pb-3">
                <InventoryIcon className="!text-base !text-[#1F1F1F] mr-2" />{" "}
                Submit New Feature Request or Append to an existing one
              </h2>
              <Formik
                onSubmit={handleSubmit}
                initialValues={{
                  project: "",
                  issueType: "",
                  featurePriority: "",
                  description: "",
                }}
              >
                {({ values }) => (
                  <Form>
                    <div className="flex items-end pt-2 pb-4 border-b-2 border-[#BCCED5]">
                      <div className="w-5/12">
                        <label className="text-sm text-[#9A9F95] mb-2">
                          Opportunity Name
                        </label>
                        <h3 className="text-sm mb-3">
                          {selectedOpportunity?.name}
                        </h3>
                        <label className="text-sm text-[#9A9F95] mb-2">
                          Title
                        </label>
                        <h3 className="text-sm mb-3">{selectedNote?.title}</h3>
                        <div className="flex flex-col">
                          <label className="text-sm text-[#9A9F95] mb-2">
                            Project
                          </label>
                          <Select
                            options={jiraProjects}
                            name="project"
                            id="project"
                            placeholder="Select"
                            className="ot-select border-[#797979] !w-72"
                          />
                        </div>
                        <div className="flex flex-col pt-3">
                          <label className="text-sm text-[#9A9F95] mb-2">
                            Issue Type
                          </label>
                          <Select
                            options={jiraIssueType}
                            name="issueType"
                            id="issueType"
                            placeholder="Select Issue Type"
                            className="ot-select border-[#797979] !w-72"
                          />
                        </div>
                        <div className="flex flex-col pt-3">
                          <label className="text-sm text-[#9A9F95] mb-2">
                            Feature Priority
                          </label>
                          <Select
                            options={featurePriority}
                            name="featurePriority"
                            id="featurePriority"
                            placeholder="Select Feature Priority"
                            className="ot-select border-[#797979] !w-72"
                          />
                        </div>
                      </div>
                      <div className="w-7/12 ml-7 mr-1">
                        {/* <label className="text-sm text-[#9A9F95] mb-2">
                          Add Description
                        </label>
                        <TextArea
                          name="description"
                          id="description"
                          rows={13}
                          className="ot-textarea"
                          readOnly={true}
                          placeholder={selectedNote?.body}
                        /> */}
                        <div className="text-right pt-4">
                          <button
                            type="submit"
                            className="bg-[#002244] submit-btn text-white !text-sm px-4 py-1 rounded"
                          >
                            {submitFRLoading && <Spin indicator={antIcon} />}
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
              <div className=" relative mt-3">
                <h3 className="text-base pb-0 pt-4 absolute -top-2">
                  Append to similar Feature Requests ({searchFeatures?.length})
                </h3>
                <Table
                  columns={columns}
                  className={"featureRequestTable"}
                  dataSource={searchFeatures}
                  pagination={{
                    position: ["topRight"],
                    pageSize: 10,
                    simple: true,
                    total: searchFeatures?.length,
                  }}
                  scroll={{ y: 640 }}
                  loading={isLoading?.appendFR}
                />
              </div>
              {isAppendFR && (
                <AppendFRModal
                  {...{ handleModal, isAppendFR, selectedFR, appendPayload }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SubmitFeatureRequest;
