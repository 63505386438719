import types from "../../types";

const initialState = {
  toggleSidebar: false,
  users: [],
  featureSummary: [],
  reportedSummary: [],
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case types.TOGGLE_SIDEBAR:
      return {
        ...state,
        toggleSidebar: action.payload,
      };
    case types.SET_ALL_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case types.FEATURE_SUMMARY:
      return {
        ...state,
        featureSummary: action.payload,
      };
    case types.REPORTED_SUMMARY:
      return {
        ...state,
        reportedSummary: action.payload,
      };
    default:
      return { ...state };
  }
}
