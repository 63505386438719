import React, { Fragment, useState } from "react";
import actions from "../../store/actions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { LoginSchema } from "../../store/validations/auth/loginSchema";
import ErrorComponent from "../common/ErrorComponent";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

function LoginForm({ setForgetPasswordForm }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const googleLoginHanlder = () => {
    window.open(
      "https://dev.theitstack.com/login/v1.0/google",
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <Fragment>
      <h1>Sign in to OT</h1>
      <div className="login-form">
        <Formik
          initialValues={{ email: "", password: "", tanant: "" }}
          validationSchema={LoginSchema}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            // // When button submits form and form is in the process of submitting, submit button is disabled
            // setSubmitting(true);

            // // Resets form after submission is complete
            // resetForm();

            // // Sets setSubmitting to false after form is reset
            // setSubmitting(false);
            try {
              setIsLoading(true);
              const formData = {
                email: values.email,
                password: values.password,
                tenant: values.tanant,
              };
              const res = await dispatch(
                actions.loginAction(formData, navigate)
              );
              if (res) {
                setIsLoading(false);
              }
            } catch (error) {
              console.log(error);
              setIsLoading(false);
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form autoComplete="off">
              {/* {console.log(errors)} */}
              <input
                name="email"
                className={errors.email ? "text-box invalid-input" : "text-box"}
                type="text"
                placeholder="Email ID"
                autoFocus={true}
                value={values.email}
                onChange={handleChange}
                /* Set onBlur to handleBlur */
                onBlur={handleBlur}
              />
              <ErrorComponent {...{ errors: errors.email, touched }} />
              <input
                className={` mt-8
                  ${errors.password ? "text-box invalid-input" : "text-box"}
               `}
                name="password"
                type="password"
                placeholder="Password"
                autoComplete="false"
                value={values.password}
                onChange={handleChange}
                /* Set onBlur to handleBlur */
                onBlur={handleBlur}
              />
              <ErrorComponent {...{ errors: errors.password, touched }} />

              <input
                className={`mt-8
                ${errors.tanant ? "text-box invalid-input" : "text-box"}
             `}
                type="text"
                name="tanant"
                placeholder="Tenant"
                value={values.tanant}
                onChange={handleChange}
                /* Set onBlur to handleBlur */
                onBlur={handleBlur}
              />
              <ErrorComponent {...{ errors: errors.tanant, touched }} />
              <div className="d-flex align-items-center mt-8">
                <input className="check-box pointer" type="checkbox"></input>
                <span>Remember me</span>
              </div>
              <div className="d-flex flex-column">
                <button onClick={handleSubmit}>
                  {isLoading && <Spin indicator={antIcon} />} Login
                </button>
                <span
                  onClick={() => setForgetPasswordForm(true)}
                  className="forget-text mt20 pointer"
                >
                  Forgot Password?
                </span>
              </div>
              <hr className="line mt42" />
              <p className="mx-auto">Or sign in with</p>
              <div className="social-login-section d-flex mt34">
                <div className="d-flex justify-content-center ">
                  <img
                    onClick={googleLoginHanlder}
                    src={require("./../../assets/images/auth/google.png")}
                    alt=""
                    className="pointer"
                  ></img>
                  <p onClick={googleLoginHanlder} className="ml19 pointer">
                    Google
                  </p>
                </div>
                <div className="d-flex justify-content-center ">
                  <img
                    src={require("./../../assets/images/auth/microsoft.png")}
                    alt=""
                    className="pointer"
                  ></img>
                  <p className="ml19 pointer">Work Account</p>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Fragment>
  );
}

export default LoginForm;
