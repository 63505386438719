import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { PieChart } from "react-minimal-pie-chart";

const TopFiveRequestTableComponent = () => {
  const rows = [
    {
      clientName: "TCS",
      date: "12 Now 2022",
      frId: "POC 12",
      details: "Change in Form",
      otSource: 50.12,
      progress: (
        <PieChart
          data={[{ title: "TCS", value: 80, color: "#2689ED" }]}
          totalValue={100}
          animate={true}
          animationDuration="600"
          background="#EEEEEE"
          style={{ height: "23px", margin: "auto" }}
        />
      ),
    },
    {
      clientName: "Wipro",
      date: "12 Now 2022",
      frId: "POC 12",
      details: "Change in Form",
      otSource: 50.12,
      progress: (
        <PieChart
          data={[{ title: "Wipro", value: 80, color: "#69BE28" }]}
          totalValue={100}
          animate={true}
          animationDuration="600"
          background="#EEEEEE"
          style={{ height: "23px", margin: "auto" }}
        />
      ),
    },
    {
      clientName: "Amazon",
      date: "12 Now 2022",
      frId: "POC 12",
      details: "Change in Form",
      otSource: 50.12,
      progress: (
        <PieChart
          data={[{ title: "Amazon", value: 80, color: "#F8882C" }]}
          totalValue={100}
          animate={true}
          animationDuration="600"
          background="#EEEEEE"
          style={{ height: "23px", margin: "auto" }}
        />
      ),
    },
    {
      clientName: "OLA",
      date: "12 Now 2022",
      frId: "POC 12",
      details: "Change in Form",
      otSource: 50.12,
      progress: (
        <PieChart
          data={[{ title: "OLA", value: 80, color: "#69BE28" }]}
          totalValue={100}
          animate={true}
          animationDuration="600"
          background="#EEEEEE"
          style={{ height: "23px", margin: "auto" }}
        />
      ),
    },
    {
      clientName: "TCS",
      date: "12 Now 2022",
      frId: "POC 12",
      details: "Change in Form",
      otSource: 50.12,
      progress: (
        <PieChart
          data={[{ title: "OLA", value: 80, color: "#F8882C" }]}
          totalValue={100}
          animate={true}
          animationDuration="600"
          background="#EEEEEE"
          style={{ height: "23px", margin: "auto" }}
        />
      ),
    },
  ];

  return (
    <TableContainer
      component={Paper}
      className="mt-2 border-t-4 !border-primary !rounded-none"
    >
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Client Name</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>FR ID</TableCell>
            <TableCell>Details</TableCell>
            <TableCell>OT Score</TableCell>
            <TableCell className="text-center">Progress</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.clientName}
              </TableCell>
              <TableCell>{row.date}</TableCell>
              <TableCell>{row.frId}</TableCell>
              <TableCell>{row.details}</TableCell>
              <TableCell>{row.otSource}</TableCell>
              <TableCell>
                {row.progress}
                {/* <Pie options={options} data={data} /> */}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TopFiveRequestTableComponent;
