// import axios from "axios";
// import config from "../../../config";
import types from "../../types";

export const toggleAction = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.TOGGLE_SIDEBAR,
        payload: payload,
      });
    } catch (error) {
      console.error(" TOGGLE ERROR --> ", error);
    }
  };
};
