import axios from "axios";
import actions from "..";
import config from "../../../config";
import types from "../../types";

/*==============================================================
                GET ALL METRICS
===============================================================*/

export const getAllProductMetrics = (props) => {
  const { navigate, setLoadingMetric } = props;
  let response = {};
  return async (dispatch) => {
    try {
      setLoadingMetric(true);
      response = await axios.get(
        `${config.WEB_URL}/api/v1.0/sfdc/fields/describe/jira/63e8fdf6892e3c000114c820`
      );
      if (response.data.status === "FAILURE") {
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: true,
            alertType: "error",
            alertMessage: response.data.errorMessage,
          },
        });
        dispatch({
          type: types.SET_ALL_PRODUCT_METRICS,
          payload: [],
        });
      } else if (response.data.status === "SUCCESS") {
        dispatch({
          type: types.SET_ALL_PRODUCT_METRICS,
          payload: response.data.fieldsObjects,
        });
      }
      setLoadingMetric(false);
    } catch (error) {
      setLoadingMetric(false);
      dispatch({
        type: types.SET_ALERT,
        payload: {
          alert: true,
          alertType: "error",
          alertMessage: "Error in metrics action",
        },
      });
      dispatch(actions.logoutUser(navigate));
    }
    return response;
  };
};

export const getProductSelectedMetrics = () => {
  let response = {};
  return async (dispatch) => {
    try {
      response = await axios.get(
        `${config.WEB_URL}/api/v1.0/metric/tenant/devt4/type/SALES`
      );

      if (response.data.status === "FAILURE") {
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: true,
            alertType: "error",
            alertMessage: response.data.errorMessage,
          },
        });
        dispatch({
          type: types.SET_PRODUCT_SELECTED_METRICS,
          payload: [],
        });
      } else if (response.data.status === "SUCCESS") {
        dispatch({
          type: types.SET_PRODUCT_SELECTED_METRICS,
          payload: response.data.metricsDTOS,
        });
      }
    } catch (error) {
      dispatch({
        type: types.SET_ALERT,
        payload: {
          alert: true,
          alertType: "error",
          alertMessage: "Error in metrics action",
        },
      });
    }
    return response;
  };
};

// /*====================================================
//                     Create metrics
// ====================================================*/
// export const createMetrics = (data) => {
//   let response = {};
//   return async (dispatch) => {
//     try {
//       response = await axios.post(`${config.WEB_URL}/api/v1.0/metric/`, data);

//       if (response.data.status === "SUCCESS")
//         dispatch({
//           type: types.SET_ALERT,
//           payload: {
//             alert: true,
//             alertType: "success",
//             alertMessage: "User Created Successfully.",
//           },
//         });
//       else
//         dispatch({
//           type: types.SET_ALERT,
//           payload: {
//             alert: true,
//             alertType: "error",
//             alertMessage: response.data.errorMessage,
//           },
//         });
//     } catch (error) {
//       dispatch({
//         type: types.SET_ALERT,
//         payload: {
//           alert: true,
//           alertType: "error",
//           alertMessage: "Error in login action",
//         },
//       });
//     }
//     return response;
//   };
// };

/*==============================================================
                GET Bucket Metrics
===============================================================*/

export const getProductBucketByMetricsId = (navigate) => {
  let response = {};
  return async (dispatch) => {
    try {
      response = await axios.get(
        `${config.WEB_URL}/api/v1.0/metric/tenant/devt4/type/PRODUCT`
      );

      if (response.data.status === "FAILURE") {
        dispatch(actions.logoutUser(navigate));
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: true,
            alertType: "error",
            alertMessage: response.data.errorMessage,
          },
        });
        dispatch({
          type: types.SET_BUCKETS_LIST_BY_PRODUCT_METRIC,
          payload: [],
        });
      } else if (response.data.status === "SUCCESS") {
        dispatch({
          type: types.SET_BUCKETS_LIST_BY_PRODUCT_METRIC,
          payload: response.data.metricsDTOS,
        });
      }
    } catch (error) {
      dispatch(actions.logoutUser(navigate));
      dispatch({
        type: types.SET_ALERT,
        payload: {
          alert: true,
          alertType: "error",
          alertMessage: "Error in metrics action",
        },
      });
    }
    return response;
  };
};

/*====================================================
                    Create bucket
====================================================*/
export const createProductBucket = (data, navigate) => {
  let response = {};
  return async (dispatch) => {
    try {
      response = await axios.post(
        `${config.WEB_URL}/api/v1.0/metric/bucket/product/list/`,
        data
      );

      if (response.data.status === "SUCCESS")
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: true,
            alertType: "success",
            alertMessage: "Product Bucket Created Successfully.",
          },
        });
      else
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: true,
            alertType: "error",
            alertMessage: response.data.errorMessage,
          },
        });
    } catch (error) {
      dispatch(actions.logoutUser(navigate));
      dispatch({
        type: types.SET_ALERT,
        payload: {
          alert: true,
          alertType: "error",
          alertMessage: "Error in create product bucket action",
        },
      });
    }
    return response;
  };
};

/*====================================================
                    Create bucket
====================================================*/
export const deleteMetricsByMetricsId = (metricsType, metricsObjectIds) => {
  let response = {};
  return async (dispatch) => {
    try {
      response = await axios.delete(
        `${config.WEB_URL}/api/v1.0/metric/${metricsType}/${metricsObjectIds}`
      );

      if (response.data.status === "SUCCESS")
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: true,
            alertType: "success",
            alertMessage: "Metrics Deleted Successfully.",
          },
        });
      else
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: true,
            alertType: "error",
            alertMessage: response.data.errorMessage,
          },
        });
    } catch (error) {
      dispatch({
        type: types.SET_ALERT,
        payload: {
          alert: true,
          alertType: "error",
          alertMessage: "Error in metrics deleted action",
        },
      });
    }
    return response;
  };
};
