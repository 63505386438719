import { Modal } from "antd";
import { Formik } from "formik";
import React from "react";
import { Form, Input } from "formik-antd";

const AddEditAcccountModal = (props) => {
  const { showAddModalForm, handleAddModalForm } = props;
  const handleSubmit = async (values) => {
    console.log("object", values);
  };

  return (
    <>
      <Modal
        width={"70%"}
        open={showAddModalForm}
        onCancel={handleAddModalForm}
        // okText={"Submit"}
        // onOk={handleSubmit}
        footer={false}
      >
        <h2 className=" text-base mb-0 pb-2 border-b-[1px] border-[#BCCED5]">
          Add Organization
        </h2>
        <div className="py-7 mx-auto pl-40 relative after:absolute after:w-[100%] after:h-[0.5px] after:bg-gray-400 after:bottom-20 after:left-0">
          <Formik
            initialValues={{
              email: "",
              officeAddress: "",
              city: "",
              url: "",
              firstName: "",
              lastName: "",
              password: "",
              confirmedPassword: "",
              phoneNumber: "",
              description: "",
            }}
            onSubmit={(e) => handleSubmit(e)}
          >
            {() => (
              <Form className="w-full" id={"addEditAccount"}>
                <div className="flex w-full">
                  <div className="w-4/12 flex flex-col mr-5">
                    <label
                      htmlFor={`email`}
                      className="text-[13px] text-[#797979] pb-2 pt-1"
                    >
                      Email Address
                    </label>
                    <Input
                      name={`email`}
                      id={`email`}
                      placeholder="Email address"
                      className="!border-[1px]  !border-gray-400 disabled:!border-0 disabled:!bg-transparent disabled:!text-primary"
                    />
                  </div>
                  <div className="w-4/12 flex flex-col ml-5">
                    <label
                      htmlFor={`organizationName`}
                      className="text-[13px] text-[#797979] pb-2 pt-1"
                    >
                      Organization Name
                    </label>
                    <Input
                      name={`organizationName`}
                      id={`organizationName`}
                      placeholder="Organization Name"
                      className="!border-[1px]  !border-gray-400 disabled:!border-0 disabled:!bg-transparent disabled:!text-primary"
                    />
                  </div>
                </div>
                {/* office addresss */}
                <div className="mt-4 w-full">
                  <div className="w-9/12 flex flex-col pr-5">
                    <label
                      htmlFor={`officeAddress`}
                      className="text-[13px] text-[#797979] pb-2 pt-1"
                    >
                      Office Address
                    </label>
                    <Input
                      name={`officeAddress`}
                      id={`officeAddress`}
                      placeholder="Office Address"
                      className="!border-[1px]  !border-gray-400 disabled:!border-0 disabled:!bg-transparent disabled:!text-primary"
                    />
                  </div>
                </div>
                {/* city and url */}
                <div className="flex w-full mt-4">
                  <div className="w-4/12 flex flex-col mr-5">
                    <label
                      htmlFor={`city`}
                      className="text-[13px] text-[#797979] pb-2 pt-1"
                    >
                      City
                    </label>
                    <Input
                      name={`city`}
                      id={`city`}
                      placeholder="Email address"
                      className="!border-[1px]  !border-gray-400 disabled:!border-0 disabled:!bg-transparent disabled:!text-primary"
                    />
                  </div>
                  <div className="w-4/12 flex flex-col ml-5">
                    <label
                      htmlFor={`url`}
                      className="text-[13px] text-[#797979] pb-2 pt-1"
                    >
                      URL
                    </label>
                    <Input
                      name={`url`}
                      id={`url`}
                      placeholder="URL"
                      className="!border-[1px]  !border-gray-400 disabled:!border-0 disabled:!bg-transparent disabled:!text-primary"
                    />
                  </div>
                </div>
                {/* first name and last name */}
                <div className="flex w-full mt-4">
                  <div className="w-4/12 flex flex-col mr-5">
                    <label
                      htmlFor={`firstName`}
                      className="text-[13px] text-[#797979] pb-2 pt-1"
                    >
                      First Name
                    </label>
                    <Input
                      name={`firstName`}
                      id={`firstName`}
                      placeholder="First Name"
                      className="!border-[1px]  !border-gray-400 disabled:!border-0 disabled:!bg-transparent disabled:!text-primary"
                    />
                  </div>
                  <div className="w-4/12 flex flex-col ml-5">
                    <label
                      htmlFor={`url`}
                      className="text-[13px] text-[#797979] pb-2 pt-1"
                    >
                      Last Name
                    </label>
                    <Input
                      name={`lastName`}
                      id={`lastName`}
                      placeholder="Last Name"
                      className="!border-[1px]  !border-gray-400 disabled:!border-0 disabled:!bg-transparent disabled:!text-primary"
                    />
                  </div>
                </div>
                {/* Password and confirmed password */}
                <div className="flex mt-4 w-full">
                  <div className="w-4/12 flex flex-col mr-5">
                    <label
                      htmlFor={`password`}
                      className="text-[13px] text-[#797979] pb-2 pt-1"
                    >
                      Password
                    </label>
                    <Input
                      name={`password`}
                      id={`password`}
                      type={"password"}
                      placeholder="password"
                      className="!border-[1px]  !border-gray-400 disabled:!border-0 disabled:!bg-transparent disabled:!text-primary"
                    />
                  </div>
                  <div className="w-4/12 flex flex-col ml-5">
                    <label
                      htmlFor={`lastName`}
                      className="text-[13px] text-[#797979] pb-2 pt-1"
                    >
                      Confirmed Password
                    </label>
                    <Input
                      name={`confirmedPassword`}
                      id={`confirmedPassword`}
                      type={"password"}
                      placeholder="Confirmed Password"
                      className="!border-[1px]  !border-gray-400 disabled:!border-0 disabled:!bg-transparent disabled:!text-primary"
                    />
                  </div>
                </div>
                {/* Phone number */}
                <div className="flex mt-4 w-full">
                  <div className="w-4/12 flex flex-col mr-5">
                    <label
                      htmlFor={`phoneNumber`}
                      className="text-[13px] text-[#797979] pb-2 pt-1"
                    >
                      Phone Number
                    </label>
                    <Input
                      name={`phoneNumber`}
                      id={`phoneNumber`}
                      type={"number"}
                      placeholder="Phone Number"
                      className="!border-[1px]  !border-gray-400 disabled:!border-0 disabled:!bg-transparent disabled:!text-primary"
                    />
                  </div>
                </div>
                {/* Description */}
                <div className="mt-4 w-full">
                  <div className="w-9/12 flex flex-col pr-5">
                    <label
                      htmlFor={`description`}
                      className="text-[13px] text-[#797979] pb-2 pt-1"
                    >
                      Description
                    </label>
                    <Input
                      name={`description`}
                      id={`description`}
                      placeholder="Description"
                      className="!border-[1px]  !border-gray-400 disabled:!border-0 disabled:!bg-transparent disabled:!text-primary"
                    />
                  </div>
                </div>
                <div className="flex justify-end items-center mt-8 pt-4">
                  <button
                    onClick={handleAddModalForm}
                    className="border-gray-400 border-[1px] mr-3 rounded-sm px-2 py-1"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    htmlFor="addEditAccount"
                    className="!bg-primary rounded-sm px-2 py-1 text-white"
                  >
                    Submit
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    </>
  );
};

export default AddEditAcccountModal;
