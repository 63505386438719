import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SideBar from "../sidebar/SideBar";
import Header from "../Header/Header";
import MoneyStatusBox from "./MoneyStatusBox";
import AllRequestStatusVerticalGraph from "./common/AllRequestStatusVerticalGraph";
import TopFiveOpportunitiesTable from "./common/TopFiveOpportunitiesTable";
import TopFiveRequest from "./common/TopFiveRequest";
import WhatsTrending from "./common/WhatsTrending";
import Footer from "../Footer/Footer";
import actions from "../../store/actions";

function DashboardProductPerson() {
  const dispatch = useDispatch();

  const toggleState = useSelector((state) => state.dashboard.toggleSidebar);
  const { tenant } = useSelector((state) => state.auth.user);

  const { featureSummary, reportedSummary } = useSelector(
    (state) => state.dashboard
  );

  useEffect(() => {
    dispatch(actions.getAllUsers({ tenant }));
    dispatch(actions.getFeatureSummary());
    dispatch(actions.getReportedSummary());
    dispatch(actions.getExtensions());
  }, [dispatch, tenant]);

  return (
    <Fragment>
      <div
        className={`d-flex dashboard-main-container ${
          toggleState ? "expandedBlock" : "unExpandedBlock"
        }`}
      >
        <SideBar />
        <div
          className={
            toggleState ? "content-section-extend" : "content-section-minimize"
          }
        >
          <Header title={"Dashboard"} />
          <MoneyStatusBox {...{ featureSummary, reportedSummary }} />
          <AllRequestStatusVerticalGraph />
          <div className="dashboard-content-div">
            <div className="d-flex ">
              <div className="w-7/12">
                <TopFiveOpportunitiesTable />
              </div>
            </div>
            <div className="w-5/12"></div>
            <div className="d-flex ">
              <div className="w-7/12">
                <TopFiveRequest />
              </div>
            </div>
            <div className="w-5/12"></div>

            <WhatsTrending />
          </div>
          <Footer />
        </div>
      </div>
    </Fragment>
  );
}

export default DashboardProductPerson;
