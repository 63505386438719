import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import React from "react";
const WhatsTrendingComponent = () => {
  const rows = [
    {
      name: "Alex Jones",
      para: "Lorem ipsum dolor sit amet, consectetur adipisicing elit,&nbsp;Curabitur venenatis sem at dapibus auctor. Nam aliquam nulla aliquam diam feugiat auctor. Sed ut tellus id nibh pharetra dictum.&nbsp;",
      id: "Jira Id - POC123",
      time: "2 mins ago",
    },
    {
      name: "Clark Kents",
      para: "Lorem ipsum dolor sit amet, consectetur adipisicing elit,&nbsp;Curabitur venenatis sem at dapibus auctor. Nam aliquam nulla aliquam diam feugiat auctor. Sed ut tellus id nibh pharetra dictum.&nbsp;",
      id: "SF ID-POC123",
      time: "2 hrs ago",
    },
    {
      name: "Bruce Wanyne",
      para: "Lorem ipsum dolor sit amet, consectetur adipisicing elit,&nbsp;Curabitur venenatis sem at dapibus auctor. Nam aliquam nulla aliquam diam feugiat auctor. Sed ut tellus id nibh pharetra dictum.&nbsp;",
      id: "SF ID-POC123",
      time: "5 hrs ago",
    },

    {
      name: "Peter Parker",
      para: "Lorem ipsum dolor sit amet, consectetur adipisicing elit,&nbsp;Curabitur venenatis sem at dapibus auctor. Nam aliquam nulla aliquam diam feugiat auctor. Sed ut tellus id nibh pharetra dictum.&nbsp;",
      id: "SF ID-POC123",
      time: "2 days ago",
    },
    {
      name: "Clark Kent",
      para: "Lorem ipsum dolor sit amet, consectetur adipisicing elit,&nbsp;Curabitur venenatis sem at dapibus auctor. Nam aliquam nulla aliquam diam feugiat auctor. Sed ut tellus id nibh pharetra dictum.&nbsp;",
      id: "Jira Id - POC123",
      time: "2 days ago",
    },
  ];

  return (
    <TableContainer
      component={Paper}
      className="mt-2 border-t-4 !border-primary !rounded-none"
    >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row" width={"60%"}>
                <span className="text-[#9A9F95]"> {row.name}</span>
                <br />
                {row.para}
              </TableCell>
              <TableCell>{row.id}</TableCell>
              <TableCell>{row.time}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default WhatsTrendingComponent;
