const types = {
  //COMMON TYPES
  TOGGLE_SIDEBAR: "TOGGLE_SIDEBAR",
  SET_ALERT: "SET_ALERT",

  //AUTH TYPES
  SET_USER: "SET_USER",
  LOGOUT_USER: "LOGOUT_USER",

  // OPPORTUNITIES TYPES
  SET_ALL_OPPORTUNITIES: "SET_ALL_OPPORTUNITIES",
  SET_OPPORTUNITIES_NOTES: "SET_OPPORTUNITIES_NOTES",
  SET_OPPORTUNITIES_SCORE: "SCORE",

  //DASHBOARD
  FEATURE_SUMMARY: "FEATURE_SUMMARY",
  REPORTED_SUMMARY: "REPORTED_SUMMARY",
  // USERS TYPES
  SET_ALL_USERS: "SET_ALL_USERS",

  // Buckets
  SET_ACCOUNTS_BUCKETS: "SET_ACCOUNTS_BUCKETS",
  SET_ACCOUNTS_BUCKETS_DICT: "SET_ACCOUNTS_BUCKETS_DICT",

  // Metrics
  SET_All_METRICS: "SET_All_METRICS",
  SET_SELECTED_METRICS: "SET_SELECTED_METRICS",
  SET_BUCKETS_LIST_BY_METRIC: "SET_BUCKETS_LIST_BY_METRIC",

  // Product Metrics
  SET_ALL_PRODUCT_METRICS: "SET_ALL_PRODUCT_METRICS",
  SET_PRODUCT_SELECTED_METRICS: "SET_PRODUCT_SELECTED_METRICS",
  SET_BUCKETS_LIST_BY_PRODUCT_METRIC: "SET_BUCKETS_LIST_BY_PRODUCT_METRIC",
  // Account that we will Associate with bucket.
  SET_ACCOUNTS: "SET_ACCOUNTS",
  SET_ACCOUNTS_DICT: "SET_ACCOUNTS_DICT",

  //Jira Related Details
  SET_JIRA_PROJECT_LIST: "SET_JIRA_PROJECT_LIST",
  SET_JIRA_ISSUE_TYPE_LIST: "SET_JIRA_ISSUE_TYPE_LIST",

  // Feature
  SET_USER_FEATURE_LIST: "SET_USER_FEATURE_LIST",
  SET_SEARCH_FEATURE_LIST: "SET_SEARCH_FEATURE_LIST",
  SET_FEATURE_DETAILS: "SET_FEATURE_DETAILS",
  SET_APPEND_LIST: "SET_APPEND_LIST",

  //Extentions
  SET_ALL_EXTENTIONS: "SET_ALL_EXTENTIONS",
  SET_ALL_INTEGRATIONLIST: "SET_ALL_INTEGRATIONLIST",
  SET_ALL_INTEGRATIONLIST_TENENT: "SET_ALL_INTEGRATIONLIST_TENENT",
  SET_ALL_EXTENTIONS_DETAILS: "SET_ALL_EXTENTIONS_DETAILS",
};

export default types;
