import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import actions from "../../store/actions";
import AddCustomMetrics from "./metricStep/AddCustomMetrics";
import StepOne from "./metricStep/StepOne";
// import StepThree from "./metricStep/StepThree";
import StepThreeNew from "./metricStep/StepThreeNew";
import StepTwo from "./metricStep/StepTwo";

const ProductMetrics = () => {
  const dispatch = useDispatch();
  const [selectedMetrics, setSelectedMetrics] = useState([]);
  const [isAddCustomMetrics, setIsAddCustomMetrics] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const [totalWeightage, setTotalWeightage] = useState(0);
  const [customId, setCustomId] = useState(1);
  const [metrics, setMetrics] = useState([]);
  const navigate = useNavigate();
  const [isLoadingMetric, setLoadingMetric] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const {
    productMetricsList,
    productSelectedMetricsList,
    productbucketListByMetrics: bucketListByMetrics,
  } = useSelector((state) => state.productMetrics);

  const handleOnSelectMetric = (data) => {
    const updatedMatrics = selectedMetrics;
    if (updatedMatrics.some((sm) => sm.name === data.name)) {
      const filteredPeople = updatedMatrics.filter(
        (item) => item.name !== data.name
      );
      setSelectedMetrics([...filteredPeople]);
    } else {
      data.weightage = 0;
      setSelectedMetrics([...selectedMetrics, data]);
    }
  };

  const handleCustomMetrics = () => {
    setIsAddCustomMetrics(!isAddCustomMetrics);
  };

  const handleAddWeightage = (index) => {
    if (totalWeightage < 100) {
      const oldSelectedMetrics = selectedMetrics;
      let total = 0;
      oldSelectedMetrics[index].weightage =
        oldSelectedMetrics[index].weightage + 5;

      oldSelectedMetrics.map((data) => {
        total = data.weightage + total;
      });
      setTotalWeightage(total);
      setSelectedMetrics(oldSelectedMetrics);
    }
  };

  const handleMinusWeightage = (index) => {
    if (totalWeightage > 0) {
      const oldSelectedMetrics = selectedMetrics;
      let total = 0;
      oldSelectedMetrics[index].weightage =
        oldSelectedMetrics[index].weightage - 5;
      oldSelectedMetrics.map((data) => {
        total = total - data.weightage;
      });
      setTotalWeightage(Math.abs(total));
      setSelectedMetrics(oldSelectedMetrics);
    }
  };

  const handleOnSubmitStepTwo = async () => {
    setIsLoadingSubmit(true);
    const payload = {
      metricsType: "product",
      metricDTOs: [],
    };

    selectedMetrics.map((data) => {
      payload.metricDTOs.push({
        name: data.name ?? "",
        metricObjectId: data.metricObjectId ?? data.name.replace(/ +/g, ""),
        weightage: data.weightage ?? 0,
        type: data.type ?? "",
        tenant: data.tenant ?? user?.tenant,
      });
    });
    const deletedArray = productSelectedMetricsList.filter(
      (data) =>
        !selectedMetrics?.some(
          (selectedMetrics2) =>
            selectedMetrics2.name.toLowerCase() === data.name.toLowerCase()
        )
    );

    if (deletedArray.length > 0) {
      deletedArray.map(async (data) => {
        const res = await dispatch(
          actions.deleteMetricsByMetricsId("PRODUCT", data.metricObjectId)
        );
      });
      payload.deleteMetricsObjectId = deletedArray.map((data) => {
        return data.metricObjectId;
      });
    }
    const res = await dispatch(actions.createMetrics(payload));
    if (res?.status === 201) {
      dispatch(actions.getProductBucketByMetricsId(navigate));
      setActiveStep(3);
    }
    setIsLoadingSubmit(false);
  };

  useEffect(() => {
    if (selectedMetrics.length > 0) {
      let total = 0;
      selectedMetrics.map((data) => {
        total = data.weightage + total;
      });
      setTotalWeightage(total);
    }
  }, [selectedMetrics]);

  useEffect(() => {
    if (productSelectedMetricsList && productMetricsList) {
      setSelectedMetrics(productSelectedMetricsList);
      setMetrics([...productSelectedMetricsList, ...productMetricsList]);
    }
  }, [productSelectedMetricsList, productMetricsList]);

  useEffect(() => {
    dispatch(
      actions.getAllProductMetrics({ ...{ navigate, setLoadingMetric } })
    );
    dispatch(actions.getProductSelectedMetrics());
  }, [dispatch]);

  return (
    <div className=" border-t-2 !border-primary bg-white my-4 mx-3 py-3 px-7">
      {isAddCustomMetrics && (
        <AddCustomMetrics
          {...{
            setIsAddCustomMetrics,
            setSelectedMetrics,
            setMetrics,
            metrics,
            selectedMetrics,
            customId,
            setCustomId,
          }}
        />
      )}
      {activeStep === 1 && !isAddCustomMetrics && (
        <>
          <StepOne
            {...{
              metrics,
              handleOnSelectMetric,
              selectedMetrics,
              handleCustomMetrics,
              setActiveStep,
              totalWeightage,
              isLoadingMetric,
            }}
          />
        </>
      )}
      {activeStep === 2 && !isAddCustomMetrics && (
        <StepTwo
          {...{
            selectedMetrics,
            setActiveStep,
            handleOnSubmitStepTwo,
            handleAddWeightage,
            totalWeightage,
            handleMinusWeightage,
            isLoadingSubmit,
          }}
        />
      )}
      {activeStep === 3 && !isAddCustomMetrics && (
        <StepThreeNew {...{ bucketListByMetrics, setActiveStep }} />
      )}
    </div>
  );
};

export default ProductMetrics;
