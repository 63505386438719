import React, { Fragment } from "react";
import SideBar from "../sidebar/SideBar";
import { useSelector } from "react-redux";
import Header from "../Header/Header";
import SalesMetricMain from "./SalesMetricMain";

function ManageMain() {
  const toggleState = useSelector((state) => state.dashboard.toggleSidebar);

  return (
    <Fragment>
      <div
        className={`d-flex dashboard-main-container manage-main-container ${
          toggleState ? "expandedBlock" : "unExpandedBlock"
        }`}
      >
        <SideBar sideBardExtendHeight={"manage-main-container"} />
        <div
          className={
            toggleState ? "content-section-extend" : "content-section-minimize"
          }
        >
          <Header title={"Metrics > Configure Metrics"} />
          <SalesMetricMain />
        </div>
      </div>
    </Fragment>
  );
}

export default ManageMain;
