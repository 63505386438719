import React from "react";

const TextArea = (props) => {
  const {
    value,
    handleChange,
    placeholader,
    extraClass,
    readOnly = false,
    // ...rest
  } = props;
  return (
    <textarea
      placeholder={placeholader}
      className={`${extraClass ? extraClass : ""} ot-textarea ${
        readOnly ? "!border-0 p-0" : ""
      }`}
      value={value}
      onChange={handleChange}
      readOnly={readOnly}
    />
  );
};

export default TextArea;
