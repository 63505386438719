import React, { Fragment, useState } from "react";
import SideBar from "../sidebar/SideBar";
import Header from "../Header/Header";
import { useSelector } from "react-redux";
import AccountTable from "./AccountTable";
import AddEditAcccountModal from "./addEditAccount/AddEditAcccountModal";

const AccountsMain = () => {
  const toggleState = useSelector((state) => state.dashboard.toggleSidebar);
  const [showAddModalForm, setShowAddModalForm] = useState(false);

  const handleAddModalForm = () => {
    setShowAddModalForm(!showAddModalForm);
  };

  return (
    <Fragment>
      <div
        className={`d-flex dashboard-main-container reports-main-container ${
          toggleState ? "expandedBlock" : "unExpandedBlock"
        }`}
      >
        <SideBar sideBardExtendHeight={"reports-main-container"} />

        <div
          className={
            toggleState ? "content-section-extend" : "content-section-minimize"
          }
        >
          <div className="bg-white min-h-screen">
            <Header title={"Accounts"} />
            <AccountTable {...{ handleAddModalForm }} />
            {showAddModalForm && (
              <>
                <AddEditAcccountModal
                  {...{ showAddModalForm, handleAddModalForm }}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AccountsMain;
