import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import menus from "./menuList";
import isEmpty from "../../store/validations/is-empty";

function SideBar({ sideBardExtendHeight }) {
  const [sidebarMenus, setSidebarMenus] = useState([]);

  const location = useLocation();
  const user = useSelector((state) => state.auth.user);
  const toggleState = useSelector((state) => state.dashboard.toggleSidebar);

  useEffect(() => {
    if (!isEmpty(user)) {
      if (user.roles[0] === "ADMIN") {
        setSidebarMenus(menus.adminMenuList);
      } else if (user.roles[0] === "SALES_ADMIN") {
        setSidebarMenus(menus.salesAdminMenuList);
      } else if (user.roles[0] === "PRODUCT_ADMIN") {
        setSidebarMenus(menus.productAdminMenuList);
      }
    }
  }, [user]);

  return (
    <div
      className={
        toggleState
          ? `extended-sidebar-section sideBar ${sideBardExtendHeight}`
          : "sidebar-collapse-section sideBar"
      }
    >
      <div className="logo-section">
        <div className=" rounded-full bg-white w-8 h-8 mx-auto material-icons">
          <img src="/logo192.png" alt="logo" />
        </div>
        {/* <span className="material-icons">circle</span> */}
      </div>
      <div className="menu-section">
        {!isEmpty(sidebarMenus) &&
          sidebarMenus.map((menu, index) => {
            return (
              <div
                key={index}
                className={
                  location.pathname === menu.link
                    ? "menu-item active-menu-item"
                    : "menu-item"
                }
              >
                <NavLink
                  className="navlink"
                  to={`${menu.link}`}
                  style={({ isActive }) => ({})}
                >
                  {menu.icon}
                  <span
                    className={toggleState ? "menu-title" : "menu-title-hidden"}
                  >
                    {menu.title}
                  </span>
                </NavLink>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default SideBar;
