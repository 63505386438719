import types from "../../types";

const initialState = {
  userList: [],
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_ALL_USERS:
      return {
        ...state,
        userList: action.payload,
      };
    default:
      return { ...state };
  }
}
