import React, { useState, useEffect, Fragment } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  // Navigate,
} from "react-router-dom";
import Login from "../components/auth/Login";
import DashboardSalesPerson from "../components/dashboard/DashboardSalesPerson";
import DashboardProductPerson from "../components/dashboard/DashboardProductPerson";
import DashboardAdmin from "../components/dashboard/DashboardAdmin";
import FeatureRequestMain from "../components/feature-requests/FeatureRequestMain";
import OpportunitiesMain from "../components/opportunities/OpportunitiesMain";
import ReportsMain from "../components/reports/ReportsMain";
import ExtentionsMain from "../components/extentions/ExtentionsMain";
import ManageMain from "../components/manage/ManageMain";
import Default from "../components/Default";
import { PrivateRouteProductAdmin } from "../store/privateRoute/PrivateRouteProductAdmin";
import { PrivateRouteAdmin } from "../store/privateRoute/PrivateRouteAdmin";
import { PrivateRouteSalesAdmin } from "../store/privateRoute/PrivateRouteSalesAdmin";
import UserMain from "../components/users/UsersMain";
import AccountsMain from "../components/accounts/AccountsMain";
import SubmitFeatureRequest from "../components/feature-requests/SubmitFeatureRequest";
import ProductMetricsMain from "../components/productManage/ProductMetricsMain";

function Index() {
  const [windowWidth, setWindowWidth] = useState(768);

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };

  return (
    <Fragment>
      <Router>
        {windowWidth >= 768 && (
          <Routes>
            {/* LOGIN ROUTE */}
            <Route exact path="/" element={<Login />} />
            {/* SALES ADMIN */}
            <Route
              exact
              path="/dashboard-sales-admin"
              element={
                <PrivateRouteSalesAdmin>
                  <DashboardSalesPerson />
                </PrivateRouteSalesAdmin>
              }
            />
            {/* PRODUCT ADMIN */}
            <Route
              exact
              path="/dashboard-product-admin"
              element={
                <PrivateRouteProductAdmin>
                  <DashboardProductPerson />
                </PrivateRouteProductAdmin>
              }
            />
            {/* ADMIN */}
            <Route
              exact
              path="/dashboard-admin"
              element={
                <PrivateRouteAdmin>
                  <DashboardAdmin />
                </PrivateRouteAdmin>
              }
            />
            <Route
              exact
              path="/feature-request"
              element={<FeatureRequestMain />}
            />
            <Route
              exact
              path="/submit-feature-request"
              element={<SubmitFeatureRequest />}
            />
            <Route
              exact
              path="/opportunities"
              element={<OpportunitiesMain />}
            />
            <Route exact path="/reports" element={<ReportsMain />} />
            <Route exact path="/extentions" element={<ExtentionsMain />} />
            <Route exact path="/manage" element={<ManageMain />} />{" "}
            <Route
              exact
              path="/product-manage"
              element={<ProductMetricsMain />}
            />{" "}
            <Route exact path="/users" element={<UserMain />} />
            <Route exact path="/accounts" element={<AccountsMain />} />
            <Route path="*" element={<Default />} />
          </Routes>
        )}
      </Router>
    </Fragment>
  );
}

export default Index;
