import axios from "axios";
import config from "../../../config";
import jwt from "jwt-decode";
import types from "../../types";
import setAuthToken from "../../../utils/setAuthToken";

/*==============================================================
                 AUTH LOGIN ACTION
===============================================================*/

export const loginAction = (formData, navigate) => {
  let response = {};
  return async (dispatch) => {
    try {
      response = await axios.post(`${config.WEB_URL}/login/v1.0/`, formData);

      if (response.data.status === "FAILURE") {
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: true,
            alertType: "error",
            alertMessage: response.data.errorMessage,
          },
        });
      } else {
        const token = response.data.token;
        const user = jwt(token);
        localStorage.setItem("otAuthToken", JSON.stringify(token));
        setAuthToken(token);
        dispatch({
          type: types.SET_USER,
          payload: user,
        });
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: true,
            alertType: "success",
            alertMessage: "Login Sucessfull",
          },
        });

        if (user.roles[0] === "ADMIN") {
          navigate("/dashboard-admin");
        } else if (user.roles[0] === "SALES_ADMIN") {
          navigate("/dashboard-sales-person");
        } else if (user.roles[0] === "PRODUCT_ADMIN") {
          navigate("/dashboard-product-person");
        }
      }
    } catch (error) {
      dispatch({
        type: types.SET_ALERT,
        payload: {
          alert: true,
          alertType: "error",
          alertMessage: "Error in login action",
        },
      });
    }
    return response;
  };
};

/*==============================================================
                 LOGOUT ACTION
===============================================================*/

export const logoutUser = (navigate) => {
  return async (dispatch) => {
    // Toast.info("Logout Sucessfull", 3000);
    //remove token from localstorage
    localStorage.clear();
    // localStorage.clear();
    dispatch({
      type: types.LOGOUT_USER,
    });
    dispatch({
      type: types.SET_ALERT,
      payload: {
        alert: true,
        alertType: "success",
        alertMessage: "Logout Sucessfull",
      },
    });

    //remove token from header
    setAuthToken(false);
    navigate("/");
  };
};
