import axios from "axios";
import config from "../../../config";
import types from "../../types";
import actions from "..";

/*====================================================
                    Create extentions
====================================================*/
export const getReports = (payload, navigate) => {
  // const { payload } = { props };
  let response = {};
  return async (dispatch) => {
    try {
      response = await axios.post(
        `${config.WEB_URL}/api/v1.0/jira/project/report/user/`,
        payload
      );

      if (response.data.status === "SUCCESS")
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: true,
            alertType: "success",
            alertMessage: "Reports Generated Successfully.",
          },
        });
      else
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: true,
            alertType: "error",
            alertMessage: response.data.errorMessage,
          },
        });
    } catch (error) {
      dispatch(actions.logoutUser(navigate));
      dispatch({
        type: types.SET_ALERT,
        payload: {
          alert: true,
          alertType: "error",
          alertMessage: "Error in get report action",
        },
      });
    }
    return response;
  };
};
