import { LoadingOutlined } from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import { DatePicker, Select, Spin } from "antd";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import actions from "../../store/actions";
import { ReportsValidationSchema } from "../../validation/reports";
import Header from "../Header/Header";
import ErrorComponent from "../common/ErrorComponent";
import { DATETYPE, DEFAULT_PAGE_SIZE, REPORTSTAGE } from "../common/constant";
import SideBar from "../sidebar/SideBar";
import ReportsTable from "./ReportsTable";

function ReportsMain() {
  const toggleState = useSelector((state) => state.dashboard.toggleSidebar);
  const {
    // register,
    handleSubmit,
    formState: { errors },
    // setValue,
    watch,
    control,
  } = useForm({
    resolver: yupResolver(ReportsValidationSchema),
  });

  const [reportData, setReportData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecord, setToatlrecord] = useState(0);
  const [loading, setLoading] = useState(false);
  const watchF = watch();
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const handleOnTableChange = (e) => {
    setCurrentPage(e);
  };

  const onSubmit = async () => {
    try {
      setLoading(true);
      const payload = {
        jiraStatus: watchF.reportStage,
        pageNum: currentPage - 1,
        size: DEFAULT_PAGE_SIZE,
      };
      if (watchF.dateType === "Year to date") {
        payload.startDate = moment().startOf("year").format("YYYY-MM-DD");
        payload.endDate = moment().format("YYYY-MM-DD");
      } else if (watchF.dateType === "Select Date") {
        payload.startDate = moment(watchF.rangePicker[0]).format("YYYY-MM-DD");
        payload.endDate = moment(watchF.rangePicker[1]).format("YYYY-MM-DD");
      }
      const res = await dispatch(actions.getReports(payload, navigate));
      if (res.status === 200) {
        setReportData(res?.data?.featurelist);
        setToatlrecord(res?.data?.totalsize);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (currentPage > 1) {
      onSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  return (
    <Fragment>
      <div
        className={`d-flex dashboard-main-container reports-main-container ${
          toggleState ? "expandedBlock" : "unExpandedBlock"
        }`}
      >
        <SideBar sideBardExtendHeight={"reports-main-container"} />

        <div
          className={
            toggleState ? "content-section-extend" : "content-section-minimize"
          }
        >
          <Header title={"Reports"} />
          <div className="min-h-screen bg-white">
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
              <div className="flex items-center justify-between p-8 border-b-[1px] border-[#7B848F]">
                <div className="flex items-start">
                  {/* Report Stage */}
                  <Controller
                    control={control}
                    name={`reportStage`}
                    render={({ field }) => (
                      <div className="flex flex-col">
                        <Select
                          placeholder="Select Report Type"
                          {...field}
                          className={`
                                    "ot-select border-[#797979]"
                                      !w-44 !mr-7`}
                        >
                          {REPORTSTAGE.map((data, index) => (
                            <Option value={data.value}>{data.label}</Option>
                          ))}
                        </Select>
                        {/* Error Meassage */}
                        {errors?.reportStage?.message && (
                          <ErrorComponent
                            {...{
                              errors: errors.reportStage.message,
                              touched: true,
                            }}
                          />
                        )}
                      </div>
                    )}
                  />
                  {/* Report Type */}
                  {/* <Controller
                    control={control}
                    name={`reportType`}
                    render={({ field }) => (
                      <>
                        <Select
                          placeholder="Select"
                          {...field}
                          className={`
                                    "ot-select border-[#797979]"
                                      !w-44 !mr-7`}
                        >
                          {REPORTYPE.map((data, index) => (
                            <Option value={data.value}>{data.label}</Option>
                          ))}
                        </Select>
                      </>
                    )}
                  /> */}
                  {/* Date Type */}
                  <Controller
                    control={control}
                    name={`dateType`}
                    render={({ field }) => (
                      <div className="flex flex-col">
                        <Select
                          placeholder="Select"
                          {...field}
                          className={`ot-select border-[#797979] !w-44 !mr-7`}
                        >
                          {DATETYPE.map((data, index) => (
                            <Option value={data.value}>{data.label}</Option>
                          ))}
                        </Select>
                        {/* Error Meassage */}
                        {errors?.dateType?.message && (
                          <ErrorComponent
                            {...{
                              errors: errors.dateType.message,
                              touched: true,
                            }}
                          />
                        )}
                      </div>
                    )}
                  />
                  {watchF?.dateType === "Select Date" && (
                    <Controller
                      control={control}
                      name={`rangePicker`}
                      onChange={([selected]) => ({ value: selected })}
                      render={({ field }) => (
                        <RangePicker {...field} size="middle" />
                      )}
                    />
                  )}
                </div>
                <button
                  type="submit"
                  className="text-white !font-medium !bg-primary text-[13px] rounded-[3px]  !px-7 !py-2 generateReportBtn"
                >
                  {loading && <Spin indicator={antIcon} />}
                  Generate Reports
                </button>
              </div>
            </form>
            {reportData?.length === 0 && (
              <h1 className=" text-4xl py-8 text-center">No Data Found</h1>
            )}
            {reportData?.length > 0 && (
              <div className="pt-3 pl-3 pr-5">
                <ReportsTable
                  {...{
                    watchF,
                    reportData,
                    loading,
                    handleOnTableChange,
                    currentPage,
                    totalRecord,
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default ReportsMain;
