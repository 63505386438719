import React, { useState } from "react";
import { SearchSharp } from "@mui/icons-material";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Pagination, Spin } from "antd";
import { OPPORTUNITIESTABLE } from "../common/constant";
import isEmpty from "../../store/validations/is-empty";
import shortText from "../../utils/sliceString";
import GreenBtn from "../common/greenBtn";

const recordsInOnePage = 10;

const OpportunitiesExpandedTableContent = (props) => {
  const {
    tableData,
    handleSelectedTableRow,
    totalOpp,
    handleOnTableChange,
    currentPage,
    isLoading,
    selectedOpportunity,
  } = props;

  /*********************************
   
            pagination handler
  
  ******************************** */
  return (
    <div>
      <div className="relative flex items-center justify-between">
        <div className="flex items-center">
          <MonetizationOnOutlinedIcon className=" !text-lg !leading-0 mr-1" />{" "}
          <h5 className="text-base mb-0">Opportunities ({totalOpp})</h5>
        </div>
        <div className="flex items-center">
          <Pagination
            simple
            defaultCurrent={currentPage}
            total={totalOpp}
            onChange={(e) => handleOnTableChange(e)}
          />
          <SearchSharp className="!text-primary" />
          <MoreVertIcon className="!text-primary cursor-pointer z-[9999]" />
        </div>
      </div>

      <div className="w-full relative">
        {!isEmpty(tableData) &&
          tableData.map((data, key) => {
            return (
              key >= currentPage - 1 * recordsInOnePage &&
              key < currentPage * recordsInOnePage && (
                <div
                  key={key}
                  className={` py-3 px-2 cursor-pointer ${
                    selectedOpportunity?.id === data.id ? " bg-[#D8D8D8]" : ""
                  }`}
                  onClick={() => handleSelectedTableRow(data)}
                >
                  <div className="flex justify-between">
                    <div className="pr-1 W-3/12">
                      {/* <h5 className="text-xs mb-0"> */}{" "}
                      {/* {shortText(data.id, 4, true)} */}
                      {/* </h5> */}
                      <h5 className="text-xs mb-0">{data.amount}</h5>
                    </div>
                    <div className="w-7/12">
                      <h5 className="text-xs mb-0">{data.name}</h5>
                      {/* <h5 className="text-xs mb-0">{data.probability}</h5> */}
                    </div>
                    <div className="w-2/12">
                      {data.priority === "High" ? (
                        <GreenBtn
                          {...{
                            text: data.priority,
                            extrClass: "w-14 !cursor-auto",
                          }}
                        />
                      ) : data.priority === "Medium" ? (
                        <GreenBtn
                          {...{
                            text: data.priority,
                            extrClass: "!bg-[#EB901C] w-14  !cursor-auto",
                          }}
                        />
                      ) : (
                        <GreenBtn
                          {...{
                            text: data.priority,
                            extrClass: "!bg-[#2BB8FF] w-14  !cursor-auto",
                          }}
                        />
                      )}
                    </div>
                  </div>
                  {data.description && (
                    <h5 className="text-xs mb-0 mt-2">
                      <span className="mr-2 !text-primary">Description </span>
                      {data.description}
                    </h5>
                  )}
                </div>
              )
            );
          })}
        {isLoading && (
          <div className="h-full bg-white bg-opacity-50 w-96 flex justify-center items-center absolute left-0 top-0">
            <Spin />
          </div>
        )}
      </div>
    </div>
  );
};

export default OpportunitiesExpandedTableContent;
