import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SideBar from "../sidebar/SideBar";
import Header from "../Header/Header";
import MoneyStatusBox from "./MoneyStatusBox";
import AllRequestStatusVerticalGraph from "./common/AllRequestStatusVerticalGraph";
import TopFiveOpportunitiesTable from "./common/TopFiveOpportunitiesTable";
import TopFiveRequest from "./common/TopFiveRequest";
import WhatsTrending from "./common/WhatsTrending";
import Footer from "../Footer/Footer";
import DashboardYourPerformance from "../graphs/DashboardYourPerformance";
import DashboardYourPerformanceOpprtunitesChart from "../graphs/DashboardYourPerformanceOpprtunitesChart";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import BarChartIcon from "@mui/icons-material/BarChart";
import actions from "../../store/actions";

function DashboardSalesPerson() {
  const dispatch = useDispatch();
  const toggleState = useSelector((state) => state.dashboard.toggleSidebar);
  const { tenant } = useSelector((state) => state.auth.user);
  const { featureSummary, reportedSummary } = useSelector(
    (state) => state.dashboard
  );
  useEffect(() => {
    dispatch(actions.getAllUsers({ tenant }));
    dispatch(actions.getFeatureSummary());
    dispatch(actions.getReportedSummary());
    dispatch(actions.getExtensions());
  }, [dispatch, tenant]);

  return (
    <Fragment>
      <div
        className={`d-flex dashboard-main-container dashboard_sales_main_container ${
          toggleState ? "expandedBlock" : "unExpandedBlock"
        }`}
      >
        <SideBar sideBardExtendHeight={"dashboard_sales_main_container"} />
        <div
          className={
            toggleState ? "content-section-extend" : "content-section-minimize"
          }
        >
          <Header title={"Dashboard"} />
          <MoneyStatusBox {...{ featureSummary, reportedSummary }} />
          <div className="dashboard-content-div">
            <AllRequestStatusVerticalGraph />
            <div className="d-flex mt-5">
              <div className="w-8/12">
                <TopFiveOpportunitiesTable />
              </div>
              <div className="w-4/12 pl-5">
                <div className="flex justify-between items-center mb-2">
                  <h2 className="font-15-regular">
                    <MonetizationOnIcon className=" text-gray-600 !text-base !leading-0 mr-1" />{" "}
                    Your Performance
                  </h2>
                  <div className="section-right">
                    <span className="material-icons">keyboard_arrow_up</span>
                  </div>
                </div>
                <DashboardYourPerformanceOpprtunitesChart />
              </div>
            </div>
            <div className="w-5/12"></div>
            <div className="d-flex mt-5">
              <div className="w-8/12">
                <TopFiveRequest />
              </div>
              <div className="w-4/12 pl-5">
                <div className="flex justify-between items-center mb-2">
                  <h2 className="font-15-regular">
                    <BarChartIcon className=" text-gray-600 !text-base !leading-0 mr-1" />
                    Your Performance
                  </h2>
                  <div className="section-right">
                    <span className="material-icons">keyboard_arrow_up</span>
                  </div>
                </div>
                <DashboardYourPerformance />
              </div>
            </div>
            <div className="w-5/12"></div>
            <WhatsTrending />
          </div>
          <Footer />
        </div>
      </div>
    </Fragment>
  );
}

export default DashboardSalesPerson;
