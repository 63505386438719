import { Modal } from "antd";
import React from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const DeleteExtension = (props) => {
  const { isDeleteExtension, handleDeleteExtension } = props;
  return (
    <Modal
      width={"30%"}
      open={isDeleteExtension}
      onCancel={handleDeleteExtension}
      footer={false}
    >
      <div className="text-center">
        <ErrorOutlineIcon color="#030303" className="!text-[100px]" />
        <h2 className=" text-3xl text-gray-400 mb-0 pt-7">Are you sure?</h2>
        <h2 className="text-sm text-gray-400 font-light pt-2 mb-0">
          You will lose all your settings for salesforce extention
        </h2>
        <div className="flex justify-between items-center pt-14 px-2">
          <button
            className="text-white !bg-gray-500 text-[13px]  rounded-[3px] !px-5 w-[120px] !py-1"
            onClick={handleDeleteExtension}
          >
            Cancel
          </button>
          <button
            className="text-white !bg-[#EE584C] text-[13px]  rounded-[3px] !px-5 w-[120px] !py-1"
            onClick={handleDeleteExtension}
          >
            Yes, delete
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteExtension;
