import React from "react";
import WhatsTrendingComponent from "../tables/WhatsTrendingComponent";
import TrendingUpTwoToneIcon from "@mui/icons-material/TrendingUpTwoTone";

function WhatsTrending() {
  return (
    <div className="mt-5">
      <div className="flex justify-between items-center">
        <h2 className="font-15-regular">
          <TrendingUpTwoToneIcon className=" text-gray-600 !text-lg !leading-0 mr-1" />{" "}
          WhatsTrending
        </h2>
        <div className="section-right">
          <p>View all </p>
          <span className="material-icons">keyboard_arrow_up</span>
        </div>
      </div>
      <WhatsTrendingComponent />
    </div>
  );
}

export default WhatsTrending;
