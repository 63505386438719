import React, { useState } from "react";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Table, Checkbox } from "antd";
import GreenBtn from "../common/greenBtn";
import { MenuProps } from "antd";
import { Button, Dropdown } from "antd";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { DownloadOutlined } from "@ant-design/icons";
// import { DownloadOutlined } from "@mui/icons-material";
// import { OPPORTUNITIESTABLE } from "../common/constant";
// Don't Delete Below shortText Comment Command
// import shortText from "../../utils/sliceString";

const OpportunitiesTable = (props) => {
  const {
    handleExpanded,
    isExpanded,
    tableData,
    handleSelectedTableRow,
    totalOpp,
    handleOnTableChange,
    currentPage,
    isLoading,
  } = props;
  const [checkboxList, setCheckboxList] = useState([
    // { name: "ID", value: true },
    { name: "Name", value: true },
    { name: "Amount", value: true },
    { name: "Probability", value: true },
    { name: "Priority", value: true },
    { name: "Close Date", value: true },
    // { name: "Competitor", value: false },
    // { name: "Metric ayz", value: false },
    // { name: "Metrix abc", value: false },
  ]);
  const columns = [
    // NOTE: Do Not Delete below commented Line in future if we need to enable ID field that time we will use these lines.
    // {
    //   title: "ID",
    //   dataIndex: "id",
    //   key: "id",
    //   sorter: (a) => a,
    //   render: (text, data) => (
    //     <div
    //       onClick={() => {
    //         handleExpanded();
    //         handleSelectedTableRow(data);
    //       }}
    //       className="py-3 px-2"
    //     >
    //       {shortText(text, 4, true)}
    //     </div>
    //   ),
    // },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      hidden: !checkboxList[0]?.value,
      sorter: (a, b) => a.name.length - b.name.length,
      render: (name, data) => (
        <div
          onClick={() => {
            handleExpanded();
            handleSelectedTableRow(data);
          }}
          className="py-3 px-2 cursor-pointer"
        >
          {" "}
          {name}
        </div>
      ),
    },
    {
      title: "Amount ($)",
      dataIndex: "amount",
      key: "amount",
      hidden: !checkboxList[1]?.value,
      sorter: (a, b) => a.amount - b.amount,
      render: (amount, data) => (
        <div
          onClick={() => {
            handleExpanded();
            handleSelectedTableRow(data);
          }}
          className="py-3 px-2 cursor-pointer"
        >
          {amount}
        </div>
      ),
    },
    {
      title: "Probability",
      key: "probability",
      hidden: !checkboxList[2]?.value,
      dataIndex: "probability",
      sorter: (a, b) => a.probability - b.probability,
      render: (probability, data) => (
        <div
          onClick={() => {
            handleExpanded();
            handleSelectedTableRow(data);
          }}
          className="py-3 px-2 cursor-pointer"
        >
          {probability}
        </div>
      ),
    },
    {
      title: "Priority",
      key: "priority",
      dataIndex: "priority",
      hidden: !checkboxList[3]?.value,
      // sorter: (a, b) => a.probability - b.probability,
      // sorter: (a) => a,
      render: (priority, data) => (
        <div
          className="py-3 px-2 cursor-pointer"
          onClick={() => {
            handleExpanded();
            handleSelectedTableRow(data);
          }}
        >
          {priority === "High" ? (
            <GreenBtn
              {...{
                text: priority,
                extrClass: "w-14 !cursor-auto",
              }}
            />
          ) : priority === "Medium" ? (
            <GreenBtn
              {...{
                text: priority,
                extrClass: "!bg-[#EB901C] w-14  !cursor-auto",
              }}
            />
          ) : (
            <GreenBtn
              {...{
                text: priority,
                extrClass: "!bg-[#2BB8FF] w-14  !cursor-auto",
              }}
            />
          )}
        </div>
      ),
    },
    {
      title: "Close Date",
      key: "closeDate",
      hidden: !checkboxList[4]?.value,
      dataIndex: "closeDate",
      // sorter: (a) => a,
      render: (closeDate, data) => (
        <div
          className="py-3 px-2 cursor-pointer"
          onClick={() => {
            handleExpanded();
            handleSelectedTableRow(data);
          }}
        >
          {closeDate}
        </div>
      ),
    },
  ].filter((item) => !item.hidden);

  const handleChangeCheckbox = (con) => {
    const updatedMatrics = checkboxList?.map((data, key) => {
      if (data.name === con) {
        return { ...data, value: !data.value };
      }
      return data;
    });

    setCheckboxList([...updatedMatrics]);
  };

  const items = [
    {
      key: "1",
      label: (
        <div
          className="flex items-center text-sm pb-3 border-b-[1px] border-gray-500"
          onClick={() => window.location.reload()}
        >
          <AutorenewIcon className="!w-4 mr-2 text-gray-500 " />
          Refresh Opportunities
        </div>
      ),
    },
    // {
    //   key: "2",
    //   label: (
    //     <div className="flex items-center text-sm pb-3 ">
    //       <DownloadOutlined className="mr-2 !text-gray-500" />
    //       Export All Opportunities
    //     </div>
    //   ),
    // },
    // {
    //   key: "3",
    //   label: (
    //     <div className="flex items-center text-sm pb-3 border-b-[1px] border-gray-500">
    //       <DownloadOutlined className="mr-2 !text-gray-500" />
    //       Export Current View
    //     </div>
    //   ),
    // },
    {
      key: "4",
      label: (
        <div className="flex flex-col">
          <h5 className="text-[#979696] text-sm">Manage List Columns</h5>
          {checkboxList?.map((data, key) => (
            <Checkbox
              checked={data.value}
              className="ot-checkbox mb-2 !ml-0"
              onChange={() => handleChangeCheckbox(data.name)}
            >
              {data.name}
            </Checkbox>
          ))}
        </div>
      ),
    },
  ];
  return (
    <div className="relative opportunities-table-expanded">
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center">
          <MonetizationOnOutlinedIcon className=" !text-lg !leading-0 mr-1" />{" "}
          <h5 className="text-base mb-0">Opportunities ({totalOpp})</h5>
        </div>
        <Dropdown menu={{ items }} placement="bottomLeft" arrow>
          <MoreVertIcon className="!text-primary cursor-pointer " />
        </Dropdown>
      </div>
      <div className={`pt-3`}>
        <Table
          columns={columns}
          className={isExpanded ? "opportunitiesExpandedTable" : ""}
          dataSource={tableData ?? []}
          loading={isLoading}
          pagination={{
            position: ["topRight"],
            pageSize: 10,
            defaultCurrent: currentPage,
            simple: true,
            total: totalOpp,
            onChange: (e) => {
              handleOnTableChange(e);
            },
          }}
          // scroll={{ y: 640, x: 1000 }}
        />
      </div>
    </div>
  );
};

export default OpportunitiesTable;
