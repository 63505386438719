import types from "../../types";
import isEmpty from "../../validations/is-empty";

const initialState = {
  isAuthenticated: false,
  user: {},
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
      };
    case types.LOGOUT_USER:
      return {
        ...state,
        isAuthenticated: false,
        user: {},
      };
    default:
      return { ...state };
  }
}
