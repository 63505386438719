import React, { Fragment } from "react";
import AdminWidgetBox from "./common/AdminWidgetBox";

const AdminWidgetBoxes = (props) => {
  const { featureSummary, reportedSummary } = props;
  return (
    <Fragment>
      <div className="admin-widget-box-container">
        {featureSummary?.map((data, key) => (
          <AdminWidgetBox
            title={Object.keys(data)}
            // icon={
            //   <BusinessCenterIcon className="text-white-600 !text-base !leading-0 mr-1" />
            // }
            count={Object.values(data)}
          />
        ))}
        {reportedSummary?.map((data, key) => (
          <AdminWidgetBox
            title={Object.keys(data)}
            // icon={
            //   <BusinessCenterIcon className="text-white-600 !text-base !leading-0 mr-1" />
            // }
            count={`$${Intl.NumberFormat("en-US", {
              notation: "compact",
              maximumFractionDigits: 1,
            }).format(Object.values(data))}`}
          />
        ))}

        {/* <AdminWidgetBox
          title={"Total Users"}
          icon={
            <PeopleAltIcon className="text-white-600 !text-base !leading-0 mr-1" />
          }
          count={117}
        />
        <AdminWidgetBox
          title={"Sales Executives"}
          icon={
            <BusinessCenterIcon className="text-white-600 !text-base !leading-0 mr-1" />
          }
          count={34}
        />
        <AdminWidgetBox
          title={"Sales Managers"}
          icon={
            <BusinessCenterIcon className="text-white-600 !text-base !leading-0 mr-1" />
          }
          count={12}
        />
        <AdminWidgetBox
          title={"Product Executives"}
          icon={
            <BusinessCenterIcon className="text-white-600 !text-base !leading-0 mr-1" />
          }
          count={56}
        />
        <AdminWidgetBox
          title={"Product Managers"}
          icon={
            <BusinessCenterIcon className="text-white-600 !text-base !leading-0 mr-1" />
          }
          count={5}
        />
        <AdminWidgetBox
          title={"Admins"}
          icon={
            <AdminPanelSettingsIcon className="text-white-600 !text-base !leading-0 mr-1" />
          }
          count={10}
        />
        <AdminWidgetBox
          title={"Extentions"}
          icon={
            <SettingsIcon className="text-white-600 !text-base !leading-0 mr-1" />
          }
          count={2}
        /> */}
      </div>
    </Fragment>
  );
};

export default AdminWidgetBoxes;
