import axios from "axios";
import actions from "..";
import config from "../../../config";
import types from "../../types";

/*==============================================================
                GET All Jira Project
===============================================================*/

export const getAllProject = (props) => {
  const { navigate } = props;
  let response = {};
  return async (dispatch) => {
    try {
      response = await axios.get(
        `${config.WEB_URL}/api/v1.0/jira/project/all/`
      );
      if (response.status === 200) {
        dispatch({
          type: types.SET_JIRA_PROJECT_LIST,
          payload: response.data,
        });
      } else {
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: true,
            alertType: "error",
            alertMessage:
              response.data.errorMessage || "Not Able to Fetch the Jira Issue",
          },
        });
        dispatch({
          type: types.SET_JIRA_PROJECT_LIST,
          payload: [],
        });
      }
    } catch (error) {
      dispatch({
        type: types.SET_ALERT,
        payload: {
          alert: true,
          alertType: "error",
          alertMessage: "Not Able to Fetch the Jira Issue",
        },
      });
      dispatch(actions.logoutUser(navigate));
    }
    return response;
  };
};

// api/v1.0/jira/project/issuetype/all/

export const getAllIssueType = (props) => {
  const { navigate } = props;
  let response = {};
  return async (dispatch) => {
    try {
      response = await axios.get(
        `${config.WEB_URL}/api/v1.0/jira/project/issuetype/all/`
      );
      if (response.status === 200) {
        dispatch({
          type: types.SET_JIRA_ISSUE_TYPE_LIST,
          payload: response.data,
        });
      } else {
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: true,
            alertType: "error",
            alertMessage:
              response.data.errorMessage || "Not Able to Fetch the Jira Issue",
          },
        });
        dispatch({
          type: types.SET_JIRA_ISSUE_TYPE_LIST,
          payload: [],
        });
      }
    } catch (error) {
      dispatch({
        type: types.SET_ALERT,
        payload: {
          alert: true,
          alertType: "error",
          alertMessage: "Not Able to Fetch the Jira Issue",
        },
      });
      dispatch(actions.logoutUser(navigate));
    }
    return response;
  };
};
