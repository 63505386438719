import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useDispatch } from "react-redux";
import actions from "../../store/actions";
import axios from "axios";
import CancelIcon from "@mui/icons-material/Cancel";
import config from "../../config";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const JiraComponent = (props) => {
  const { extentionsList, handleExtension, handleDeleteExtension } = props;
  const dispatch = useDispatch();
  const [checkConnection, setCheckConnection] = useState(true);
  const [checkLoader, setCheckConnectionLoader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const { register, handleSubmit, errors, setValue, watch } = useForm({
    defaultValues: {
      accountType: "",
      url: "",
      username: "",
      secretToken: "",
    },
  });

  const watchF = watch();

  const onSubmit = async (values) => {
    setSaveLoader(true);
    const payload = {
      accountType: values.accountType,
      url: values.url,
      username: values.username,
      secretToken: values.secretToken,
      tenant: extentionsList?.tenant,
      type: extentionsList?.type,
      userId: extentionsList?.userId,
      id: extentionsList?.id,
    };
    const res = await dispatch(actions.addJira(payload));
    if (res) {
      handleExtension();
      setSaveLoader(false);
    }
  };

  const handleCheckConnection = async () => {
    setCheckConnectionLoader(true);
    const payload = {
      accountType: watchF.accountType,
      url: watchF.url,
      username: watchF.username,
      secretToken: watchF.secretToken,
      tenant: extentionsList.tenant,
      type: extentionsList.type,
      userId: extentionsList.userId,
      id: extentionsList.id,
    };
    const res = await dispatch(actions.checkJiraConnection(payload));
    if (res) {
      setCheckConnection(res.data);
      setCheckConnectionLoader(false);
    }
  };

  const handleGenerateWebhookUrl = async () => {
    const response = await axios.get(
      `${config.WEB_URL}/api/v1.0/jira/project/generatewebhook/`
    );
    if (response.data) {
      setValue("webhookUrl", response.data);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(
        `${config.WEB_URL}/api/v1.0/jira/project/getwebhookurl/`
      );
      if (response.data) {
        setValue("webhookUrl", response.data);
      }
    };
    fetchData();
  });

  const setValueData = () => {
    if (extentionsList) {
      setValue("accountType", extentionsList.accountType);
      setValue("url", extentionsList.url);
      setValue("username", extentionsList.username);
      setValue("secretToken", extentionsList.secretToken);
    }
  };
  useEffect(() => {
    setValueData();
  }, [extentionsList]);

  return (
    <div>
      <form id="jiraExtentions" onSubmit={handleSubmit(onSubmit)}>
        <div className="bg-white pt-4 px-14 pb-14 flex flex-col">
          <div className="w-10/12">
            <div className="flex items-center justify-between">
              <h2 className=" text-base mb-0 flex items-center">
                <img
                  src="/jira.png"
                  className="w-[62px] mr-5"
                  alt="salesforces"
                />
                Jira Authentication Settings
              </h2>
              <div
                className="bg-[#002244] text-white text-sm py-2 pl-6 pr-2 rounded cursor-pointer"
                onClick={handleCheckConnection}
              >
                Test
                {checkLoader && <Spin indicator={antIcon} className="!ml-3" />}
                {!checkLoader && checkConnection && (
                  <CheckCircleIcon className="!text-primary !text-lg ml-2" />
                )}
                {!checkLoader && !checkConnection && (
                  <CancelIcon className="!text-[#EE584C] !text-lg ml-2" />
                )}{" "}
              </div>
            </div>
            <div className="flex flex-col">
              <div className="flex">
                <label htmlFor="accountTypeCloud">
                  <input
                    {...register("accountType")}
                    type="radio"
                    value="Cloud"
                    id="accountType"
                  />
                  <span className="ml-2">Cloud JIRA</span>
                </label>
                <label htmlFor="accountTypeSlefHosted" className="ml-8">
                  <input
                    {...register("accountType")}
                    type="radio"
                    value="Self"
                    id="accountType"
                  />
                  <span className="ml-2">Self-hosted JIRA</span>
                </label>
              </div>
              <h3 className="mt-5">{watchF?.accountType}</h3>
              {watchF?.accountType === "Cloud" && (
                <div className="flex flex-col">
                  <input
                    {...register("url")}
                    name={`url`}
                    id={`url`}
                    placeholder="URL"
                    className={`ot-input ot-input--manage mt-7 ${
                      errors?.url ? "ot-input-error" : ""
                    }`}
                  />
                  <input
                    {...register("username")}
                    name={`username`}
                    id={`username`}
                    placeholder="Email"
                    className={`ot-input ot-input--manage mt-7 ${
                      errors?.username ? "ot-input-error" : ""
                    }`}
                  />
                  <input
                    {...register("secretToken")}
                    name={`secretToken`}
                    id={`secretToken`}
                    placeholder="API token"
                    className={`ot-input ot-input--manage mt-7 ${
                      errors?.username ? "ot-input-error" : ""
                    }`}
                  />
                </div>
              )}
              {watchF?.accountType === "Self" && (
                <div className="flex flex-col">
                  <input
                    {...register("url")}
                    name={`url`}
                    id={`url`}
                    placeholder="URL"
                    className={`ot-input ot-input--manage mt-7 ${
                      errors?.url ? "ot-input-error" : ""
                    }`}
                  />
                  <input
                    {...register("username")}
                    name={`username`}
                    id={`username`}
                    placeholder="Email"
                    className={`ot-input ot-input--manage mt-7 ${
                      errors?.username ? "ot-input-error" : ""
                    }`}
                  />
                  <input
                    {...register("password")}
                    name={`password`}
                    type={"password"}
                    id={`password`}
                    placeholder="Password"
                    className={`ot-input ot-input--manage mt-7 ${
                      errors?.username ? "ot-input-error" : ""
                    }`}
                  />
                  <input
                    {...register("webhookUrl")}
                    name={`webhookUrl`}
                    type={"webhookUrl"}
                    id={`webhookUrl`}
                    placeholder="Webhook Url"
                    className={`ot-input ot-input--manage mt-7 ${
                      errors?.username ? "ot-input-error" : ""
                    }`}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="flex  justify-center w-10/12 mt-5">
            {watchF?.accountType === "Self" && (
              <button
                type="button"
                className=" !bg-primary text-white py-2 px-4 rounded text-sm disabled:opacity-50 submit-btn mr-3"
                onClick={() => handleGenerateWebhookUrl()}
              >
                Generate Webhook URL
              </button>
            )}
            {/* <button
              type="button"
              className=" text-white !bg-gray-500 text-[13px]  rounded-[3px] !px-5 w-[200px] !py-1 mt-3"
              onClick={() => handleExtension()}
            >
              Cancel
            </button> */}
            <button
              type="button"
              className=" text-white !bg-[#EE584C] py-2 px-4 rounded text-sm disabled:opacity-50 mx-3"
              onClick={() => handleDeleteExtension()}
            >
              Delete
            </button>
            <button
              className=" !bg-primary text-white py-2 px-4 rounded text-sm disabled:opacity-50 submit-btn ml-3"
              type="submit"
              htmlFor="manageExtention"
            >
              {saveLoader && <Spin indicator={antIcon} className="!mr-3" />}
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default JiraComponent;
