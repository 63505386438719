import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import DoDisturbAltIcon from "@mui/icons-material/DoDisturbAlt";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import FolderIcon from "@mui/icons-material/Folder";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import React from "react";

function AdminWidgetBox({ title, count }) {
  return (
    <div className="box-container">
      <div
        className={`box-title-section ${
          title[0] === "Won"
            ? "!bg-primary"
            : title[0] === "Lost"
            ? " bg-red-600"
            : title[0] === "Open"
            ? "bg-[#002244]"
            : title[0] === "Submitted"
            ? "bg-secondary"
            : title[0] === "In Progress"
            ? "bg-[#2689ED]"
            : title[0] === "Completed" || title[0] === "Done"
            ? " !bg-primary"
            : title[0] === "On Hold"
            ? " bg-gray-500"
            : title[0] === "Rejected"
            ? "bg-red-500"
            : "bg-[#002244]"
        }`}
      >
        {title}
        {title[0] === "Won" ? (
          <EmojiEventsIcon className="!text-base" />
        ) : title[0] === "Lost" ? (
          <ThumbDownOffAltIcon className="!text-base" />
        ) : title[0] === "Open" ? (
          <FolderIcon className="!text-base" />
        ) : title[0] === "Submitted" ? (
          <SaveAltIcon className="!text-base" />
        ) : title[0] === "In Progress" ? (
          <RocketLaunchIcon className="!text-base" />
        ) : title[0] === "Completed" || title[0] === "Done" ? (
          <CheckIcon className="!text-base" />
        ) : title[0] === "On Hold" ? (
          <DoDisturbAltIcon className="!text-base" />
        ) : title[0] === "Rejected" ? (
          <DoNotDisturbIcon className="!text-base" />
        ) : title[0] === "Deleted" ? (
          <DeleteIcon className="!text-base" />
        ) : (
          ""
        )}
      </div>
      <div className="box-count-section">{count}</div>
    </div>
  );
}

export default AdminWidgetBox;
