import axios from "axios";
import config from "../../../config";
import types from "../../types";

/*==============================================================
                GET ALL USERS
===============================================================*/

export const getAllUsers = ({ tenant }) => {
  let response = {};
  return async (dispatch) => {
    try {
      response = await axios.get(
        `${config.WEB_URL}/api/v1.0/user/tenant/${tenant}`
      );

      if (response.data.status === "FAILURE") {
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: true,
            alertType: "error",
            alertMessage: response.data.errorMessage,
          },
        });
      } else if (response.data.status === "SUCCESS") {
        dispatch({
          type: types.SET_ALL_USERS,
          payload: response.data.userDTOS,
        });
      }
    } catch (error) {
      dispatch({
        type: types.SET_ALERT,
        payload: {
          alert: true,
          alertType: "error",
          alertMessage: "Error in user action",
        },
      });
    }
    return response;
  };
};

/*===================================================================
                GET ALL NUMBER
===================================================================*/
export const getFeatureSummary = () => {
  let response = {};
  return async (dispatch) => {
    try {
      response = await axios.get(
        `${config.WEB_URL}/api/v1.0/dashboard/featuresummary/`
      );
      if (response.data.status === "FAILURE") {
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: true,
            alertType: "error",
            alertMessage: response.data.errorMessage,
          },
        });
      } else if (response.status === 200) {
        dispatch({
          type: types.FEATURE_SUMMARY,
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch({
        type: types.SET_ALERT,
        payload: {
          alert: true,
          alertType: "error",
          alertMessage: "Error in feature action",
        },
      });
    }
    return response;
  };
};

/*===================================================================
                GET ALL NUMBER
===================================================================*/
export const getReportedSummary = () => {
  let response = {};
  return async (dispatch) => {
    try {
      response = await axios.get(
        `${config.WEB_URL}/api/v1.0/opportunity/reportbyuser`
      );
      if (response.data.status === "FAILURE") {
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: true,
            alertType: "error",
            alertMessage: response.data.errorMessage,
          },
        });
      } else if (response.status === 200) {
        dispatch({
          type: types.REPORTED_SUMMARY,
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch({
        type: types.SET_ALERT,
        payload: {
          alert: true,
          alertType: "error",
          alertMessage: "Error in reported action",
        },
      });
    }
    return response;
  };
};
