import * as Yup from "yup";

export const UserSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email Address Required"),
  firstName: Yup.string().required("First Name Required"),
  lastName: Yup.string().required("Last Name Required"),
  password: Yup.string().required("Password Required"),
  confirmedPassword: Yup.string()
    .required("Password Required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
  phoneNumber: Yup.string().required("Phone Number Required"),
  homeNumber: Yup.string().required("Home Number Required"),
  role: Yup.string().nullable().required("Role Required"),
  // role: Yup.object()
  //   .shape({
  //     label: Yup.string().required("Role is required"),
  //     value: Yup.string().required("Role is required"),
  //   })
  //   .nullable()
  //   .required("Role is required"),
});
