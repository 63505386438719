import React, { Fragment } from "react";

function ForgetPassword() {
  return (
    <Fragment>
      <div className="forget-password-form-container">
        <h1>Forgot Password</h1>
        <h3>
          Enter your email address and Tenant name and your password will be
          reset and emailed to you.
        </h3>
        <div className="forget-password-form">
          <input
            className="text-box"
            type="text"
            placeholder="Email ID"
          ></input>
          <input className="text-box" type="text" placeholder="Tanant"></input>
          <button>Send new password </button>
          <hr className="line mt145" />
          <p className="">Or sign in with</p>
          <div className="social-login-section d-flex mt34">
            <div className="d-flex justify-content-center ">
              <img
                src={require("./../../assets/images/auth/google.png")}
                alt=""
                className="pointer"
              ></img>
              <p className="ml19 pointer">Google</p>
            </div>
            <div className="d-flex justify-content-center ">
              <img
                src={require("./../../assets/images/auth/microsoft.png")}
                alt=""
                className="pointer"
              ></img>
              <p className="ml19 pointer">Work Account</p>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default ForgetPassword;
