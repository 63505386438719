import AutorenewIcon from "@mui/icons-material/Autorenew";
import ListAltIcon from "@mui/icons-material/ListAlt";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Checkbox, Dropdown, Table } from "antd";
import React, { useState } from "react";
import { DEFAULT_PAGE_SIZE } from "../common/constant";

const ReportsTable = (props) => {
  const {
    watchF,
    reportData,
    loading,
    handleOnTableChange,
    currentPage,
    totalRecord,
  } = props;

  const [checkboxList, setCheckboxList] = useState([
    { name: "Jira Id", value: true },
    { name: "Name", value: true },
    { name: "Amount", value: true },
    { name: "Probability", value: true },
    { name: "Appends", value: true },
    { name: "Close Date", value: true },
  ]);

  const handleChangeCheckbox = (value) => {
    const updatedMatrics = checkboxList?.map((data, key) => {
      if (data?.name === value) {
        return { ...data, value: !data.value };
      }
      return data;
    });
    setCheckboxList([...updatedMatrics]);
  };

  const columns = [
    {
      title: "Jira ID",
      dataIndex: "destinationId",
      key: "destinationId",
      hidden: !checkboxList[0]?.value,
      sorter: (a, b) => a.destinationId.length - b.destinationId.length,
      render: (destinationId, data) => (
        <div
          //   onClick={() => {
          //     handleExpanded();
          //     handleSelectedData(data);
          //   }}
          className="py-3 px-2"
        >
          {destinationId}
          {/* {shortText(text, 4, true)} */}
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "oppDetails",
      key: "oppDetails",
      hidden: !checkboxList[1]?.value,
      sorter: (a, b) =>
        a?.oppDetails?.name?.length - b?.oppDetails?.name?.length,
      render: (oppDetails, data) => (
        <div
          //   onClick={() => {
          //     handleExpanded();
          //     handleSelectedData(data);
          //   }}
          className="py-3 px-2"
        >
          {" "}
          {oppDetails?.name}
        </div>
      ),
    },
    {
      title: "Amount ($)",
      dataIndex: "oppDetails",
      key: "oppDetails",
      hidden: !checkboxList[2]?.value,
      sorter: (a, b) =>
        a?.oppDetails?.parameters?.amount - b?.oppDetails?.parameters?.amount,
      render: (oppDetails) => (
        <div
          // onClick={handleExpanded}
          className="py-3 px-2"
        >
          {oppDetails?.parameters?.amount}
        </div>
      ),
    },
    {
      title: "Probability",
      key: "oppDetails",
      dataIndex: "oppDetails",
      hidden: !checkboxList[3]?.value,
      sorter: (a, b) =>
        a?.oppDetails?.parameters?.probability -
        b?.oppDetails?.parameters?.probability,
      render: (oppDetails) => (
        <div
          // onClick={handleExpanded}
          className="py-3 px-2"
        >
          {oppDetails?.parameters?.probability}
        </div>
      ),
    },
    {
      title: "Appends",
      key: "numberOfAppend",
      dataIndex: "numberOfAppend",
      hidden: !checkboxList[4]?.value,
      sorter: (a, b) => a?.numberOfAppend - b?.numberOfAppend,
      render: (numberOfAppend) => (
        <div
          //  onClick={handleExpanded}
          className="py-3 px-2"
        >
          {numberOfAppend}
        </div>
      ),
    },
    {
      title: "Close Date",
      key: "oppDetails",
      dataIndex: "oppDetails",
      hidden: !checkboxList[5]?.value,
      // sorter: (a, b) => a?.productScore - b?.productScore,
      render: (oppDetails) => (
        <div
          // onClick={handleExpanded}
          className="py-3 px-2"
        >
          {oppDetails?.parameters?.closeDate}
        </div>
      ),
    },
  ].filter((item) => !item?.hidden);

  const items = [
    {
      key: "1",
      label: (
        <div
          className="flex items-center text-sm pb-3 border-b-[1px] border-gray-500"
          onClick={() => window.location.reload()}
        >
          <AutorenewIcon className="!w-4 mr-2 text-gray-500 " />
          Refresh Opportunities
        </div>
      ),
    },
    // {
    //   key: "2",
    //   label: (
    //     <div className="flex items-center text-sm pb-3 ">
    //       <DownloadOutlined className="mr-2 !text-gray-500" />
    //       Export All Opportunities
    //     </div>
    //   ),
    // },
    // {
    //   key: "3",
    //   label: (
    //     <div className="flex items-center text-sm pb-3 border-b-[1px] border-gray-500">
    //       <DownloadOutlined className="mr-2 !text-gray-500" />
    //       Export Current View
    //     </div>
    //   ),
    // },
    {
      key: "4",
      label: (
        <div className="flex flex-col">
          <h5 className="text-[#979696] text-sm">Manage List Columns</h5>
          {checkboxList?.map((data, key) => (
            <Checkbox
              checked={data?.value}
              className="ot-checkbox mb-2 !ml-0"
              onChange={() => handleChangeCheckbox(data?.name)}
            >
              {data?.name}
            </Checkbox>
          ))}
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="relative opportunities-table-expanded">
        <div className="flex items-center w-full justify-between">
          <div className="flex items-center">
            <ListAltIcon className=" !text-lg !leading-0 mr-1" />{" "}
            <h5 className="text-base mb-0 capitalize">
              {watchF.reportStage} ({reportData?.length})
            </h5>
          </div>
          <Dropdown
            menu={{ items }}
            placement="bottomLeft"
            arrow
            className="z-[9999]"
          >
            <MoreVertIcon className="!text-primary cursor-pointer " />
          </Dropdown>
        </div>
        <div className="pt-3">
          <Table
            columns={columns}
            //   className={isExpanded ? "opportunitiesExpandedTable" : ""}
            dataSource={reportData}
            loading={loading}
            pagination={{
              position: ["topRight"],
              pageSize: DEFAULT_PAGE_SIZE,
              defaultCurrent: currentPage,
              simple: true,
              total: totalRecord,
              onChange: (e) => {
                handleOnTableChange(e);
              },
            }}
            // scroll={{ y: 640, x: 1000 }}
          />
        </div>
      </div>
    </>
  );
};

export default ReportsTable;
