import React, { Fragment, useEffect, useState } from "react";
import Alert from "@mui/material/Alert";
import { useSelector, useDispatch } from "react-redux";
import isEmpty from "../../store/validations/is-empty";
import types from "../../store/types";
import useNetwork from "../custom-hooks/useNetwork";
// import { useNavigate } from "react-router-dom";
// import actions from "../../store/actions";

function AlertBox() {
  const dispatch = useDispatch();
  const [alertData, setAlertData] = useState({});
  // const navigate = useNavigate();

  // const logoutHanlder = () => {
  //   dispatch(actions.logoutUser(navigate));
  // };
  // const toggleState = useSelector((state) => state.dashboard.toggleSidebar);
  const alert = useSelector((state) => state.alert);
  const networkState = useNetwork();
  const {
    online,
    // since,
    // downLink,
    // downLinkMax,
    // effectiveType,
    // rtt,
    // saveData,
    // type,
  } = networkState;

  useEffect(() => {
    if (alert.alert === true) {
      setAlertData(alert);
      window.onclick = (event) => {
        setAlertData({});
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: false,
            alertType: "",
            alertMessage: "",
          },
        });
      };
      // setTimeout(() => {
      //   setAlertData({});
      //   dispatch({
      //     type: types.SET_ALERT,
      //     payload: {
      //       alert: false,
      //       alertType: "",
      //       alertMessage: "",
      //     },
      //   });
      // }, 2000);
    }
    return () => {
      clearTimeout();
    };
  }, [alert, dispatch]);

  if (online === false) {
    return (
      <Alert severity="error" color="error">
        You are offline please check your connection
      </Alert>
    );
  }

  if (!isEmpty(alertData) && alertData.alert === true) {
    return (
      <Fragment>
        {/* <div className="alert-box-css "> */}
        <div className="w-full mt-2">
          {/* color={alertData.alertType} */}
          <Alert
            severity={alertData.alertType}
            className="w-full flex items-center"
          >
            {alertData.alertMessage}
          </Alert>
        </div>
      </Fragment>
    );
  } else {
    return null;
  }
}

export default AlertBox;
