import React from "react";

const FeatureRequestSystemActivityTab = () => {
  const systemActivity = [
    {
      name: "Clark Kent",
      updated: "Updated the OT score 30.45 45.00",
      time: "August 10, 2022 at 2:34 PM",
    },
    {
      name: "Bruce Wayne",
      updated: "Changed the status In Progress Done",
      time: "August 10, 2022 at 2:34 PM",
    },
    {
      name: "Peter Parker",
      updated: "Changed Descrption",
      time: "August 10, 2022 at 2:34 PM",
    },
  ];
  return (
    <div className="border-[1px] border-b-0 border-[#DFDFDF]">
      {systemActivity.map((data, index) => (
        <div className="flex items-center justify-between px-4 py-2 border-b-[1px] border-[#DFDFDF]">
          <div>
            <h4 className="text-xs mb-2">{data.name}</h4>
            <h4 className="text-xs mb-0">{data.updated}</h4>
          </div>
          <h4 className="text-xs mb-0">{data.time}</h4>
        </div>
      ))}
    </div>
  );
};

export default FeatureRequestSystemActivityTab;
