import types from "../../types";

const initialState = {
  alert: false,
  alertType: "success",
  alertMessage: "Default Message",
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_ALERT:
      return {
        alert: action.payload.alert,
        alertType: action.payload.alertType,
        alertMessage: action.payload.alertMessage,
      };

    default:
      return { ...state };
  }
}
