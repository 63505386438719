import types from "../../types";

const initialState = {
  accountBuckets: [],
  accountBucketsDicts: {},
};

export default function bucketReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_ACCOUNTS_BUCKETS:
      return {
        ...state,
        accountBuckets: action.payload,
      };
    case types.SET_ACCOUNTS_BUCKETS_DICT:
      return {
        ...state,
        accountBucketsDicts: action.payload,
      };
    default:
      return { ...state };
  }
}
