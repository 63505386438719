import types from "../../types";

const initialState = {
  extentionsList: [],
  integrationtoolsList: [],
  tenantIntegrationtoolsList: [],
  extentionDetails: [],
};

export default function extentionsReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_ALL_EXTENTIONS:
      return {
        ...state,
        extentionsList: action.payload,
      };

    case types.SET_ALL_INTEGRATIONLIST:
      return {
        ...state,
        integrationtoolsList: action.payload,
      };

    case types.SET_ALL_INTEGRATIONLIST_TENENT:
      return {
        ...state,
        tenantIntegrationtoolsList: action.payload,
      };

    case types.SET_ALL_EXTENTIONS_DETAILS:
      return {
        ...state,
        extentionDetails: action.payload,
      };

    default:
      return { ...state };
  }
}
