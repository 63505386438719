import React, { useEffect } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Spin } from "antd";

const StepOne = (props) => {
  const {
    metrics,
    handleOnSelectMetric,
    selectedMetrics,
    handleCustomMetrics,
    setActiveStep,
    totalWeightage,
    isLoadingMetric,
  } = props;

  return (
    <>
      <div className="flex justify-between items-center">
        <h6 className="text-base mb-0">Product Metrics available</h6>
        <button
          className="!text-primary flex justify-start items-center text-[15px]"
          onClick={handleCustomMetrics}
        >
          <AddCircleOutlineIcon className="!text-base mr-0.5" />
          Add Custom Metrics
        </button>
        <p className=" hidden">{totalWeightage}</p>
      </div>
      {isLoadingMetric && (
        <div className=" py-16 text-center">
          <Spin />
        </div>
      )}

      {!isLoadingMetric && (
        <div className="flex justify-start flex-wrap  mt-7">
          {metrics?.map((data, index) => (
            <div className="w-1/5">
              <div
                className={`${
                  selectedMetrics?.some(
                    (d) => d?.name?.toLowerCase() === data?.name?.toLowerCase()
                  )
                    ? "selectedMetrix"
                    : "unSelectedMetrix"
                } text-sm mb-4  font-normal w-44 text-center py-1 px-4 cursor-pointer break-words capitalize`}
                onClick={() => handleOnSelectMetric(data)}
              >
                {data?.name}
              </div>
            </div>
          ))}
        </div>
      )}
      <div className="flex justify-center mt-10">
        <button className="mr-3 bg-[#999999] text-[13px] text-white rounded-[3px] !px-3.5 !py-1">
          Back
        </button>
        <button
          className="ml-3 !bg-primary text-[13px] text-white rounded-[3px] !px-3.5 !py-1 disabled:opacity-50"
          disabled={selectedMetrics.length === 0}
          onClick={() => setActiveStep(2)}
        >
          Next
        </button>
      </div>
    </>
  );
};

export default StepOne;
