import { Modal } from "antd";
import { Formik } from "formik";
import { Form, Input, Select } from "formik-antd";
import React, { useState } from "react";
import { ROLES } from "../../../constant";
import { UserSchema } from "../../../store/validations/user/userSchema";

import { useDispatch, useSelector } from "react-redux";
import actions from "../../../store/actions";
import ErrorComponent from "../../common/ErrorComponent";
import GreenBtn from "../../common/greenBtn";

const AddUser = (props) => {
  const { showAddModalForm, handleAddModalForm, isEdit, selectedUser } = props;
  const [isLoading, setIsLoading] = useState(false);
  const { organisation, tenant } = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const handleSubmit = async (values) => {
    try {
      setIsLoading(true);
      const payload = {
        email: values.email,
        firstName: values.firstName,
        homePhone: values.homeNumber,
        lastName: values.lastName,
        mobileNumber: values.phoneNumber,
        organisation,
        tenant,
        password: values.password,
        // passwordHash: values.confirmedPassword,
        // photo: "string",
        // photoURL: "string",
        role: values.role.toUpperCase(),
        // tenant: "string",
      };
      let res = {};
      if (isEdit) {
        res = await dispatch(actions.updateUser(payload, selectedUser?.id));
      } else {
        res = await dispatch(actions.CreateUser(payload));
      }
      console.log(res, "res");
      if (res) {
        setIsLoading(false);
        handleAddModalForm();
      }
    } catch (error) {
      console.log(error);
      // setIsLoading(false);
    }
  };

  // TODO: add user validation messages not showing properly. showing all after touch one field.

  // const showError = (errors, touched) => {
  //   let first_key = undefined;
  //   if (!Object.keys(errors).length > 0) return;
  //   first_key = Object.keys(errors)[0];
  //   return (
  //     <div>
  //       {errors[first_key] && touched[first_key] ? errors[first_key] : null}
  //     </div>
  //   );
  // };

  // const showError = (errors, touched, id) => {
  //   if (errors && touched) {
  //     return (
  //       <div className="mt-1 text-red-600 text-xs">
  //         {errors && touched ? errors : null}
  //       </div>
  //     );
  //   }
  // };

  return (
    <Modal
      width={"70%"}
      open={showAddModalForm}
      onCancel={handleAddModalForm}
      // okText={"Submit"}
      // onOk={handleSubmit}
      footer={false}
    >
      <h2 className=" text-base mb-0 pb-2 border-b-[1px] border-[#BCCED5]">
        {isEdit ? "Edit User" : "Add User"}
      </h2>
      <div className="py-7 mx-auto pl-32 relative after:absolute after:w-[100%] after:h-[0.5px] after:bg-gray-400 after:bottom-20 after:left-0">
        <Formik
          initialValues={{
            email: selectedUser?.email,
            role: selectedUser?.role,
            firstName: selectedUser?.firstName,
            lastName: selectedUser?.lastName,
            password: selectedUser?.password,
            confirmedPassword: selectedUser?.passwordHash,
            phoneNumber: selectedUser?.mobileNumber,
            homeNumber: selectedUser?.homeNumber,
          }}
          enableReinitialize={true}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={UserSchema}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({ errors, touched }) => (
            <Form className="w-full" id="addEditUser">
              <div className="flex w-full">
                <div className="w-4/12 flex flex-col mr-5">
                  <label
                    htmlFor={`email`}
                    className="text-[13px] text-[#797979] pb-2 pt-1"
                  >
                    Email Address
                  </label>
                  <Input
                    name={`email`}
                    id={`email`}
                    placeholder="Email address"
                    className={`ot-input ${
                      errors.email ? "ot-input-error" : ""
                    }`}
                  />
                  <ErrorComponent
                    {...{ errors: errors.email, touched: touched.email }}
                  />
                </div>
                <div className="w-4/12 flex flex-col ml-5">
                  <label
                    htmlFor={`role`}
                    className="text-[13px] text-[#797979] pb-2 pt-1"
                  >
                    Role
                  </label>
                  <Select
                    options={ROLES}
                    name={`role`}
                    id={`role`}
                    placeholder="Select"
                    className={`ot-select !border-gray-400 ${
                      errors.role ? "ot-select-error" : ""
                    } `}
                  />
                  <ErrorComponent
                    {...{ errors: errors.role, touched: touched.role }}
                  />
                </div>
              </div>
              <div className="flex mt-4 w-full">
                <div className="w-4/12 flex flex-col mr-5">
                  <label
                    htmlFor={`firstName`}
                    className="text-[13px] text-[#797979] pb-2 pt-1"
                  >
                    First Name
                  </label>
                  <Input
                    name={`firstName`}
                    id={`firstName`}
                    placeholder="First Name"
                    className={`ot-input ${
                      errors.firstName ? "ot-input-error" : ""
                    }`}
                  />
                  <ErrorComponent
                    {...{
                      errors: errors.firstName,
                      touched: touched.firstName,
                    }}
                  />
                </div>
                <div className="w-4/12 flex flex-col ml-5">
                  <label
                    htmlFor={`lastName`}
                    className="text-[13px] text-[#797979] pb-2 pt-1"
                  >
                    Last Name
                  </label>
                  <Input
                    name={`lastName`}
                    id={`lastName`}
                    placeholder="Last Name"
                    className={`ot-input ${
                      errors.lastName ? "ot-input-error" : ""
                    }`}
                  />
                  <ErrorComponent
                    {...{ errors: errors.lastName, touched: touched.lastName }}
                  />
                </div>
              </div>
              <div className="flex mt-4 w-full">
                <div className="w-4/12 flex flex-col mr-5">
                  <label
                    htmlFor={`password`}
                    className="text-[13px] text-[#797979] pb-2 pt-1"
                  >
                    Password
                  </label>
                  <Input
                    name={`password`}
                    id={`password`}
                    type={"password"}
                    placeholder="password"
                    className={`ot-input ${
                      errors.password ? "ot-input-error" : ""
                    }`}
                  />
                  <ErrorComponent
                    {...{ errors: errors.password, touched: touched.password }}
                  />
                </div>
                <div className="w-4/12 flex flex-col ml-5">
                  <label
                    htmlFor={`lastName`}
                    className="text-[13px] text-[#797979] pb-2 pt-1"
                  >
                    Confirmed Password
                  </label>
                  <Input
                    name={`confirmedPassword`}
                    id={`confirmedPassword`}
                    type={"password"}
                    placeholder="Confirmed Password"
                    className={`ot-input ${
                      errors.confirmedPassword ? "ot-input-error" : ""
                    }`}
                  />
                  <ErrorComponent
                    {...{
                      errors: errors.confirmedPassword,
                      touched: touched.confirmedPassword,
                    }}
                  />
                </div>
              </div>
              <div className="flex mt-4 w-full">
                <div className="w-4/12 flex flex-col mr-5">
                  <label
                    htmlFor={`phoneNumber`}
                    className="text-[13px] text-[#797979] pb-2 pt-1"
                  >
                    Phone Number
                  </label>
                  <Input
                    name={`phoneNumber`}
                    id={`phoneNumber`}
                    type={"number"}
                    placeholder="Phone Number"
                    className={`ot-input ${
                      errors.phoneNumber ? "ot-input-error" : ""
                    }`}
                  />
                  <ErrorComponent
                    {...{
                      errors: errors.phoneNumber,
                      touched: touched.phoneNumber,
                    }}
                  />
                </div>
                <div className="w-4/12 flex flex-col ml-5">
                  <label
                    htmlFor={`homeNumber`}
                    className="text-[13px] text-[#797979] pb-2 pt-1"
                  >
                    Home Number
                  </label>
                  <Input
                    name={`homeNumber`}
                    id={`homeNumber`}
                    type="number"
                    placeholder="Home Number"
                    className={`ot-input ${
                      errors.homeNumber ? "ot-input-error" : ""
                    }`}
                  />
                  <ErrorComponent
                    {...{
                      errors: errors.homeNumber,
                      touched: touched.homeNumber,
                    }}
                  />
                </div>
              </div>
              <div className="flex justify-end items-center mt-8 pt-4">
                <button
                  onClick={handleAddModalForm}
                  className="border-gray-400 border-[1px] mr-3 rounded-sm px-2 py-1"
                >
                  Cancel
                </button>
                <GreenBtn
                  {...{
                    loading: isLoading,
                    text: "submit",
                    type: "button",
                    id: "addEditUser",
                  }}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default AddUser;
