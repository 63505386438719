import types from "../../types";

const initialState = {
  userFeatures: [],
  searchFeatures: [],
  featureDetails: [],
  appendList: [],
};

export default function featureReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_USER_FEATURE_LIST:
      return {
        ...state,
        userFeatures: action.payload,
      };

    case types.SET_SEARCH_FEATURE_LIST:
      return {
        ...state,
        searchFeatures: action.payload,
      };

    case types.SET_FEATURE_DETAILS:
      return {
        ...state,
        featureDetails: action.payload,
      };

    case types.SET_APPEND_LIST:
      return {
        ...state,
        appendList: action.payload,
      };

    default:
      return { ...state };
  }
}
