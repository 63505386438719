import axios from "axios";
import actions from "..";
import config from "../../../config";
import types from "../../types";

export const getAllUsers = (props) => {
  const {
    tenant,
    currentPage = 1,
    pageSize = 10,
    navigate,
    setIsLoading,
  } = props;
  let response = {};
  // TODO: In Future we will put these variables in a common variable file.
  // const pageNumber = 1;
  // const pageSize = 10;
  return async (dispatch) => {
    if (typeof setIsLoading === "function") {
      setIsLoading(true);
    }
    try {
      response = await axios.get(
        `${config.WEB_URL}/api/v1.0/user/tenant/${tenant}?page=${currentPage}&size=${pageSize}`
      );

      if (response.data.status === "FAILURE") {
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: true,
            alertType: "error",
            alertMessage: response.data.errorMessage,
          },
        });
        dispatch({
          type: types.SET_ALL_USERS,
          payload: [],
        });
        if (typeof setIsLoading === "function") {
          setIsLoading(false);
        }
      } else if (response.data.status === "SUCCESS") {
        dispatch({
          type: types.SET_ALL_USERS,
          payload: response.data.userDTOS,
        });
      }
      if (typeof setIsLoading === "function") {
        setIsLoading(false);
      }
    } catch (error) {
      dispatch({
        type: types.SET_ALERT,
        payload: {
          alert: true,
          alertType: "error",
          alertMessage: "Error in user action",
        },
      });
      if (typeof setIsLoading === "function") {
        setIsLoading(false);
      }
      dispatch(actions.logoutUser(navigate));
    }
    return response;
  };
};

export const CreateUser = (data) => {
  let response = {};
  return async (dispatch) => {
    try {
      response = await axios.post(`${config.WEB_URL}/api/v1.0/user`, data);

      if (response.data.status === "SUCCESS")
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: true,
            alertType: "success",
            alertMessage: "User Created Successfully.",
          },
        });
      else
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: true,
            alertType: "error",
            alertMessage: response.data.errorMessage,
          },
        });
    } catch (error) {
      dispatch({
        type: types.SET_ALERT,
        payload: {
          alert: true,
          alertType: "error",
          alertMessage: "Error in create user action",
        },
      });
    }
    return response;
  };
};

export const updateUser = (data, id) => {
  let response = {};
  return async (dispatch) => {
    try {
      response = await axios.put(`${config.WEB_URL}/api/v1.0/user/${id}`, data);

      if (response.data.status === "SUCCESS")
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: true,
            alertType: "success",
            alertMessage: "User updated Successfully.",
          },
        });
      else
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: true,
            alertType: "error",
            alertMessage: response.data.errorMessage,
          },
        });
    } catch (error) {
      dispatch({
        type: types.SET_ALERT,
        payload: {
          alert: true,
          alertType: "error",
          alertMessage: "Error in update user action",
        },
      });
    }
    return response;
  };
};

export const getActiveUser = (id, navigate) => {
  // const { payload } = { props };
  let response = {};
  return async (dispatch) => {
    try {
      response = await axios.get(
        `${config.WEB_URL}/api/v1.0/user/activate/user/${id}`
      );
      if (response.status === 200)
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: true,
            alertType: "success",
            alertMessage: "User Activate Successfully",
          },
        });
      else
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: true,
            alertType: "error",
            alertMessage: response.data.errorMessage,
          },
        });
    } catch (error) {
      dispatch({
        type: types.SET_ALERT,
        payload: {
          alert: true,
          alertType: "error",
          alertMessage: "Error in active user action",
        },
      });
    }
    return response;
  };
};
