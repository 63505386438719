import React, { useState } from "react";
import { Select, Slider } from "antd";
import { Controller, useFieldArray } from "react-hook-form";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { AMOUNT_OPTION, PRIORITY_OPTIONS } from "../../common/constant";
import DisabledByDefaultOutlinedIcon from "@mui/icons-material/DisabledByDefaultOutlined";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const NestedArray = (props) => {
  const {
    nestIndex,
    control,
    register,
    activeIndex,
    watch,
    handleStep,
    initialValues,
    handleBackStep,
    resSelectedIndex,
    loading,
  } = props;
  const { Option } = Select;
  const antIcon = (
    <LoadingOutlined
      style={{ fontSize: 14, color: "white", marginRight: 8 }}
      spin
    />
  );

  const { fields, remove, prepend } = useFieldArray({
    control,
    name: `metrics[${nestIndex}].metricsList`,
  });

  const [isAdded, setIsAdded] = useState(false);
  const [isEdited, setIsEdited] = useState(0);

  const watchFileds = watch();

  return (
    <div className="pt-3">
      {watchFileds?.metrics[activeIndex]?.type?.toLowerCase() !==
        "probability" &&
        watchFileds?.metrics[activeIndex]?.type?.toLowerCase() !== "date" && (
          <div>
            {fields.map((item, index) => {
              return (
                <div key={item.id}>
                  <div className="flex w-full mb-5 justify-between">
                    <div className="flex">
                      <div className="flex flex-col pr-5">
                        <label
                          htmlFor={`metrics[${nestIndex}].metricsList[${index}].condition`}
                          className="text-[13px] text-[#797979] pb-3"
                        >
                          {item?.type?.toLowerCase() !== "currency"
                            ? item?.type
                            : "If deal Size is"}
                        </label>
                        {item?.type?.toLowerCase() !== "customer" && (
                          <>
                            <Controller
                              control={control}
                              name={`metrics[${nestIndex}].metricsList[${index}].condition`}
                              render={({ field }) => (
                                <>
                                  <Select
                                    placeholder="Select"
                                    {...field}
                                    disabled={
                                      (resSelectedIndex !== null && !isAdded) ||
                                      index !== isEdited ||
                                      activeIndex < nestIndex
                                    }
                                    className={`${
                                      index === isEdited &&
                                      (resSelectedIndex === null || isAdded)
                                        ? "ot-select border-[#797979]"
                                        : "ot-transparent"
                                    }  !w-44 !mr-7`}
                                  >
                                    {item?.type?.toLowerCase() === "currency" &&
                                      AMOUNT_OPTION.map((data) => (
                                        <Option value={data.value}>
                                          {data.label}
                                        </Option>
                                      ))}
                                    {item?.type?.toLowerCase() ===
                                      "probability" &&
                                      PRIORITY_OPTIONS.map((data) => (
                                        <Option value={data.value}>
                                          {data.label}
                                        </Option>
                                      ))}
                                    {item?.type?.toLowerCase() === "priority" &&
                                      PRIORITY_OPTIONS.map((data) => (
                                        <Option value={data.value}>
                                          {data.label}
                                        </Option>
                                      ))}
                                  </Select>
                                </>
                              )}
                            />
                          </>
                        )}
                        {item?.type?.toLowerCase() === "customer" && (
                          <input
                            {...register(
                              `metrics[${nestIndex}].metricsList[${index}].condition`
                            )}
                            readOnly={
                              index !== isEdited ||
                              (resSelectedIndex !== null && !isAdded)
                            }
                            placeholder="Enter Customer"
                            className={`ot-input text-sm !w-44 !mr-7 ${
                              index !== isEdited ||
                              (resSelectedIndex !== null && !isAdded)
                                ? "border-none !px-0 !text-primary"
                                : "px-3 py-0.5"
                            }`}
                            name={`metrics[${nestIndex}].metricsList[${index}].condition`}
                            defaultValue={item.toValue}
                          />
                        )}
                      </div>
                      {item?.type?.toLowerCase() === "currency" && (
                        <>
                          <div className="flex flex-col mr-14">
                            <label
                              htmlFor={`metrics[${nestIndex}].metricsList[${index}].toValue`}
                              className="text-[13px] text-[#797979] pb-3 pt-1"
                            >
                              Min
                            </label>
                            <input
                              {...register(
                                `metrics[${nestIndex}].metricsList[${index}].toValue`
                              )}
                              name={`metrics[${nestIndex}].metricsList[${index}].toValue`}
                              id={`metrics[${nestIndex}].metricsList[${index}].toValue`}
                              placeholder="0"
                              className="!border-b-2 !border-l-0 !border-t-0 !pl-1 !border-r-0 !border-gray-400 focus:!border-b-2 focus:!border-gray-400 !w-16 disabled:!border-0 disabled:!bg-transparent disabled:!text-primary"
                              disabled={
                                index !== isEdited ||
                                (resSelectedIndex !== null && !isAdded)
                              }
                            />
                          </div>
                          <div className="flex flex-col mr-14">
                            <label
                              htmlFor={`metrics[${nestIndex}].metricsList[${index}].fromValue`}
                              className="text-[13px] text-[#797979] pb-3 pt-1"
                            >
                              Max
                            </label>
                            <input
                              {...register(
                                `metrics[${nestIndex}].metricsList[${index}].fromValue`
                              )}
                              name={`metrics[${nestIndex}].metricsList[${index}].fromValue`}
                              id={`metrics[${nestIndex}].metricsList[${index}].fromValue`}
                              placeholder="0"
                              className="!border-b-2 !border-l-0 !border-t-0 !pl-1 !border-r-0 focus:!border-gray-400 !border-gray-400 !w-16 disabled:!border-0 disabled:!bg-transparent disabled:!text-primary focus:!border-b-2"
                              disabled={
                                index !== isEdited ||
                                (resSelectedIndex !== null && !isAdded)
                              }
                            />
                          </div>
                        </>
                      )}
                      <div className="pr-5">
                        <label
                          htmlFor={`metrics[${nestIndex}].metricsList[${index}].percentage`}
                          className="text-[13px] text-[#797979] pb-2"
                        >
                          then percentage %
                        </label>
                        <div className="flex justify-start items-center">
                          {index === isEdited &&
                          (isAdded || resSelectedIndex === null) ? (
                            <Controller
                              control={control}
                              name={`metrics[${nestIndex}].metricsList[${index}].percentage`}
                              defaultValue={50}
                              render={({ field }) => (
                                <Slider {...field} step={10} />
                              )}
                            />
                          ) : (
                            ""
                          )}
                          <span
                            className={` text-[13px]  ml-4 ${
                              index !== isEdited || !isAdded
                                ? "!text-primary pt-2 px-3"
                                : "underline underline-offset-8 text-[#151515]"
                            }`}
                          >
                            {
                              watchFileds?.metrics[nestIndex]?.metricsList[
                                index
                              ]?.percentage
                            }
                            %
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-end pt-3">
                      {index === isEdited && (
                        <div className="flex items-center">
                          {
                            <button
                              type="button"
                              disabled={fields?.length <= 1}
                              className="mr-3 bg-[#999999] text-[13px] text-white rounded-[3px] w-[70px] !py-1 disabled:opacity-50"
                              onClick={() => {
                                remove(index);
                                setIsAdded(false);
                              }}
                            >
                              Cancel
                            </button>
                          }
                          <button
                            type="submit"
                            className="text-[13px] !bg-primary disabled:opacity-50 text-white rounded-[3px] py-1 w-[70px] shadow-[0px,3px,6px,0px,rgba(0,0,0,0.15); ]"
                          >
                            Save
                          </button>
                        </div>
                      )}
                      {index !== isEdited && (
                        <div className=" flex items-center">
                          <button
                            className="p-0 mr-6"
                            onClick={() => setIsEdited(index)}
                          >
                            <EditOutlinedIcon className="!w-5 text-[#999999] " />
                          </button>{" "}
                          <button
                            onClick={() => {
                              remove(index);
                              setIsAdded(false);
                            }}
                          >
                            <DisabledByDefaultOutlinedIcon className="!w-5 text-[#999999] cursor-pointer" />
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  <button
                    type="button"
                    disabled={
                      watchFileds?.metrics[activeIndex]?.type !== "Date" &&
                      (watchFileds?.metrics[nestIndex]?.metricsList[0]
                        .condition === null ||
                        watchFileds?.metrics[nestIndex]?.metricsList[0]
                          .percentage === 0)
                    }
                    className="text-[13px] !bg-primary text-white rounded-[3px] py-0.5 px-2 absolute top-0 right-2 disabled:opacity-50"
                    onClick={() => {
                      setIsAdded(true);
                      setIsEdited(0);
                      prepend({
                        fromValue: "",
                        toValue: "",
                        percentage: 0,
                        condition: null,
                        type: item.type,
                      });
                    }}
                  >
                    + Add
                  </button>
                </div>
              );
            })}
            <hr />
          </div>
        )}
      {watchFileds?.metrics[activeIndex]?.type?.toLowerCase() === "date" && (
        <div className=" w-96 pt-3">
          <p className="text-[13px]">
            This bucket for date is already predefined by the system.Each date
            towards the closing date of the oppurtunity is taken into account
            for the weightage calculations.
          </p>
        </div>
      )}
      {watchFileds.metrics[activeIndex].type.toLowerCase() ===
        "probability" && (
        <div className=" w-96 pt-3">
          <p className="text-[13px]">
            This bucket for probability is already predefined by the system.Each
            probability towards the oppurtunity is taken into account for the
            weightage calculations.
          </p>
        </div>
      )}
      <div className="flex justify-center mt-10">
        <button
          className="mr-3 bg-[#999999] text-[13px] text-white rounded-[3px] !px-3.5 !py-1"
          onClick={handleBackStep}
        >
          Back
        </button>
        <button
          type="submit"
          htmlFor="bucket"
          className="ml-3 !bg-primary text-[13px] text-white rounded-[3px] !px-3.5 !py-1 disabled:opacity-50"
          // disabled={
          //   watchFileds?.metrics[activeIndex]?.type?.toLowerCase() !== "date" &&
          //   watchFileds?.metrics[activeIndex]?.type?.toLowerCase() !==
          //     "probability" &&
          //   (watchFileds?.metrics[nestIndex]?.metricsList[0]?.condition ===
          //     null ||
          //     watchFileds?.metrics[nestIndex]?.metricsList[0]?.percentage === 0)
          // }
          onClick={handleStep}
        >
          {loading && <Spin indicator={antIcon} />}
          {activeIndex === initialValues?.length - 1 ? "Finish" : "Next"}
          {/* {activeMetrics === bucketListByMetrics?.length ? "Finish" : " Next"} */}
        </button>
      </div>
    </div>
  );
};

export default NestedArray;
