import { Tooltip } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import actions from "../../store/actions";

function TopMenuBar(props) {
  const { title } = props.title;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toggleState = useSelector((state) => state.dashboard.toggleSidebar);

  const toggleHandler = () => {
    dispatch(actions.toggleAction(!toggleState));
  };

  const logoutHanlder = () => {
    dispatch(actions.logoutUser(navigate));
  };

  return (
    <div className="bg-white w-full">
      <div
        className={`shadow-sm relative z-10 ${
          !toggleState ? "top-navbar-container" : " top-navbar-container-extend"
        }`}
      >
        <div className="left-section">
          <div className="menu-icon pointer" onClick={toggleHandler}>
            <span className="material-icons ">dehaze</span>
          </div>

          <p className="w-full whitespace-nowrap">{title}</p>
        </div>
        <div className="right-section">
          <span className="material-icons">circle_notifications</span>
          <Tooltip title={"Logout"} trigger={"click"}>
            <span
              onClick={logoutHanlder}
              className="material-icons cursor-pointer"
            >
              account_circle
            </span>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}

export default TopMenuBar;
