import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useEffect, useState } from "react";
// import StarBorderIcon from "@mui/icons-material/StarBorder";
// import StarIcon from "@mui/icons-material/Star";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { roles } from "../../../constant";
import isEmpty from "../../../store/validations/is-empty";
const RecentUsersTableComponent = () => {
  const [tableData, setTableData] = useState([]);

  const { users } = useSelector((state) => state.dashboard);

  useEffect(() => {
    if (!isEmpty(users)) {
      let finalData = [];
      users.map((data) => {
        return finalData.push({
          userId: data.id,
          firstName: data.firstName,
          lastName: data.lastName,
          emailId: data.email,
          role: data.role,
          status: data.status,
          dateAdded: data.createdDate,
        });
      });
      setTableData(finalData);
    } else {
      setTableData([]);
    }
  }, [users]);

  // const rows = [
  //   {
  //     userId: "TCS",
  //     firstName: "$123k",
  //     lastName: "Change in form",
  //     emailId: "akshay@gmail.com",
  //     role: 50.12,
  //     status: 2,
  //     dateAdded: "12 Nov 2022",
  //   },
  //   {
  //     userId: "WIPRO",
  //     firstName: "$56k",
  //     lastName: "Add 50 orders",
  //     emailId: "akshay@gmail.com",
  //     role: 34.65,
  //     status: 1,
  //     dateAdded: "18 Nov 2022",
  //   },
  //   {
  //     userId: "Amazon",
  //     firstName: "$25k",
  //     lastName: "Change unit capacity",
  //     emailId: "akshay@gmail.com",
  //     role: 87.45,
  //     status: 3,
  //     dateAdded: "18 Nov 2022",
  //     state: "Closed Won",
  //   },
  //   {
  //     userId: "ola",
  //     firstName: "$456K",
  //     lastName: "Delete Items",
  //     emailId: "akshay@gmail.com",
  //     role: 54.12,
  //     status: 3,
  //     dateAdded: "18 Nov 2022",
  //   },
  //   {
  //     userId: "Accenture",
  //     firstName: "$545k",
  //     lastName: "Remove addons",
  //     emailId: "akshay@gmail.com",
  //     role: 45.78,
  //     status: 1,
  //     dateAdded: "18 Nov 2022",
  //   },
  // ];

  // const starArr = [1, 2, 3];
  return (
    <TableContainer
      component={Paper}
      className="mt-2 border-t-4 !border-primary !rounded-none"
    >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {/* <TableCell>User ID</TableCell> */}
            <TableCell>First Name</TableCell>
            <TableCell>Last Name</TableCell>
            <TableCell>Email ID</TableCell>
            <TableCell>Role</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Date Added</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!isEmpty(tableData) &&
            tableData.map((row, index) => {
              return (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {/* <TableCell component="th" scope="row">
                    {shortText(row.userId, 5, true)}
                  </TableCell> */}
                  <TableCell>{row.firstName}</TableCell>
                  <TableCell className=" whitespace-nowrap text-ellipsis overflow-hidden w-40">
                    {row.lastName}
                  </TableCell>
                  <TableCell>{row.emailId}</TableCell>
                  <TableCell>{roles[row.role]}</TableCell>

                  <TableCell>{row.status ? "Active" : "Inactive"}</TableCell>
                  <TableCell>
                    {format(new Date(row.dateAdded), "do MMM yyyy")}
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default RecentUsersTableComponent;
