import * as auth from "./auth";
import * as dashboard from "./dashboard";
import * as dashboardAdmin from "./dashboard/dashboardAdminAction";
import * as opportunities from "./opportunities";
import * as users from "./users";
import * as buckets from "./buckets";
import * as accounts from "./accounts";
import * as jira from "./jira";
import * as features from "./features";
import * as metrics from "./metrics";
import * as productMetrics from "./productMetrics";
import * as extentions from "./extenstions";
import * as reports from "./reports";
const actions = {
  ...auth,
  ...dashboard,
  ...dashboardAdmin,
  ...opportunities,
  ...users,
  ...buckets,
  ...accounts,
  ...jira,
  ...features,
  ...metrics,
  ...productMetrics,
  ...extentions,
  ...reports,
};

export default actions;
