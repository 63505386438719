import axios from "axios";
import actions from "..";
import config from "../../../config";
import types from "../../types";

/*==============================================================
                GET All Features
===============================================================*/

export const getFeatureByUser = (props) => {
  const { pageNumber, pageSize, navigate, setFeatureLoading, setTotalCount } =
    props;

  let response = {};
  return async (dispatch) => {
    try {
      setFeatureLoading(true);
      response = await axios.get(
        `${config.WEB_URL}/api/v1.0/jira/project/issue/byuser?page=${pageNumber}&size=${pageSize}`
      );
      if (response.status === 200 && response.data.status === "SUCCESS") {
        setFeatureLoading(false);
        setTotalCount(response.data.totalsize);
        dispatch({
          type: types.SET_USER_FEATURE_LIST,
          payload: response.data.featurelist,
        });
      } else {
        setFeatureLoading(false);
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: true,
            alertType: "error",
            alertMessage:
              response.data.errorMessage ||
              "Not Able to Fetch the Feature Details",
          },
        });
        dispatch({
          type: types.SET_USER_FEATURE_LIST,
          payload: [],
        });
      }
    } catch (error) {
      setFeatureLoading(false);
      dispatch({
        type: types.SET_ALERT,
        payload: {
          alert: true,
          alertType: "error",
          alertMessage: "Not Able to Fetch the Jira Issue",
        },
      });
      dispatch(actions.logoutUser(navigate));
    }
    return response;
  };
};

export const searchFeature = (props) => {
  const { title } = props;
  let response = {};
  return async (dispatch) => {
    try {
      response = await axios.get(
        `${config.WEB_URL}/api/v1.0/jira/project/searchissue/${title}`
      );
      if (response.status === 200) {
        dispatch({
          type: types.SET_SEARCH_FEATURE_LIST,
          payload: response.data,
        });
      } else {
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: true,
            alertType: "error",
            alertMessage:
              response.data.errorMessage ||
              "Not Able to Fetch the Feature Details",
          },
        });
        dispatch({
          type: types.SET_SEARCH_FEATURE_LIST,
          payload: [],
        });
      }
    } catch (error) {
      // {
      //   navigate && dispatch(actions.logoutUser(navigate));
      // }

      dispatch({
        type: types.SET_ALERT,
        payload: {
          alert: true,
          alertType: "error",
          alertMessage: "Not Able to Fetch the Jira Issue",
        },
      });
    }
    return response;
  };
};

//=================================================
//         appendFR
//==================================================
export const updateAppendFR = (props) => {
  const { payload, jiraId } = props;
  let response = {};
  return async (dispatch) => {
    try {
      response = await axios.put(
        `${config.WEB_URL}/api/v1.0/jira/project/append/issue/${jiraId}`,
        payload
      );

      if (response.status === 200)
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: true,
            alertType: "success",
            alertMessage: "Append Successfully.",
          },
        });
      else
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: true,
            alertType: "error",
            alertMessage: response.data.errorMessage,
          },
        });
    } catch (error) {
      dispatch({
        type: types.SET_ALERT,
        payload: {
          alert: true,
          alertType: "error",
          alertMessage: "Error in update append action",
        },
      });
    }
    return response;
  };
};

export const getFeatureDetails = (id, navigate, setLoading) => {
  let response = {};
  return async (dispatch) => {
    try {
      setLoading(true);
      response = await axios.get(
        `${config.WEB_URL}/api/v1.0/jira/project/featureid/${id}`
      );

      if (response.data.status === "FAILURE") {
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: true,
            alertType: "error",
            alertMessage: response.data.errorMessage,
          },
        });
        setLoading(false);
        dispatch({
          type: types.SET_FEATURE_DETAILS,
          payload: [],
        });
      } else if (response.status === 200) {
        setLoading(false);
        dispatch({
          type: types.SET_FEATURE_DETAILS,
          payload: response.data,
        });
      }
    } catch (error) {
      setLoading(false);
      dispatch({
        type: types.SET_ALERT,
        payload: {
          alert: true,
          alertType: "error",
          alertMessage: "Error in opportunity notes",
        },
      });
      dispatch(actions.logoutUser(navigate));
    }
    return response;
  };
};

/*====================================================
                    Create bucket
====================================================*/
export const addComment = (data) => {
  let response = {};
  return async (dispatch) => {
    try {
      response = await axios.post(
        `${config.WEB_URL}/api/v1.0/jira/project/addcomments/`,
        data
      );

      if (response.status === 200)
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: true,
            alertType: "success",
            alertMessage: "Comment Added Successfully.",
          },
        });
      else
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: true,
            alertType: "error",
            alertMessage: response.data.errorMessage,
          },
        });
    } catch (error) {
      dispatch({
        type: types.SET_ALERT,
        payload: {
          alert: true,
          alertType: "error",
          alertMessage: "Error in add comment action",
        },
      });
    }
    return response;
  };
};
/*==============================================================
                GET All Append List By id
===============================================================*/

export const getAppendedListById = (props) => {
  const { navigate, setIsLoading, id } = props;
  let response = {};
  return async (dispatch) => {
    try {
      setIsLoading(true);
      response = await axios.get(
        `${config.WEB_URL}/api/v1.0/jira/project/getappendbyjiraid/${id}`
      );
      if (response.status === 200) {
        setIsLoading(false);
        dispatch({
          type: types.SET_APPEND_LIST,
          payload: response.data,
        });
      } else {
        setIsLoading(false);
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: true,
            alertType: "error",
            alertMessage:
              response.data.errorMessage ||
              "Not Able to Fetch the Feature Details",
          },
        });
        dispatch({
          type: types.SET_APPEND_LIST,
          payload: [],
        });
      }
    } catch (error) {
      setIsLoading(false);
      dispatch({
        type: types.SET_ALERT,
        payload: {
          alert: true,
          alertType: "error",
          alertMessage: "Not Able to Fetch the Jira Issue",
        },
      });
      dispatch(actions.logoutUser(navigate));
    }
    return response;
  };
};

/*====================================================
                    delete connection
====================================================*/
export const deleteAppend = (id) => {
  // const { payload } = { props };
  let response = {};
  return async (dispatch) => {
    try {
      response = await axios.delete(
        `${config.WEB_URL}/api/v1.0/jira/project/feature/${id}`
      );

      if (response.data.status === "SUCCESS")
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: true,
            alertType: "success",
            alertMessage: "Append Deleted Successfully.",
          },
        });
      else
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: true,
            alertType: "error",
            alertMessage: response.data.errorMessage,
          },
        });
    } catch (error) {
      dispatch({
        type: types.SET_ALERT,
        payload: {
          alert: true,
          alertType: "error",
          alertMessage: "Error in delete append action",
        },
      });
    }
    return response;
  };
};
/*===================================================================
                    SUBMIT FEATURE REQUEST
=====================================================================*/
export const submitFeatureRequest = (data) => {
  let response = {};
  return async (dispatch) => {
    try {
      response = await axios.post(
        `${config.WEB_URL}/api/v1.0/jira/project/submit/approve/`,
        data
      );

      if (response.status === 200)
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: true,
            alertType: "success",
            alertMessage: "Request submitted Successfully.",
          },
        });
      else
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: true,
            alertType: "error",
            alertMessage: response.data.errorMessage,
          },
        });
    } catch (error) {
      dispatch({
        type: types.SET_ALERT,
        payload: {
          alert: true,
          alertType: "error",
          alertMessage: "Error in submit feature action",
        },
      });
    }
    return response;
  };
};
