import React from "react";
import TopMenuBar from "../common/TopMenuBar";
import AlertBox from "../common/Alert";
// import WelcomeMessageBox from "../common/WelcomeMessageBox";

function Header(title) {
  return (
    <div className="Header-section bg-white">
      <TopMenuBar title={title} />
      {/* <WelcomeMessageBox /> */}
      <AlertBox />
    </div>
  );
}

export default Header;
