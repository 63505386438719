import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: false,
    },
  },
};

const labels = ["Jan", "Feb", "Mar", "Apr", "Jun", "Jul", "Aug"];

export const data = {
  labels,
  datasets: [
    {
      label: "Requets",
      data: [20, 40, 80, 85, 90, 100],
      borderColor: " #69BE28",
      backgroundColor: " #69BE28",
    },
    {
      label: "Won",
      data: [80, 110, 70, 85, 90, 80],
      borderColor: " #69BE28",
      backgroundColor: " #69BE28",
    },
  ],
};

const DashboardYourPerformance = () => {
  return (
    <div className="dashboard-performance-section dashboard-performance-section-line">
      <Line options={options} data={data} width={"100%"} height={"69%"} />
    </div>
  );
};

export default DashboardYourPerformance;
