import React, { Fragment } from "react";
import VerticalBarChart from "../../graphs/VerticalBarChart";

function AllRequestStatusVerticalGraph() {
  return (
    <Fragment>
      <div className="all-request-status-graph-container">
        <div className="heading-section">
          <div className="section-left">
            <span className="material-icons-round">window</span>
            <h1 className="font-15-regular">All Requests Status</h1>
          </div>
          <div className="section-right">
            <p>View all </p>
            <span className="material-icons">keyboard_arrow_up</span>
          </div>
        </div>
        <div className="graph-container">
          <VerticalBarChart />
        </div>
      </div>
    </Fragment>
  );
}

export default AllRequestStatusVerticalGraph;
