import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
// import { history } from "_helpers";
import isEmpty from "../validations/is-empty";

export { PrivateRouteProductAdmin };

function PrivateRouteProductAdmin({ children }) {
  const user = useSelector((state) => state.auth.user);
  const { isAuthenticated } = useSelector((state) => state.auth);

  if (!isEmpty(user) && user.roles[0] !== "PRODUCT_ADMIN") {
    // not logged in so redirect to login page with the return url
    return <Navigate to="/not-found" />;
  } else if (!isAuthenticated) {
    return <Navigate to="/not-found" />;
  }

  // authorized so return child components
  return children;
}
