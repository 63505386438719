import types from "../../types";

const initialState = {
  jiraProjects: [],
  jiraIssueType: [],
};

export default function jiraReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_JIRA_PROJECT_LIST:
      return {
        ...state,
        jiraProjects: action.payload,
      };
    case types.SET_JIRA_ISSUE_TYPE_LIST:
      return {
        ...state,
        jiraIssueType: action.payload,
      };
    default:
      return { ...state };
  }
}
