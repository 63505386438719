export const roles = {
  ADMIN: "Admin",
  SALES_ADMIN: "Sales Admin",
  PRODUCT_ADMIN: "Product Admin",
};

export const ROLES = [
  {
    label: "Admin",
    value: "Admin",
  },
  {
    label: "Product Admin",
    value: "Product Admin",
  },
  {
    label: "Sales Admin",
    value: "Sales Admin",
  },
  {
    label: "User",
    value: "User",
  },
];

export const METRICDATATYPE = {
  id: "customer",
  isdeleted: "boolean",
  accountid: "customer",
  isprivate: "boolean",
  name: "customer",
  description: "textarea",
  stagename: "customer",
  amount: "currency",
  probability: "probability",
  expectedrevenue: "currency",
  totalopportunityquantity: "currency",
  closedate: "date",
  type: "customer",
  nextstep: "customer",
  leadSource: "customer",
  isclosed: "boolean",
  isWon: "boolean",
  forecastcategoryname: "customer",
  campaignid: "customer",
  hasopportunityLineitem: "boolean",
  pricebook2id: "customer",
  ownerid: "customer",
  createddate: "date",
  createdByid: "customer",
  lastmodifieddate: "date",
  lastmodifiedbyid: "customer",
  systemmodstamp: "date",
  lastactivitydate: "date",
  fiscalquarter: "int", //int
  fiscalyear: "int", //int
  fiscal: "customer",
  lastvieweddate: "date",
  lastreferenceddate: "date",
  deliveryinstallationstatus__c: "customer", //picklist
  trackingnumber__c: "customer",
  ordernumber__c: "customer",
  currentgenerators__c: "customer",
  maincompetitors__c: "customer",
  priority__c: "priority",
};
