import React, { Fragment } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { YEARLYDATA } from "../common/constant";
import Box from "./common/Box";

const MoneyStatusBox = (props) => {
  const { featureSummary, reportedSummary } = props;
  return (
    <Fragment>
      <div className="status-box-container relative">
        <div className="date-section">
          <span className="prev-next-text"> &lt; Prev </span>{" "}
          <span className="date-text">2021-2022</span>{" "}
          <span className="prev-next-text"> Next &gt; </span>
        </div>
        <Tabs>
          <TabList>
            <Tab>Yearly</Tab>
            <Tab>Quaterly</Tab>
            <Tab>Monthly</Tab>
          </TabList>

          <TabPanel>
            {featureSummary?.map((data, key) => (
              <Box key={key} {...{ data }} />
            ))}
            {reportedSummary?.map((data, key) => (
              <Box key={key} {...{ data }} />
            ))}
            {/* {YEARLYDATA.map((data, key) => ( */}
            {/* // <Box key={key} {...{ data }} /> */}
            {/* ))} */}
          </TabPanel>
          <TabPanel>
            {YEARLYDATA.map((data, key) => (
              <Box key={key} {...{ data }} />
            ))}
          </TabPanel>
          <TabPanel>
            {YEARLYDATA.map((data, key) => (
              <Box key={key} {...{ data }} />
            ))}
          </TabPanel>
        </Tabs>
      </div>
    </Fragment>
  );
};

export default MoneyStatusBox;
