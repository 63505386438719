import React from "react";
import { Checkbox, Dropdown, Table } from "antd";
import InventoryIcon from "@mui/icons-material/Inventory";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import GreenBtn from "../common/greenBtn";
import { useState } from "react";
import AutorenewIcon from "@mui/icons-material/Autorenew";

const FeatureRequestTable = (props) => {
  const {
    handleExpanded,
    isExpanded,
    userFeatures,
    featureLoading,
    handleSelectedData,
    handleShowAppendFr,
    totalCount,
    handleOnTableChange,
    currentPage,
  } = props;

  const [checkboxList, setCheckboxList] = useState([
    { name: "FRID", value: true },
    { name: "Jira Id", value: true },
    { name: "Name", value: true },
    { name: "Appends", value: true },
    { name: "OT Score", value: true },
    { name: "Scales Score", value: true },
    { name: "Product Score", value: true },
    { name: "Jira Status", value: true },
    { name: "Created By", value: true },
  ]);
  const columns = [
    {
      title: "FR ID",
      dataIndex: "customFeatureId",
      key: "customFeatureId",
      hidden: !checkboxList[0]?.value,
      fixed: "left",
      sorter: (a, b) => a.customFeatureId.length - b.customFeatureId.length,
      render: (customFeatureId, data) => (
        <div
          onClick={() => {
            handleExpanded();
            handleSelectedData(data);
          }}
          className="py-3 px-2 cursor-pointer"
        >
          {customFeatureId ?? "-"}
          {/* {shortText(text, 4, true)} */}
        </div>
      ),
    },
    {
      title: "Jira ID",
      dataIndex: "destinationId",
      key: "destinationId",
      hidden: !checkboxList[1]?.value,
      sorter: (a, b) => a.destinationId.length - b.destinationId.length,
      render: (destinationId, data) => (
        <div
          onClick={() => {
            handleExpanded();
            handleSelectedData(data);
          }}
          className="py-3 px-2 !text-primary cursor-pointer"
        >
          <a
            href={`https://otdevt1.atlassian.net/browse/${destinationId}`}
            target="_blank"
            rel="noreferrer"
          >
            {destinationId}
          </a>
          {/* {shortText(text, 4, true)} */}
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "jiraDetails",
      key: "jiraDetails",
      hidden: !checkboxList[2]?.value,
      sorter: (a, b) =>
        a?.jiraDetails?.issueName?.length - b?.jiraDetails?.issueName?.length,
      render: (jiraDetails, data) => (
        <div
          onClick={() => {
            handleExpanded();
            handleSelectedData(data);
          }}
          className="py-3 px-2 cursor-pointer"
        >
          {" "}
          {jiraDetails?.issueName}
        </div>
      ),
    },
    {
      title: "Appends",
      dataIndex: "numberOfAppend",
      key: "numberOfAppend",
      hidden: !checkboxList[3]?.value,
      sorter: (a, b) => a?.numberOfAppend - b?.numberOfAppend,
      render: (numberOfAppend, data) => (
        <div
          onClick={() => {
            handleShowAppendFr();
            handleSelectedData(data);
          }}
          className="py-3 px-2 !text-primary cursor-pointer"
        >
          {numberOfAppend}
        </div>
      ),
    },
    {
      title: "OT SCore",
      key: "score",
      hidden: !checkboxList[4]?.value,
      dataIndex: "score",
      sorter: (a, b) => a?.score - b?.score,
      render: (score, data) => (
        <div onClick={handleExpanded} className="py-3 px-2 cursor-pointer">
          {score}
        </div>
      ),
    },
    {
      title: "Scales Score",
      key: "salesScore",
      dataIndex: "salesScore",
      hidden: !checkboxList[5]?.value,

      sorter: (a, b) => a?.salesScore - b?.salesScore,
      render: (salesScore) => (
        <div onClick={handleExpanded} className="py-3 px-2 cursor-pointer">
          {salesScore}
        </div>
      ),
    },
    {
      title: "Product Score",
      key: "productScore",
      dataIndex: "productScore",
      hidden: !checkboxList[6]?.value,

      sorter: (a, b) => a?.productScore - b?.productScore,
      render: (productScore) => (
        <div onClick={handleExpanded} className="py-3 px-2 cursor-pointer">
          {productScore}
        </div>
      ),
    },
    {
      title: "Jira Status",
      key: "jiraStatus",
      dataIndex: "jiraStatus",
      hidden: !checkboxList[7]?.value,

      sorter: (a, b) => a?.jiraStatus?.length - a?.jiraStatus?.length,
      render: (jiraStatus) => (
        <div className="py-3 px-2">
          {jiraStatus?.toLowerCase() === "done" ? (
            <GreenBtn
              {...{
                text: jiraStatus,
                extrClass: "w-14 !cursor-auto",
              }}
            />
          ) : jiraStatus?.toLowerCase() === "to do" ? (
            <GreenBtn
              {...{
                text: jiraStatus,
                extrClass: "!bg-[#EB901C] w-14  !cursor-auto",
              }}
            />
          ) : (
            <GreenBtn
              {...{
                text: jiraStatus,
                extrClass: "!bg-[#2BB8FF] min-w-14 mx-auto !cursor-auto",
              }}
            />
          )}
        </div>
      ),
    },
    {
      title: "Created By",
      key: "createdBy",
      dataIndex: "createdBy",
      hidden: !checkboxList[8]?.value,

      sorter: (a, b) => a?.createdBy?.length - a?.createdBy?.length,
      render: (createdBy, data) => (
        <div
          className="py-3 px-2 cursor-pointer"
          onClick={() => handleSelectedData(data)}
        >
          {" "}
          {createdBy}
        </div>
      ),
    },
  ].filter((item) => !item?.hidden);

  const handleChangeCheckbox = (value) => {
    const updatedMatrics = checkboxList?.map((data, key) => {
      if (data?.name === value) {
        return { ...data, value: !data.value };
      }
      return data;
    });
    setCheckboxList([...updatedMatrics]);
  };

  const items = [
    {
      key: "1",
      label: (
        <div
          className="flex items-center text-sm pb-3 border-b-[1px] border-gray-500"
          onClick={() => window.location.reload()}
        >
          <AutorenewIcon className="!w-4 mr-2 text-gray-500 " />
          Refresh Opportunities
        </div>
      ),
    },
    // {
    //   key: "2",
    //   label: (
    //     <div className="flex items-center text-sm pb-3 ">
    //       <DownloadOutlined className="mr-2 !text-gray-500" />
    //       Export All Opportunities
    //     </div>
    //   ),
    // },
    // {
    //   key: "3",
    //   label: (
    //     <div className="flex items-center text-sm pb-3 border-b-[1px] border-gray-500">
    //       <DownloadOutlined className="mr-2 !text-gray-500" />
    //       Export Current View
    //     </div>
    //   ),
    // },
    {
      key: "4",
      label: (
        <div className="flex flex-col">
          <h5 className="text-[#979696] text-sm">Manage List Columns</h5>
          {checkboxList?.map((data, key) => (
            <Checkbox
              checked={data?.value}
              className="ot-checkbox mb-2 !ml-0"
              onChange={() => handleChangeCheckbox(data?.name)}
            >
              {data?.name}
            </Checkbox>
          ))}
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="relative opportunities-table-expanded ">
        <div className="flex items-center  w-full justify-between">
          <div className="flex items-center">
            <InventoryIcon className=" !text-lg !leading-0 mr-1" />{" "}
            <h5 className="text-base mb-0">Feature Request ({totalCount})</h5>
          </div>
          <Dropdown
            menu={{ items }}
            placement="bottomLeft"
            arrow
            className="z-[9999]"
          >
            <MoreVertIcon className="!text-primary cursor-pointer " />
          </Dropdown>
        </div>
        <div className={`pt-3`}>
          <Table
            columns={columns}
            className={isExpanded ? "opportunitiesExpandedTable" : ""}
            dataSource={userFeatures}
            loading={featureLoading}
            // scroll={{ y: 650, x: 600 }}
            pagination={{
              position: ["topRight"],
              pageSize: 10,
              defaultCurrent: currentPage,
              simple: true,
              total: totalCount,
              onChange: (e) => {
                handleOnTableChange(e);
              },
            }}
          />
        </div>
      </div>
    </>
  );
};

export default FeatureRequestTable;
