import InventoryIcon from "@mui/icons-material/Inventory";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import PeopleAltSharpIcon from "@mui/icons-material/PeopleAltSharp";
import BuildIcon from "@mui/icons-material/Build";
const salesAdminMenuList = [
  {
    link: "/dashboard-sales-person",
    icon: <span className="material-icons-round">window</span>,
    title: "Dashboard",
  },
  {
    link: "/feature-request",
    icon: <InventoryIcon className="mui-menu" />,
    title: "Feature Request",
  },
  {
    link: "/opportunities",
    icon: <span className="material-icons-round">monetization_on</span>,
    title: "Opportunities",
  },
  {
    link: "/reports",
    icon: <span className="material-icons-round">book</span>,
    title: "Reports",
  },
  {
    link: "/users",
    icon: <PeopleAltSharpIcon />,
    title: "Users",
  },
  {
    link: "/extentions",
    icon: <ViewInArIcon className="mui-menu" />,
    title: "Extentions",
  },
  {
    link: "/manage",
    icon: <span className="material-icons-round">construction</span>,
    title: "Manage",
  },
  {
    link: "/product-manage",
    icon: <BuildIcon className="mui-menu" />,
    title: "Product Manage",
  },
];

const adminMenuList = [
  {
    link: "/dashboard-admin",
    icon: <span className="material-icons-round">window</span>,
    title: "Dashboard",
  },
  {
    link: "/feature-request",
    icon: <InventoryIcon className="mui-menu" />,
    title: "Feature Request",
  },
  {
    link: "/opportunities",
    icon: <span className="material-icons-round">monetization_on</span>,
    title: "Opportunities",
  },
  {
    link: "/reports",
    icon: <span className="material-icons-round">book</span>,
    title: "Reports",
  },
  {
    link: "/users",
    icon: <PeopleAltSharpIcon className="mui-menu" />,
    title: "Users",
  },
  {
    link: "/extentions",
    icon: <ViewInArIcon className="mui-menu" />,
    title: "Extentions",
  },
  {
    link: "/manage",
    icon: <span className="material-icons-round">construction</span>,
    title: "Manage",
  },
  {
    link: "/product-manage",
    icon: <BuildIcon className="mui-menu" />,
    title: "Product Manage",
  },
];

const productAdminMenuList = [
  {
    link: "/dashboard-admin",
    icon: <span className="material-icons-round">window</span>,
    title: "Dashboard",
  },
  {
    link: "/feature-request",
    icon: <InventoryIcon className="mui-menu" />,
    title: "Feature Request",
  },
  {
    link: "/opportunities",
    icon: <span className="material-icons-round">monetization_on</span>,
    title: "Opportunities",
  },
  {
    link: "/reports",
    icon: <span className="material-icons-round">book</span>,
    title: "Reports",
  },
  {
    link: "/users",
    icon: <PeopleAltSharpIcon className="mui-menu" />,
    title: "Users",
  },
  {
    link: "/extentions",
    icon: <ViewInArIcon className="mui-menu" />,
    title: "Extentions",
  },
  {
    link: "/manage",
    icon: <span className="material-icons-round">construction</span>,
    title: "Manage",
  },
  {
    link: "/product-manage",
    icon: <BuildIcon className="mui-menu" />,
    title: "Product Manage",
  },
];

const menus = {
  adminMenuList: adminMenuList,
  salesAdminMenuList: salesAdminMenuList,
  productAdminMenuList: productAdminMenuList,
};

export default menus;
