export const YEARLYDATA = [
  {
    title: "Won",
    price: "$560k",
    per: "98%",
    status: "up",
  },
  {
    title: "Lost",
    price: "$560k",
    per: "98%",
    status: "up",
  },
  {
    title: "Open",
    price: "$71k",
    per: "05%",
    status: "down",
  },
  {
    title: "Submitted",
    price: "$560k",
    per: "22%",
    status: "up",
  },
  {
    title: "In Progress",
    price: "23",
    per: "22%",
    status: "up",
  },
  {
    title: "Completed",
    price: "15",
    per: "05%",
    status: "down",
  },
  {
    title: "On Hold",
    price: "22%",
    per: "22%",
    status: "up",
  },
  {
    title: "Rejected",
    price: "02",
    per: "22%",
    status: "up",
  },
];

export const pageSize = 10;

export const DEFAULT_PAGE_SIZE = 10;

export const OPPORTUNITIESTABLE = [
  {
    id: "12345",
    name: "Lorem ipsum dolor ",
    amount: 20000,
    probability: 70,
    priority: "high",
    closeDate: "2022-12-05",
  },
  {
    id: "12345",
    name: "Lorem ipsum dolor ",
    amount: 20000,
    probability: 70,
    priority: "high",
    closeDate: "2022-12-05",
  },
  {
    id: "12345",
    name: "Lorem ipsum dolor ",
    amount: 20000,
    probability: 70,
    priority: "high",
    closeDate: "2022-12-05",
  },
  {
    id: "12345",
    name: "Lorem ipsum dolor ",
    amount: 20000,
    probability: 70,
    priority: "high",
    closeDate: "2022-12-05",
  },
  {
    id: "12345",
    name: "Lorem ipsum dolor ",
    amount: 20000,
    probability: 70,
    priority: "high",
    closeDate: "2022-12-05",
  },
  {
    id: "12345",
    name: "Lorem ipsum dolor ",
    amount: 20000,
    probability: 70,
    priority: "high",
    closeDate: "2022-12-05",
  },
  {
    id: "12345",
    name: "Lorem ipsum dolor ",
    amount: 20000,
    probability: 70,
    priority: "high",
    closeDate: "2022-12-05",
  },
  {
    id: "12345",
    name: "Lorem ipsum dolor ",
    amount: 20000,
    probability: 70,
    priority: "high",
    closeDate: "2022-12-05",
  },
  {
    id: "12345",
    name: "Lorem ipsum dolor ",
    amount: 20000,
    probability: 70,
    priority: "high",
    closeDate: "2022-12-05",
  },
  {
    id: "12345",
    name: "Lorem ipsum dolor ",
    amount: 20000,
    probability: 70,
    priority: "high",
    closeDate: "2022-12-05",
  },
  {
    id: "12345",
    name: "Lorem ipsum dolor ",
    amount: 20000,
    probability: 70,
    priority: "high",
    closeDate: "2022-12-05",
  },
  {
    id: "12345",
    name: "Lorem ipsum dolor ",
    amount: 20000,
    probability: 70,
    priority: "high",
    closeDate: "2022-12-05",
  },
  {
    id: "12345",
    name: "Lorem ipsum dolor ",
    amount: 20000,
    probability: 70,
    priority: "high",
    closeDate: "2022-12-05",
  },
  {
    id: "12345",
    name: "Lorem ipsum dolor ",
    amount: 20000,
    probability: 70,
    priority: "high",
    closeDate: "2022-12-05",
  },
  {
    id: "12345",
    name: "Lorem ipsum dolor ",
    amount: 20000,
    probability: 70,
    priority: "high",
    closeDate: "2022-12-05",
  },
  {
    id: "12345",
    name: "Lorem ipsum dolor ",
    amount: 20000,
    probability: 70,
    priority: "high",
    closeDate: "2022-12-05",
  },
  {
    id: "12345",
    name: "Lorem ipsum dolor ",
    amount: 20000,
    probability: 70,
    priority: "high",
    closeDate: "2022-12-05",
  },
  {
    id: "12345",
    name: "Lorem ipsum dolor ",
    amount: 20000,
    probability: 70,
    priority: "high",
    closeDate: "2022-12-05",
  },
  {
    id: "12345",
    name: "Lorem ipsum dolor ",
    amount: 20000,
    probability: 70,
    priority: "medium",
    closeDate: "2022-12-05",
  },
  {
    id: "12345",
    name: "Lorem ipsum dolor ",
    amount: 20000,
    probability: 70,
    priority: "high",
    closeDate: "2022-12-05",
  },
  {
    id: "12345",
    name: "Lorem ipsum dolor ",
    amount: 20000,
    probability: 70,
    priority: "high",
    closeDate: "2022-12-05",
  },
  {
    id: "12345",
    name: "Lorem ipsum dolor ",
    amount: 20000,
    probability: 70,
    priority: "medium",
    closeDate: "2022-12-05",
  },
  {
    id: "12345",
    name: "Lorem ipsum dolor ",
    amount: 20000,
    probability: 70,
    priority: "high",
    closeDate: "2022-12-05",
  },
  {
    id: "12345",
    name: "Lorem ipsum dolor ",
    amount: 20000,
    probability: 70,
    priority: "high",
    closeDate: "2022-12-05",
  },
  {
    id: "12345",
    name: "Lorem ipsum dolor ",
    amount: 20000,
    probability: 70,
    priority: "medium",
    closeDate: "2022-12-05",
  },
  {
    id: "12345",
    name: "Lorem ipsum dolor ",
    amount: 20000,
    probability: 70,
    priority: "high",
    closeDate: "2022-12-05",
  },
  {
    id: "12345",
    name: "Lorem ipsum dolor ",
    amount: 20000,
    probability: 70,
    priority: "high",
    closeDate: "2022-12-05",
  },
  {
    id: "12345",
    name: "Lorem ipsum dolor ",
    amount: 20000,
    probability: 70,
    priority: "high",
    closeDate: "2022-12-05",
  },
  {
    id: "12345",
    name: "Lorem ipsum dolor ",
    amount: 20000,
    probability: 70,
    priority: "medium",
    closeDate: "2022-12-05",
  },
  {
    id: "12345",
    name: "Lorem ipsum dolor ",
    amount: 20000,
    probability: 70,
    priority: "high",
    closeDate: "2022-12-05",
  },
  {
    id: "12345",
    name: "Lorem ipsum dolor ",
    amount: 20000,
    probability: 70,
    priority: "high",
    closeDate: "2022-12-05",
  },
  {
    id: "12345",
    name: "Lorem ipsum dolor ",
    amount: 20000,
    probability: 70,
    priority: "medium",
    closeDate: "2022-12-05",
  },
  {
    id: "12345",
    name: "Lorem ipsum dolor ",
    amount: 20000,
    probability: 70,
    priority: "high",
    closeDate: "2022-12-05",
  },
  {
    id: "12345",
    name: "Lorem ipsum dolor ",
    amount: 20000,
    probability: 70,
    priority: "high",
    closeDate: "2022-12-05",
  },
  {
    id: "9089",
    name: "Lorem ipsum dolor ",
    amount: 20000,
    probability: 70,
    priority: "low",
    closeDate: "2022-12-05",
  },
  {
    id: "12345",
    name: "Lorem ipsum dolor ",
    amount: 20000,
    probability: 70,
    priority: "high",
    closeDate: "2022-12-05",
  },
  {
    id: "12345",
    name: "Lorem ipsum dolor ",
    amount: 20000,
    probability: 70,
    priority: "high",
    closeDate: "2022-12-05",
  },
  {
    id: "12345",
    name: "Lorem ipsum dolor ",
    amount: 20000,
    probability: 70,
    priority: "low",
    closeDate: "2022-12-05",
  },
  {
    id: "12345",
    name: "Lorem ipsum dolor ",
    amount: 20000,
    probability: 70,
    priority: "high",
    closeDate: "2022-12-05",
  },
  {
    id: "12345",
    name: "Lorem ipsum dolor ",
    amount: 20000,
    probability: 70,
    priority: "low",
    closeDate: "2022-12-05",
  },
  {
    id: "12345",
    name: "Lorem ipsum dolor ",
    amount: 20000,
    probability: 70,
    priority: "high",
    closeDate: "2022-12-05",
  },
  {
    id: "12345",
    name: "Lorem ipsum dolor ",
    amount: 20000,
    probability: 70,
    priority: "low",
    closeDate: "2022-12-05",
  },
  {
    id: "12345",
    name: "Lorem ipsum dolor ",
    amount: 20000,
    probability: 70,
    priority: "high",
    closeDate: "2022-12-05",
  },
  {
    id: "12345",
    name: "Lorem ipsum dolor ",
    amount: 20000,
    probability: 70,
    priority: "high",
    closeDate: "2022-12-05",
  },
  {
    id: "12345",
    name: "Lorem ipsum dolor ",
    amount: 20000,
    probability: 70,
    priority: "low",
    closeDate: "2022-12-05",
  },
  {
    id: "12345",
    name: "Lorem ipsum dolor ",
    amount: 20000,
    probability: 70,
    priority: "high",
    closeDate: "2022-12-05",
  },
  {
    id: "12345",
    name: "Lorem ipsum dolor ",
    amount: 20000,
    probability: 70,
    priority: "high",
    closeDate: "2022-12-05",
  },
  {
    id: "12345",
    name: "Lorem ipsum dolor ",
    amount: 20000,
    probability: 70,
    priority: "low",
    closeDate: "2022-12-05",
  },
];

export const DATA_TYPES = [
  {
    label: "Priority",
    value: "Priority",
  },
  {
    label: "Probability",
    value: "Probability",
  },
  {
    label: "Date",
    value: "Date",
  },
  {
    label: "Currency",
    value: "Currency",
  },
  {
    label: "Customer",
    value: "Customer",
  },
];

export const PRIORITY_OPTIONS = [
  {
    label: "Critical",
    value: "Critical",
  },
  {
    label: "High",
    value: "High",
  },
  {
    label: "Medium",
    value: "Medium",
  },
  {
    label: "Low",
    value: "Low",
  },
];
export const AMOUNT_OPTION = [
  {
    label: "is greater than",
    value: "is greater than",
  },
  {
    label: "is between",
    value: "is between",
  },
  {
    label: "is less than",
    value: "is less than",
  },
];

export const CUSTOMER_OPTION = [
  {
    label: "Gold",
    value: "Gold",
  },
  {
    label: "Sliver",
    value: "Sliver",
  },
  {
    label: "Paper",
    value: "Paper",
  },
];
export const featureRequestTableData = [
  {
    id: "DEV 31",
    project: "ATMOS",
    title: "Need field level permission",
    otScore: "30.92",
    type: "New features",
    action: "",
  },
  {
    id: "DEV 31",
    project: "ATMOS",
    title: "Need field level permission",
    otScore: "30.92",
    type: "New features",
    action: "",
  },
  {
    id: "DEV 31",
    project: "ATMOS",
    title: "Need field level permission",
    otScore: "30.92",
    type: "New features",
    action: "",
  },
];

export const FEATUREREQUESTDATA = [
  {
    jiraId: 1234,
    name: "Lorem ipsum dolor sit amet",
    amount: 30,
    probability: 90,
    appends: 1,
    closeDate: "12/12/2012",
  },
  {
    jiraId: 1234,
    name: "Lorem ipsum dolor sit amet",
    amount: 30,
    probability: 90,
    appends: 1,
    closeDate: "12/12/2012",
  },
  {
    jiraId: 1234,
    name: "Lorem ipsum dolor sit amet",
    amount: 30,
    probability: 90,
    appends: 1,
    closeDate: "12/12/2012",
  },
  {
    jiraId: 1234,
    name: "Lorem ipsum dolor sit amet",
    amount: 30,
    probability: 90,
    appends: 1,
    closeDate: "12/12/2012",
  },
  {
    jiraId: 1234,
    name: "Lorem ipsum dolor sit amet",
    amount: 30,
    probability: 90,
    appends: 1,
    closeDate: "12/12/2012",
  },
  {
    jiraId: 1234,
    name: "Lorem ipsum dolor sit amet",
    amount: 30,
    probability: 90,
    appends: 1,
    closeDate: "12/12/2012",
  },
  {
    jiraId: 1234,
    name: "Lorem ipsum dolor sit amet",
    amount: 30,
    probability: 90,
    appends: 1,
    closeDate: "12/12/2012",
  },
  {
    jiraId: 1234,
    name: "Lorem ipsum dolor sit amet",
    amount: 30,
    probability: 90,
    appends: 1,
    closeDate: "12/12/2012",
  },
];

export const REPORTSTAGE = [
  { value: "submitted", label: "submitted" },
  { value: "In Progress", label: "In Progress" },
  { value: "Done", label: "Done" },
  { value: "Rejected", label: "Rejected" },
  { value: "Deleted", label: "Deleted" },
  { value: "Opportunities Open", label: "Opportunities Open" },
  { value: "Opportunities Won", label: "Opportunities Won" },
  { value: "Opportunities Lost", label: "Opportunities Lost" },
];

export const DATETYPE = [
  { value: "Year to date", label: "Year to date" },
  { value: "Select Date", label: "Select Date" },
];

export const REPORTYPE = [
  { value: "lorem", label: "lorem" },
  { value: "lorem2", label: "lorem2" },
  { value: "lorem3", label: "lorem3" },
  { value: "lorem4", label: "lorem4" },
];
