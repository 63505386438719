import React from "react";
import TopFiveOpportunitiesTableComponent from "../tables/TopFiveOpportunitiesTableComponent";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
function TopFiveOpportunitiesTable() {
  return (
    <div className="top-five-opportunities-container">
      <div className="flex justify-between items-center">
        <h2 className="font-15-regular flex items-center">
          <MonetizationOnIcon className=" text-gray-600 !text-base !leading-0 mr-1" />{" "}
          Top 5 Opportunities
        </h2>
        <div className="section-right">
          <p>View all </p>
          <span className="material-icons">keyboard_arrow_up</span>
        </div>
      </div>
      <TopFiveOpportunitiesTableComponent />
    </div>
  );
}

export default TopFiveOpportunitiesTable;
