import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import { Table } from "antd";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";

const AccountTable = (props) => {
  const { handleAddModalForm } = props;
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a) => a,
      render: (text) => <div className="py-3 px-2">{text}</div>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: (a) => a,
      render: (name) => <div className="py-3 px-2"> {name}</div>,
    },
    {
      title: "Office Address",
      dataIndex: "officeAddress",
      key: "Office Address",
      sorter: (a) => a,
      render: (amount) => <div className="py-3 px-2">{amount}</div>,
    },
    {
      title: "City",
      key: "city",
      dataIndex: "city",
      sorter: (a) => a,
      render: (company) => <div className="py-3 px-2">{company}</div>,
    },
    {
      title: "Description",
      key: "description",
      dataIndex: "description",
      sorter: (a) => a,
      render: (role) => <div className="py-3 px-2">{role}</div>,
    },
    {
      title: "Url",
      key: "url",
      dataIndex: "url",
      sorter: (a) => a,
      render: (url) => <div className="py-3 px-2">{url}</div>,
    },
    {
      title: "Edit",
      key: "edit",
      dataIndex: "edit",
      sorter: (a) => a,
      render: (edit) => (
        <div className="py-3 px-2">
          <EditIcon className="text-[#333333]" />
        </div>
      ),
    },
  ];

  const tableData = [
    {
      name: "Gaurav",
      email: "xyz.com",
      officeAddress: "Ganesh nagar amravati",
      city: "amravati",
      description: "tenant for deepak",
      url: "https:google.com",
      edit: "as",
    },
    {
      name: "Gaurav",
      email: "xyz.com",
      officeAddress: "Ganesh nagar amravati",
      city: "amravati",
      description: "tenant for deepak",
      url: "https:google.com",
      edit: "as",
    },
    {
      name: "Gaurav",
      email: "xyz.com",
      officeAddress: "Ganesh nagar amravati",
      city: "amravati",
      description: "tenant for deepak",
      url: "https:google.com",
      edit: "as",
    },
    {
      name: "Gaurav",
      email: "xyz.com",
      officeAddress: "Ganesh nagar amravati",
      city: "amravati",
      description: "tenant for deepak",
      url: "https:google.com",
      edit: "as",
    },
  ];
  return (
    <div>
      <div className="relative  user-table pl-3">
        <div className="flex items-center absolute left-3 top-9">
          <MonetizationOnOutlinedIcon className=" !text-lg !leading-0 mr-1" />{" "}
          <h5 className="text-base mb-0">Account List (23)</h5>
        </div>
        <button
          className="!bg-primary text-white text-xs p-2 rounded-sm top-9 absolute right-4 z-50"
          onClick={handleAddModalForm}
        >
          + Add Account
        </button>
        <Table
          columns={columns}
          // className={"opportunitiesExpandedTable"}
          dataSource={tableData}
          pagination={{
            position: ["topRight"],
            pageSize: 10,
            simple: true,
          }}
          scroll={{ y: 640 }}
        />
      </div>
    </div>
  );
};

export default AccountTable;
