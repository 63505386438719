import React from "react";

const ErrorComponent = (props) => {
  const { errors, touched } = props;
  return (
    <div className="mt-1 text-red-600 text-xs">
      {errors && touched ? errors : null}
    </div>
  );
};

export default ErrorComponent;
