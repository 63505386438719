import { combineReducers } from "redux";
import authReducer from "./auth";
import mainDashboard from "./mainDashboard";
import opportunities from "./opportunities";
import user from "./users";
import alert from "./alert";
import buckets from "./buckets";
import accounts from "./accounts";
import jira from "./jira";
import features from "./features";
import metrics from "./metrics";
import productMetrics from "./productMetrics";
import extentions from "./extentions";

export default combineReducers({
  auth: authReducer,
  dashboard: mainDashboard,
  opportunities,
  user,
  alert,
  buckets,
  accounts,
  jira,
  features,
  metrics,
  productMetrics,
  extentions,
});
