import {
  Chart as ChartJS,
  Legend,
  LinearScale,
  PointElement,
  Tooltip,
} from "chart.js";
import React from "react";
import { Bubble } from "react-chartjs-2";

ChartJS.register(LinearScale, PointElement, Tooltip, Legend);

export const options = {
  scales: {
    y: {
      beginAtZero: true,
    },
  },
};

const data = {
  datasets: [
    {
      label: "First Dataset",
      data: [
        {
          x: 20,
          y: 30,
          r: 15,
        },
        {
          x: 40,
          y: 10,
          r: 10,
        },
        {
          x: 30,
          y: 5,
          r: 25,
        },
        {
          x: 45,
          y: 15,
          r: 15,
        },
        {
          x: 30,
          y: 35,
          r: 10,
        },
        {
          x: 4,
          y: 1,
          r: 10,
        },
        {
          x: 58,
          y: 9,
          r: 5,
        },
        {
          x: 20,
          y: 10,
          r: 15,
        },
      ],
      backgroundColor: "#69BE28",
    },
  ],
};
const DashboardYourPerformanceOpprtunitesChart = () => {
  return (
    <div className="dashboard-performance-section">
      <Bubble options={options} data={data} />
    </div>
  );
};

export default DashboardYourPerformanceOpprtunitesChart;
