import types from "../../types";

const initialState = {
  opportunitiesList: [],
  opportunitiesNotes: [],
  opportunityScore: 0,
  opportunitiesAccount: [],
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_ALL_OPPORTUNITIES:
      return {
        ...state,
        opportunitiesList: action.payload,
      };
    case types.SET_OPPORTUNITIES_NOTES:
      return {
        ...state,
        opportunitiesNotes: action.payload,
      };
    case types.SET_OPPORTUNITIES_SCORE:
      return {
        ...state,
        opportunitiesNotes: action.payload,
      };
    case types.SET_OPPORTUNITIES_ACCOUNTS:
      return {
        ...state,
        opportunitiesAccount: action.payload,
      };
    default:
      return { ...state };
  }
}
