import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { Input } from "formik-antd";
import DisabledByDefaultOutlinedIcon from "@mui/icons-material/DisabledByDefaultOutlined";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../store/actions";
import { useNavigate } from "react-router-dom";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const FeatureRequestComment = (props) => {
  const { selectedFeatures } = props;
  const { featureDetails } = useSelector((state) => state.features);
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const navigate = useNavigate();
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const dispatch = useDispatch();
  const handleSubmit = async (values) => {
    setLoadingSubmit(true);
    const payload = {
      commentBody: values?.comment,
      issueId: selectedFeatures?.destinationId,
    };

    const res = await dispatch(actions.addComment(payload));
    console.log(res, "res");
    if (res.status === 200) {
      dispatch(
        actions.getFeatureDetails(
          selectedFeatures?.customFeatureId,
          navigate,
          setLoading
        )
      );
    }
    setLoadingSubmit(false);
  };

  const { TextArea } = Input;

  const handleEditComment = () => {
    console.log("Onclick comment");
  };

  const handleDeleteComment = () => {
    console.log("Onclick Delete");
  };

  useEffect(() => {
    dispatch(
      actions.getFeatureDetails(
        selectedFeatures?.customFeatureId,
        navigate,
        setLoading
      )
    );
  }, [selectedFeatures]);

  return (
    <>
      <Formik initialValues={{ comment: "" }} onSubmit={handleSubmit}>
        {({}) => (
          <Form>
            <div className="flex items-end">
              <div className="w-10/12">
                <label className="mb-0 mr-3 pb-2 text-[#9A9F95] text-xs">
                  Add New Comment
                </label>
                <TextArea
                  rows={3}
                  id={"comment"}
                  name="comment"
                  className="ot-textarea"
                />
              </div>
              <button
                type="submit"
                className="bg-[#002244] text-white !text-xs px-2 ml-5 py-1 mb-2 rounded-sm generateReportBtn generateReportBtn--FR"
              >
                {loadingSubmit && <Spin indicator={antIcon} size="small" />}
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
      <div className="border-[1px] border-b-0 mt-3 border-[#DFDFDF] h-72 overflow-y-auto">
        {loading && (
          <div className="h-48 w-full flex items-center justify-center">
            <Spin />
          </div>
        )}
        {!loading && featureDetails[0]?.jiraDetails?.comments?.length === 0 && (
          <div>
            <h2 className=" text-3xl text-center py-5">Comment not found</h2>
          </div>
        )}
        {!loading &&
          featureDetails[0]?.jiraDetails?.comments?.map((data, index) => (
            <div className="flex items-center justify-between  px-4 py-2 border-b-[1px] border-[#DFDFDF]">
              <div className="w-9/12">
                <h4 className="text-xs mb-2">{data.author}</h4>
                <h4 className="text-xs mb-0">{data.content}</h4>
              </div>
              {/* <h4 className="text-xs mb-0 w-2/12">{data.time}</h4> */}
              <div className="w-1/12">
                <button className="mr-3" onClick={handleEditComment}>
                  <ModeEditOutlinedIcon className="!text-[#D8D8D8] !text-sm " />
                </button>
                <button onClick={handleDeleteComment}>
                  <DisabledByDefaultOutlinedIcon className="!text-[#D8D8D8] !text-sm " />
                </button>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default FeatureRequestComment;
