import React, { Fragment } from "react";
import SideBar from "./sidebar/SideBar";
import Header from "./Header/Header";

import { useSelector } from "react-redux";

function Default() {
  const toggleState = useSelector((state) => state.dashboard.toggleSidebar);
  return (
    <Fragment>
      <div
        className={`d-flex dashboard-main-container default-main-container ${
          toggleState ? "expandedBlock" : "unExpandedBlock"
        }`}
      >
        <SideBar sideBardExtendHeight={"default-main-container"} />
        <div
          style={{ alignItems: "center" }}
          className={
            toggleState ? "content-section-extend" : "content-section-minimize"
          }
        >
          <Header title={"Page Not found"} />
          <h1 className="page-not-found !text-primary py-5">Page Not Found</h1>
        </div>
      </div>
    </Fragment>
  );
}

export default Default;
