import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
const ExtentionsList = (props) => {
  const { handleExtension, setExtentionType } = props;
  const dummyArray = [
    { img: "/salesforces.jfif", name: "sdfc" },
    { img: "/jira.png", name: "jira" },
  ];
  return (
    <div className="py-6 bg-white px-8">
      <h2 className="text-base pb-5 mb-0"> Installed Extensions</h2>
      <div className="flex">
        {dummyArray.map((data, key) => (
          <div
            key={key}
            className="w-44 min-h-[241px] mr-3 bg-white p-3 shadow-[0px_3px_6px_0px_rgba(0,0,0,0.27)]"
          >
            <div className="flex justify-between items-center pb-2">
              <CheckCircleIcon className="!text-primary !text-lg" />
              <h3 className="text-sm mb-0">Connected</h3>
              <button
                onClick={() => {
                  handleExtension();
                  setExtentionType(data.name);
                }}
              >
                <ModeEditOutlineOutlinedIcon className="!text-lg !text-gray-400" />
              </button>
            </div>
            <img src={data.img} className="w-32 mx-auto" alt="salesforces" />
            <div className="text-center pt-9">
              <button className="!bg-primary text-[13px] text-white rounded-[3px] !px-3.5 !py-1 disabled:opacity-50 mx-auto">
                Create Metrics
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ExtentionsList;
