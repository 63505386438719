import React from "react";
import RecentUsersTableComponent from "../tables/RecentUsersTableComponent";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
function RecentUsers() {
  return (
    <>
      <div className="flex justify-between items-center">
        <h2 className="font-15-regular">
          <PeopleAltIcon className=" text-gray-600 !text-base !leading-0 mr-1" />
          Recent Users
        </h2>
        <div className="section-right">
          <p>View all </p>
          <span className="material-icons">keyboard_arrow_up</span>
        </div>
      </div>
      <RecentUsersTableComponent />
    </>
  );
}

export default RecentUsers;
