import { Input } from "antd";
import React from "react";

const InputFields = (props) => {
  const { value, handleChange, placeholader, extraClass } = props;
  return (
    <Input
      placeholder={placeholader}
      className={`${
        extraClass ? extraClass : ""
      } !border-[1px]  border-[#797979]  !text-sm rounded`}
      value={value}
      onChange={handleChange}
    />
  );
};

export default InputFields;
