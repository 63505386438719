import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import actions from "../../store/actions";
import Header from "../Header/Header";
import { pageSize } from "../common/constant";
import SideBar from "../sidebar/SideBar";
import UserTable from "./UserTable";
import AddUser from "./addUser/AddUser";

function UserMain() {
  const dispatch = useDispatch();
  const [showAddModalForm, setShowAddModalForm] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isUpdateTable, setIsUpdateTable] = useState(false);
  const toggleState = useSelector((state) => state.dashboard.toggleSidebar);
  const { tenant } = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const { userList } = useSelector((state) => state.user);
  const handleAddModalForm = () => {
    setShowAddModalForm(!showAddModalForm);
  };

  const handleEdit = (data) => {
    setSelectedUser(data);
    setShowAddModalForm(true);
    setIsEdit(true);
  };

  const handleAdd = () => {
    setSelectedUser(null);
    setShowAddModalForm(true);
    setIsEdit(false);
  };

  const handelGetData = async () => {
    setIsLoading(true);
    const res = await dispatch(
      actions.getAllUsers({
        tenant,
        currentPage,
        pageSize,
        navigate,
      })
    );
    if (res) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (!showAddModalForm) {
      handelGetData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, tenant, currentPage, showAddModalForm, isUpdateTable]);

  const handleOnTableChange = (e) => {
    setCurrentPage(e);
  };

  return (
    <Fragment>
      <div
        className={`d-flex dashboard-main-container reports-main-container ${
          toggleState ? "expandedBlock" : "unExpandedBlock"
        }`}
      >
        <SideBar sideBardExtendHeight={"reports-main-container"} />

        <div
          className={
            toggleState ? "content-section-extend" : "content-section-minimize"
          }
        >
          <div className="bg-white min-h-screen">
            <Header title={"Users"} />
            <UserTable
              {...{
                handleAddModalForm,
                userList,
                handleOnTableChange,
                currentPage,
                isLoading,
                handleEdit,
                handleAdd,
                setIsUpdateTable,
              }}
            />
            {showAddModalForm && (
              <AddUser
                {...{
                  showAddModalForm,
                  handleAddModalForm,
                  selectedUser,
                  isEdit,
                }}
              />
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default UserMain;
