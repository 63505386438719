import { Pagination, Spin } from "antd";
import React, { useState } from "react";
import isEmpty from "../../store/validations/is-empty";
import shortText from "../../utils/sliceString";
import InventoryIcon from "@mui/icons-material/Inventory";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { SearchSharp } from "@mui/icons-material";
import { FEATUREREQUESTDATA } from "../common/constant";
import GreenBtn from "../common/greenBtn";
import moment from "moment";
import DisplayDate from "../common/DisplayDate";

const FeatureRequestExpandedTableContent = (props) => {
  const {
    userFeatures,
    featureLoading,
    handleSelectedData,
    selectedFeatures,
    handleShowAppendFr,
    totalCount,
    handleOnTableChange,
    currentPage,
  } = props;

  const recordsInOnePage = 10;

  return (
    <>
      <div className="relative flex items-center justify-between">
        <div className="flex items-center">
          <InventoryIcon className=" !text-lg !leading-0 mr-1 !text-[#797979]" />{" "}
          <h5 className="text-base mb-0">Feature Request ({totalCount})</h5>
        </div>
        <div className="flex items-center">
          <SearchSharp className="!text-primary" />
          <MoreVertIcon className="!text-primary cursor-pointer z-[9999]" />
        </div>
      </div>
      <div className="w-full border-2 mt-3 border-[#DFDFDF] relative">
        {!isEmpty(userFeatures) &&
          userFeatures?.map((data, key) => {
            return (
              key >= currentPage - 1 * recordsInOnePage &&
              key < currentPage * recordsInOnePage && (
                <div
                  key={key}
                  className={`flex justify-between items-start py-3 px-2 border-b-2 cursor-pointer border-[#DFDFDF] ${
                    selectedFeatures?.id === data?.id ? "bg-[#DFDFDF]" : ""
                  }`}
                  onClick={() => handleSelectedData(data)}
                >
                  <div className="w-7/12 pr-1">
                    <h5 className="text-xs mb-3">
                      {data?.jiraDetails?.issueName}
                    </h5>
                    <div className="flex justify-between">
                      <h5 className="text-xs mb-0">
                        {data?.customFeatureId ?? "-"}
                      </h5>
                      <a
                        href={`https://otdevt1.atlassian.net/browse/${data?.destinationId}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <h5 className="text-xs mb-0 !text-primary">
                          {data?.destinationId}
                        </h5>
                      </a>
                      <h5
                        className="text-xs mb-0 !text-primary"
                        onClick={() => {
                          handleShowAppendFr();
                          handleSelectedData(data);
                        }}
                      >
                        {data.numberOfAppend}
                      </h5>
                    </div>
                  </div>
                  <div className="w-5/12  flex flex-col items-end">
                    <div>
                      {data?.jiraStatus?.toLowerCase() === "done" ? (
                        <GreenBtn
                          {...{
                            text: data?.jiraStatus,
                            extrClass: "w-14 !cursor-auto",
                          }}
                        />
                      ) : data?.jiraStatus?.toLowerCase() === "to do" ? (
                        <GreenBtn
                          {...{
                            text: data?.jiraStatus,
                            extrClass: "!bg-[#EB901C] w-14  !cursor-auto",
                          }}
                        />
                      ) : (
                        <GreenBtn
                          {...{
                            text: data?.jiraStatus,
                            extrClass: "!bg-[#2BB8FF] w-14  !cursor-auto",
                          }}
                        />
                      )}
                    </div>
                    <h5 className="text-xs mb-0 mr-2 mt-2">
                      <DisplayDate
                        {...{ date: data?.updatedDate, format: "YYYY-MM-DD" }}
                      />
                    </h5>
                  </div>
                </div>
              )
            );
          })}
        {featureLoading && (
          <div className="h-full bg-white bg-opacity-50 w-96 flex justify-center items-center absolute left-0 top-0">
            <Spin />
          </div>
        )}
      </div>
      <div className="text-right mt-3">
        <Pagination
          simple
          defaultCurrent={currentPage}
          pageSize={10}
          total={totalCount}
          onChange={(e) => handleOnTableChange(e)}
        />
      </div>
    </>
  );
};

export default FeatureRequestExpandedTableContent;
