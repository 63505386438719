import { Modal } from "antd";
import { Formik } from "formik";
import { Form, Select } from "formik-antd";
import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import actions from "../../store/actions";
import GreenBtn from "../common/greenBtn";

const AppendFRModal = (props) => {
  const { handleModal, isAppendFR, selectedFR, appendPayload } = props;
  const dispatch = useDispatch();
  const formRef = useRef();
  console.log(selectedFR, "selectedFR");
  const featurePriority = [
    { label: "Must Have", value: "Must Have" },
    { label: "Should Have", value: "Should Have" },
    { label: "Could Have", value: "Could Have" },
    { label: "Won't Have", value: "Won't Have" },
  ];

  const handleSubmit = async (values) => {
    let payload = { ...appendPayload, featurePriority: "Should Have" };
    dispatch(
      actions.updateAppendFR({ payload, jiraId: appendPayload.issueId })
    );
  };
  return (
    <div>
      <Modal
        width={"800px"}
        open={isAppendFR}
        onCancel={handleModal}
        okText={"Submit"}
        okButtonProps={"submit"}
        onOk={() => handleSubmit()}
      >
        <h2 className=" text-base mb-0 pb-2 border-b-[1px] border-[#BCCED5]">
          Need ability to pull SF Data
        </h2>
        <div className="flex pt-3 justify-between">
          <div className="w-4/12">
            <h4 className="mb-3  text-[#9A9F95] text-xs">
              FR ID : <span className="pl-1 text-[#1F1F1F]">FR-43</span>
            </h4>

            <h4 className="mb-3  text-[#9A9F95] text-xs">
              JIRA ID :{" "}
              <span className="pl-1 text-[#1F1F1F]">{selectedFR?.issueId}</span>
            </h4>
            <h4 className="mb-3  text-[#9A9F95] text-xs">
              Created on :{" "}
              <span className="pl-1 text-[#1F1F1F]">09 June 2022</span>
            </h4>
            <h4 className="mb-3  text-[#9A9F95] text-xs">
              Opportunity Name :{" "}
            </h4>
            <span className="pl-1 text-[#1F1F1F]">{selectedFR?.issueName}</span>
          </div>
          <div className="w-3/12">
            <div className="flex text-xs pb-3">
              <span className="w-1/2 text-right text-[#9A9F95]">
                Issue Type :
              </span>
              <span className="pl-2 text-[#1F1F1F]">
                {selectedFR?.issueTypeId}
              </span>
            </div>
            <div className="flex text-xs pb-3">
              <span className="w-1/2 text-right text-[#9A9F95] mr-2">
                Priority :
              </span>
              {selectedFR?.priority?.toLowerCase() === "high" ? (
                <GreenBtn
                  {...{
                    text: selectedFR?.priority,
                    extrClass: "w-14 !cursor-auto",
                  }}
                />
              ) : selectedFR?.priority?.toLowerCase() === "medium" ? (
                <GreenBtn
                  {...{
                    text: selectedFR?.priority,
                    extrClass: "!bg-[#EB901C] w-14  !cursor-auto",
                  }}
                />
              ) : (
                <GreenBtn
                  {...{
                    text: selectedFR?.priority,
                    extrClass: "!bg-[#2BB8FF] w-14  !cursor-auto",
                  }}
                />
              )}
            </div>
            <div className="flex text-sm">
              <span className="w-1/2 text-right text-[#9A9F95]">Project :</span>
              <span className="pl-2 text-[#1F1F1F] text-xs">
                {selectedFR?.projectId}
              </span>
            </div>
          </div>
          <div className="w-5/12 flex justify-end">
            <div className="w-11/12 bg-[#EDEBEB] p-3 flex">
              <div className="w-7/12 flex flex-col">
                <span className=" text-xs text-[#1F1F1F] pb-3">OT Score :</span>
                {/* <span className=" text-xs text-[#1F1F1F] pb-3">
                  Sales Score :
                </span>
                <span className=" text-xs text-[#1F1F1F] pb-3">
                  Product Score :
                </span>
                <span className=" text-xs text-[#1F1F1F] pb-3">
                  Actual Score :
                </span> */}
                <span className=" text-xs text-[#1F1F1F] pb-3">FR Status:</span>
              </div>
              <div className="w-5/12 flex flex-col">
                <span className=" text-xs text-[#1F1F1F] pb-3">
                  {selectedFR?.score}
                </span>
                {/* <span className=" text-xs text-[#1F1F1F] pb-3">36.91</span>
                <span className=" text-xs text-[#1F1F1F] pb-3">12.85</span>
                <span className=" text-xs text-[#1F1F1F] pb-3">29.55</span> */}
                <span className=" text-xs text-white text-center p-2 bg-[#EB901C]">
                  {selectedFR?.jiraStatus?.name}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-2">
          <h4 className="mb-2  text-[#9A9F95] text-xs">Description</h4>
          <span className="pl-1 text-[#1F1F1F]">
            {selectedFR?.description}
            {/* <span className="!text-primary"> .... read more</span> */}
          </span>
        </div>
        <div className="flex items-center pt-3">
          <Formik
            initialValues={{ featurePriority: "" }}
            onSubmit={handleSubmit}
            innerRef={formRef}
          >
            {({ values }) => (
              <Form>
                <label className="mb-0 mr-3  text-[#9A9F95] text-xs">
                  *Feature Priority
                </label>
                <Select
                  options={featurePriority}
                  name="featurePriority"
                  id="featurePriority"
                  placeholder="Select Priority"
                  className="ot-select border-[#797979] !w-44"
                />
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    </div>
  );
};

export default AppendFRModal;
