import types from "../../types";

const initialState = {
  productMetricsList: [],
  productSelectedMetricsList: [],
  productbucketListByMetrics: [],
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_ALL_PRODUCT_METRICS:
      return {
        ...state,
        productMetricsList: action.payload,
      };
    case types.SET_PRODUCT_SELECTED_METRICS:
      return {
        ...state,
        productSelectedMetricsList: action.payload,
      };
    case types.SET_BUCKETS_LIST_BY_PRODUCT_METRIC:
      return {
        ...state,
        productbucketListByMetrics: action.payload,
      };

    default:
      return { ...state };
  }
}
