import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
// import faker from "faker";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  scales: {
    x: {
      stacked: false,
    },
    y: {
      stacked: false,
    },
  },
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: false,
      text: "Chart.js Bar Chart",
    },
  },
};

const labels = [
  "Week12",
  "Week18",
  "Week25",
  "Week27",
  "Week36",
  "Week38",
  "Week40",
  "Week43",
];

export const data = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: [40, 40, 60, 100, 5],
      backgroundColor: "#69BE28",
      borderWidth: 0,
    },
    {
      label: "Dataset 2",
      data: [0, 0, 0, 1],
      backgroundColor: "#9A9F95",
      borderWidth: 0,
    },
  ],
};

function VerticalBarChart() {
  return <Bar options={options} data={data} width={6000} height={1500} />;
}

export default VerticalBarChart;
