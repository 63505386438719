import React, { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import SideBar from "../sidebar/SideBar";
import Header from "../Header/Header";
import AdminWidgetBoxes from "./AdminWidgetBoxes";
import ExtensionsAdded from "./common/ExtensionsAdded";
import RecentUsers from "./common/RecentUsers";
import Footer from "../Footer/Footer";
import actions from "../../store/actions";
import { useDispatch } from "react-redux";

function DashboardAdmin() {
  const dispatch = useDispatch();

  const toggleState = useSelector((state) => state.dashboard.toggleSidebar);
  const { tenant } = useSelector((state) => state.auth.user);

  const { featureSummary, reportedSummary } = useSelector(
    (state) => state.dashboard
  );

  useEffect(() => {
    dispatch(actions.getAllUsers({ tenant }));
    dispatch(actions.getFeatureSummary());
    dispatch(actions.getReportedSummary());
    dispatch(actions.getExtensions());
  }, [dispatch, tenant]);

  return (
    <Fragment>
      <div
        className={`d-flex dashboard-main-container dashboard-admin-container ${
          toggleState ? "expandedBlock" : "unExpandedBlock"
        }`}
      >
        <SideBar sideBardExtendHeight={"dashboard-admin-container"} />
        <div
          className={
            toggleState ? "content-section-extend" : "content-section-minimize"
          }
        >
          <Header title={"Dashboard"} />
          <AdminWidgetBoxes {...{ featureSummary, reportedSummary }} />
          <div className="dashboard-content-div">
            <div className="mt-3">
              <ExtensionsAdded />
            </div>

            <div className="mt-5">
              <RecentUsers />
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </Fragment>
  );
}

export default DashboardAdmin;
