import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import actions from "../../../store/actions";
import FiledArray from "./FiledArray";

const StepThreeNew = (props) => {
  const { bucketListByMetrics, setActiveStep } = props;
  const [initialValues, setInitialValues] = useState({});
  const [activeIndex, setActiveIndex] = useState(0);
  const [resSelectedIndex, setResSelectedIndex] = useState(null);
  const [filledBucket, setFilledBucket] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (bucketListByMetrics?.length > 0) {
      const init = [];
      // eslint-disable-next-line array-callback-return
      bucketListByMetrics?.map((data) => {
        init.push({
          metricId: data.id,
          metricsName: data.name,
          weightage: data.weightage,
          type: data.type,
          metricsList: [
            {
              fromValue: "",
              toValue: "",
              percentage: 0,
              condition: null,
              type: data.type,
            },
          ],
        });
      });

      setValue("metrics", init);
      setInitialValues(init);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bucketListByMetrics]);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    errors,
    // reset,
    watch,
    setValue,
  } = useForm({
    defaultValues: initialValues,
  });
  const dispatch = useDispatch();
  const watchFileds = watch();

  const onSubmit = async (values) => {
    setLoading(true);
    const temp = [];
    // eslint-disable-next-line array-callback-return
    values.map((data) => {
      // eslint-disable-next-line array-callback-return
      data?.metricsList?.map((subData) => {
        if (
          subData.type.toLowerCase() !== "date" ||
          subData.type.toLowerCase() !== "probability"
        ) {
          temp.push({
            metric: data.metricId ?? "",
            fromValue: subData.fromValue,
            toValue: subData.toValue,
            percentage: subData.percentage,
            condition: subData.condition ?? "",
            range_to: subData.fromValue,
            type: data.type ?? "",
          });
        }
      });
    });

    // eslint-disable-next-line array-callback-return
    temp.map((data, index) => {
      if (data.type.toLowerCase() === "date") {
        temp.splice(index, 1);
      }
      if (data.type.toLowerCase() === "probability") {
        temp.splice(index, 1);
      }
    });
    // eslint-disable-next-line array-callback-return
    temp.map((data) => {
      if (data.fromValue === "" || data.toValue === "") {
        delete data.fromValue;
        delete data.toValue;
        delete data.range_to;
      }
    });

    const res = await dispatch(actions.createProductBucket(temp, navigate));
    if (res?.status === 201) {
      console.log(res, "temp");
      setActiveStep(1);
    }
    setLoading(false);
  };

  const handleStep = () => {
    if (activeIndex !== initialValues.length - 1) {
      setActiveIndex(activeIndex + 1);
    } else {
      onSubmit(watchFileds.metrics);
    }
    if (activeIndex < bucketListByMetrics.length) {
      if (filledBucket.length === 0 || !filledBucket.includes(activeIndex)) {
        setFilledBucket([...filledBucket, activeIndex]);
      }
      if (!filledBucket?.includes(activeIndex + 1)) {
        setResSelectedIndex(null);
      } else {
        setResSelectedIndex(activeIndex + 1);
      }
    }
  };

  const handleBackStep = () => {
    if (activeIndex > 0) {
      setActiveIndex(activeIndex - 1);
      setResSelectedIndex(activeIndex - 1);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="bucket">
      <FiledArray
        {...{
          control,
          register,
          initialValues,
          getValues,
          setValue,
          errors,
          activeIndex,
          watch,
          handleStep,
          handleBackStep,
          filledBucket,
          resSelectedIndex,
          loading,
        }}
      />
    </form>
  );
};

export default StepThreeNew;
