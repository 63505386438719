import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import DoDisturbAltIcon from "@mui/icons-material/DoDisturbAlt";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import FolderIcon from "@mui/icons-material/Folder";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import React from "react";

const Box = (props) => {
  // const { title, price, per, status } = props.data;
  const data = props.data;

  return (
    <div className="small-status-box">
      <div
        className={`d-flex justify-between px-1 py-0.5 !text-white ${
          Object.keys(data)[0] === "Won"
            ? "bg-primary"
            : Object.keys(data)[0] === "Lost"
            ? "bg-red-600"
            : Object.keys(data)[0] === "Open"
            ? "bg-[#002244]"
            : Object.keys(data)[0] === "Submitted"
            ? "bg-secondary"
            : Object.keys(data)[0] === "In Progress"
            ? "bg-[#2689ED]"
            : Object.keys(data)[0] === "Completed" ||
              Object.keys(data)[0] === "Done"
            ? " !bg-primary"
            : Object.keys(data)[0] === "On Hold"
            ? " bg-gray-500"
            : Object.keys(data)[0] === "Rejected"
            ? "bg-red-500"
            : "bg-[#002244]"
        }`}
      >
        <h5 className="text-xs mb-0 text-white">{Object.keys(data)}</h5>
        {Object.keys(data)[0] === "Won" ? (
          <EmojiEventsIcon className="!text-base" />
        ) : Object.keys(data)[0] === "Lost" ? (
          <ThumbDownOffAltIcon className="!text-base" />
        ) : Object.keys(data)[0] === "Open" ? (
          <FolderIcon className="!text-base" />
        ) : Object.keys(data)[0] === "Submitted" ? (
          <SaveAltIcon className="!text-base" />
        ) : Object.keys(data)[0] === "In Progress" ? (
          <RocketLaunchIcon className="!text-base" />
        ) : Object.keys(data)[0] === "Completed" ||
          Object.keys(data)[0] === "Done" ? (
          <CheckIcon className="!text-base" />
        ) : Object.keys(data)[0] === "On Hold" ? (
          <DoDisturbAltIcon className="!text-base" />
        ) : Object.keys(data)[0] === "Rejected" ? (
          <DoNotDisturbIcon className="!text-base" />
        ) : Object.keys(data)[0] === "Deleted" ? (
          <DeleteIcon className="!text-base" />
        ) : (
          ""
        )}
      </div>
      <div className="d-flex px-2 py-2  justify-between items-end">
        <h2 className="mb-0 text-xl">{Object.values(data)}</h2>
        {/* <h5
          className={`mb-0 text-xs ${
            status === "down" ? "!text-[#DD6565]" : "!text-primary"
          }`}
        >
          {Object.values(data)}
          {title === "Won" ? (
            <FlashOnIcon className="!text-base" />
          ) : status === "up" ? (
            <ArrowDownwardIcon className="!text-base" />
          ) : (
            <ArrowUpwardIcon className="!text-base" />
          )}
        </h5> */}
      </div>
    </div>
  );
};

export default Box;
