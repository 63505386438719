import { Modal } from "antd";
import React from "react";

const ConfirmeModal = (props) => {
  const { openModal, setOpenModal, primaryData, setSelectedOpportunity } =
    props;
  return (
    <div>
      <Modal
        width={"40%"}
        open={openModal}
        onCancel={() => setOpenModal(false)}
        footer={false}
      >
        <div className="text-center">
          <h2 className=" text-base mb-0 pb-2 border-b-[1px] border-[#BCCED5]">
            Confirm Modal
          </h2>
          <h5 className=" text-lg py-3 text-red-500">Opportunity Not Saved</h5>
          <h5 className="text-base py-3">All unsaved settings will be lost</h5>
          <div className="flex justify-center">
            <button
              type="button"
              className=" text-white !bg-[#EE584C] text-[13px]  rounded-[3px] !px-5 w-[139px] !py-1 mt-3 mr-8"
              onClick={() => setOpenModal(false)}
            >
              No
            </button>
            <button
              className=" text-white !bg-primary text-[13px]  rounded-[3px] !px-5 w-[139px] !py-1 mt-3 ml-8 submit-btn"
              onClick={() => {
                setOpenModal(false);
                setSelectedOpportunity(primaryData);
              }}
            >
              Yes
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ConfirmeModal;
