import React, { Fragment, useState, useEffect } from "react";
import SideBar from "../sidebar/SideBar";
import Header from "../Header/Header";
import { useSelector, useDispatch } from "react-redux";
import OpportunitiesTable from "./OpportunitiesTable";
import actions from "../../store/actions";

import OpportunitiesDetails from "./OpportunitiesDetails";
import OpportunitiesExpandedTableContent from "./OpportunitiesExpandedTableContent";
import Footer from "../Footer/Footer";
import isEmpty from "../../store/validations/is-empty";
import axios from "axios";
import config from "../../config";
import { pageSize } from "../common/constant";
import { useNavigate } from "react-router-dom";
import ConfirmeModal from "./ConfirmeModal";

function OpportunitiesMain() {
  const dispatch = useDispatch();
  const toggleState = useSelector((state) => state.dashboard.toggleSidebar);
  const [isExpanded, setIsExpanded] = useState(false);
  const [tableData, setTableData] = useState([]);
  const { opportunitiesList } = useSelector((state) => state.opportunities);
  const [selectedOpportunity, setSelectedOpportunity] = useState();
  const [primaryData, setPrimaryData] = useState();
  const [totalOpp, setTotalOpp] = useState(0);
  const { tenant } = useSelector((state) => state.auth.user);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [btnHit, setBtnHit] = useState(false);
  const [accountBucketName, setAccountBucketName] = useState("");
  const [openModal, setOpenModal] = useState(false);
  useEffect(() => {
    dispatch(actions.getAccountTypes(navigate));
    dispatch(
      actions.getAllOpportunities({
        ...{ currentPage, pageSize, navigate, setIsLoading },
      })
    );
    dispatch(actions.listAccounts(navigate));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, currentPage]);

  useEffect(() => {
    if (!isEmpty(opportunitiesList)) {
      // alert(JSON.stringify(opportunitiesList));
      let finalData = [];
      opportunitiesList.map((data) => {
        return finalData.push({
          id: data.id,
          name: data.name,
          amount: data.parameters.amount,
          probability: data.parameters.probability,
          priority: data.parameters.priority__c,
          closeDate: data.parameters.closeDate,
          parameters: data.parameters,
          otscore: data.otscore,
          rank: data.rank,
          productScore: data.productScore,
          salesScore: data.salesScore,
          source: data.source,
          description: data.description,
        });
      });
      setTableData(finalData);
    } else {
      setTableData([]);
    }
  }, [opportunitiesList]);

  const handleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(
        `${config.WEB_URL}/api/v1.0/opportunity/tenant/${tenant}/opp/count`
      );
      if (response.status === 200) {
        setTotalOpp(response.data);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectedTableRow = (data) => {
    setPrimaryData(data);
    if (accountBucketName !== "") {
      if (typeof accountBucketName !== "object") {
        if (!btnHit) {
          setOpenModal(true);
        } else {
          setBtnHit(false);
          localStorage.setItem("selectedOpportunity", JSON.stringify(data));
          setSelectedOpportunity(data);
        }
      } else {
        setBtnHit(false);
        localStorage.setItem("selectedOpportunity", JSON.stringify(data));
        setSelectedOpportunity(data);
      }
    } else {
      setBtnHit(false);
      localStorage.setItem("selectedOpportunity", JSON.stringify(data));
      setSelectedOpportunity(data);
    }
  };

  const handleOnTableChange = (e) => {
    setCurrentPage(e);
  };
  // console.log(tableData, "tableData");
  return (
    <Fragment>
      <div
        className={`d-flex dashboard-main-container opportunities-main-container ${
          toggleState ? "expandedBlock" : "unExpandedBlock"
        }`}
      >
        <SideBar sideBardExtendHeight={"opportunities-main-container"} />
        <div
          className={
            toggleState ? "content-section-extend" : "content-section-minimize"
          }
        >
          <Header title={"Opportunities"} />
          <div className="py-4 px-3 flex bg-white">
            {!isExpanded && (
              <OpportunitiesTable
                {...{
                  handleExpanded,
                  isExpanded,
                  tableData,
                  totalOpp,
                  handleSelectedTableRow,
                  handleOnTableChange,
                  currentPage,
                  isLoading,
                }}
              />
            )}
            {isExpanded && (
              <>
                <div
                  className={
                    isExpanded
                      ? "w-4/12 ease-in transition duration-150 pr-4"
                      : ""
                  }
                >
                  <OpportunitiesExpandedTableContent
                    {...{
                      tableData,
                      handleSelectedTableRow,
                      totalOpp,
                      handleOnTableChange,
                      currentPage,
                      isLoading,
                      selectedOpportunity,
                    }}
                  />
                </div>
                <div className={isExpanded ? "w-8/12" : "hidden"}>
                  <OpportunitiesDetails
                    {...{
                      handleExpanded,
                      handleSelectedTableRow,
                      selectedOpportunity,
                      btnHit,
                      setBtnHit,
                      accountBucketName,
                      setAccountBucketName,
                    }}
                  />
                </div>
              </>
            )}
          </div>
          <Footer />
        </div>
      </div>
      {openModal && (
        <ConfirmeModal
          {...{ openModal, setOpenModal, primaryData, setSelectedOpportunity }}
        />
      )}
    </Fragment>
  );
}

export default OpportunitiesMain;
