import axios from "axios";
import config from "../../../config";
import types from "../../types";
import actions from "..";

/*==============================================================
                GET ALL METRICS
===============================================================*/

export const getAllMetrics = (id, navigate) => {
  let response = {};
  return async (dispatch) => {
    try {
      response = await axios.get(
        `${config.WEB_URL}/api/v1.0/sfdc/fields/describe/all/${id}`
      );
      if (response.data.status === "FAILURE") {
        dispatch(actions.logoutUser(navigate));
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: true,
            alertType: "error",
            alertMessage: response.data.errorMessage,
          },
        });
        dispatch({
          type: types.SET_All_METRICS,
          payload: [],
        });
      } else if (response.data.status === "SUCCESS") {
        dispatch({
          type: types.SET_All_METRICS,
          payload: response.data.fieldsObjects,
        });
      }
    } catch (error) {
      dispatch(actions.logoutUser(navigate));
      dispatch({
        type: types.SET_ALERT,
        payload: {
          alert: true,
          alertType: "error",
          alertMessage: "Error in metrics action",
        },
      });
    }
    return response;
  };
};

export const getSelectedMetrics = (tenantId) => {
  let response = {};
  return async (dispatch) => {
    try {
      response = await axios.get(
        `${config.WEB_URL}/api/v1.0/metric/all/tenant/${tenantId}`
      );

      if (response.data.status === "FAILURE") {
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: true,
            alertType: "error",
            alertMessage: response.data.errorMessage,
          },
        });
        dispatch({
          type: types.SET_SELECTED_METRICS,
          payload: [],
        });
      } else if (response.data.status === "SUCCESS") {
        dispatch({
          type: types.SET_SELECTED_METRICS,
          payload: response.data.metricsDTOS,
        });
      }
    } catch (error) {
      dispatch({
        type: types.SET_ALERT,
        payload: {
          alert: true,
          alertType: "error",
          alertMessage: "Error in metrics action",
        },
      });
    }
    return response;
  };
};

/*====================================================
                    Create metrics
====================================================*/
export const createMetrics = (data) => {
  let response = {};
  return async (dispatch) => {
    try {
      response = await axios.post(`${config.WEB_URL}/api/v1.0/metric/`, data);

      if (response.data.status === "SUCCESS")
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: true,
            alertType: "success",
            alertMessage: "Metrics Created Successfully.",
          },
        });
      else
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: true,
            alertType: "error",
            alertMessage: response.data.errorMessage,
          },
        });
    } catch (error) {
      dispatch({
        type: types.SET_ALERT,
        payload: {
          alert: true,
          alertType: "error",
          alertMessage: "Error in create metrics action",
        },
      });
    }
    return response;
  };
};

/*==============================================================
                GET Bucket Metrics
===============================================================*/

export const getBucketByMetricsId = (navigate) => {
  let response = {};
  return async (dispatch) => {
    try {
      response = await axios.get(
        `${config.WEB_URL}/api/v1.0/metric/tenant/devt4/type/SALES`
      );

      if (response.data.status === "FAILURE") {
        dispatch(actions.logoutUser(navigate));
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: true,
            alertType: "error",
            alertMessage: response.data.errorMessage,
          },
        });
        dispatch({
          type: types.SET_BUCKETS_LIST_BY_METRIC,
          payload: [],
        });
      } else if (response.data.status === "SUCCESS") {
        dispatch({
          type: types.SET_BUCKETS_LIST_BY_METRIC,
          payload: response.data.metricsDTOS,
        });
      }
    } catch (error) {
      dispatch(actions.logoutUser(navigate));

      dispatch({
        type: types.SET_ALERT,
        payload: {
          alert: true,
          alertType: "error",
          alertMessage: "Error in get bucket by metrics action",
        },
      });
    }
    return response;
  };
};
/*====================================================
                    Create bucket
====================================================*/
export const createBucket = (data) => {
  let response = {};
  return async (dispatch) => {
    try {
      response = await axios.post(
        `${config.WEB_URL}/api/v1.0/metric/bucket/list/`,
        data
      );

      if (response.data.status === "SUCCESS")
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: true,
            alertType: "success",
            alertMessage: "Bucket Created Successfully.",
          },
        });
      else
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: true,
            alertType: "error",
            alertMessage: response.data.errorMessage,
          },
        });
    } catch (error) {
      dispatch({
        type: types.SET_ALERT,
        payload: {
          alert: true,
          alertType: "error",
          alertMessage: "Error in create bucket action",
        },
      });
    }
    return response;
  };
};
