import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import StarBorderIcon from "@mui/icons-material/StarBorder";
// import StarIcon from "@mui/icons-material/Star";
const ExtensionsAddedTableComponent = () => {
  const rows = [
    {
      extentionName: "Sales Force",
      userId: "gaurav@gmail.com",
      password: "*******",
      status: "Active",
      dateAdded: "12 Nov 2022",
    },
    {
      extentionName: "Jira",
      userId: "gaurav@gmail.com",
      password: "*******",
      status: "Active",
      dateAdded: "12 Nov 2022",
    },
  ];

  // const starArr = [1, 2, 3];
  return (
    <TableContainer
      component={Paper}
      className="mt-2 border-t-4 !border-primary !rounded-none"
    >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Extention Name</TableCell>
            <TableCell>User Id</TableCell>
            <TableCell>Password</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Date Added</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.extentionName}
              </TableCell>
              <TableCell>{row.userId}</TableCell>
              <TableCell className=" whitespace-nowrap text-ellipsis overflow-hidden w-40">
                {row.password}
              </TableCell>
              <TableCell>{row.status}</TableCell>
              <TableCell>{row.dateAdded}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ExtensionsAddedTableComponent;
